import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { GLOBAL_SPINNER_HTTP_CONTEXT } from '@data-terminal/utils';

import { ReportConsumptionsBody, ReportConsumptionsResponse } from '../../models/consumption.model';
import { Material } from '../../models/material.interface';

@Injectable({
    providedIn: 'root',
})
export class ConsumptionsApiService {
    readonly #httpClient = inject(HttpClient);
    readonly #backendUrl = inject(BACKEND_URL);
    readonly #orgRequestPrefix = inject(ORGANIZATION_REQUEST_PREFIX);

    readonly #materialListUrl = `${this.#backendUrl}${this.#orgRequestPrefix}materiallist`;
    readonly #reportConsumptionsUrl = `${this.#backendUrl}${this.#orgRequestPrefix}consumption/report`;

    public getMaterials(): Observable<Material[]> {
        return this.#httpClient.get<Material[]>(this.#materialListUrl, {
            context: GLOBAL_SPINNER_HTTP_CONTEXT,
        });
    }

    public report(body: ReportConsumptionsBody): Observable<ReportConsumptionsResponse> {
        return this.#httpClient.post<ReportConsumptionsResponse>(this.#reportConsumptionsUrl, body, {
            context: GLOBAL_SPINNER_HTTP_CONTEXT,
        });
    }
}
