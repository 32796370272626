import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    NgModule,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxEchartsModule } from 'ngx-echarts';
import { ECharts, EChartsOption } from 'echarts';
import { map, takeUntil, timer } from 'rxjs';

import { ThemeService } from '@heidelberg/hdmui-angular';

import { createOptions } from './echart-time-indicator.options';
import { ResizeService } from '@data-terminal/utils';

@UntilDestroy()
@Component({
    selector: 'data-terminal-echart-time-indicator',
    templateUrl: './echart-time-indicator.component.html',
    styleUrls: ['./echart-time-indicator.component.scss'],
})
export class EchartTimeIndicatorComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() public currentTime!: number;
    @Input() public plannedTime!: number;
    @Input() public status!: string;
    @Input() public running!: boolean;

    public option!: EChartsOption;
    private chartInstance?: ECharts;
    private readonly ONE_SECOND = 1000;
    private readonly stopTimer = new EventEmitter<void>();

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly themeService: ThemeService,
        private readonly ngZone: NgZone,
        private readonly resizeService: ResizeService
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['status']) {
            this.option = createOptions(
                this.status,
                this.currentTime,
                this.plannedTime,
                this.themeService.currentTheme
            );
            this.stopTimer.emit();
            if (this.chartInstance !== null && this.chartInstance !== undefined) {
                this.startCountdown(this.chartInstance);
            }
        }
    }

    public ngOnInit(): void {
        this.option = createOptions(this.status, this.currentTime, this.plannedTime, this.themeService.currentTheme);
    }

    public ngAfterViewInit(): void {
        this.listenForWidthChanges();
    }

    public getChartInstance(chartInstance: ECharts): void {
        this.chartInstance = chartInstance;
        this.startCountdown(chartInstance);
        this.listenForThemeChanges();
    }

    private listenForWidthChanges(): void {
        const wrapper = this.document.getElementById('operation__container');

        if (!wrapper) {
            return;
        }

        this.resizeService.getNewResizeObserver(this.resizeCallback).observe(wrapper);
    }

    private readonly resizeCallback: (entries: ResizeObserverEntry[]) => void = (entries: ResizeObserverEntry[]) => {
        this.ngZone.run(() => {
            for (const entry of entries) {
                const { contentRect } = entry;
                const { width } = contentRect;
                this.chartInstance?.resize({ width });
            }
        });
    };

    private listenForThemeChanges(): void {
        this.themeService.currentTheme$.pipe(untilDestroyed(this)).subscribe((theme) => {
            this.chartInstance?.setOption(createOptions(this.status, this.currentTime, this.plannedTime, theme));
        });
    }

    private startCountdown(chartInstance: ECharts): void {
        if (this.running) {
            const startFrom = Math.round(this.currentTime / this.ONE_SECOND) || 0;
            const startTimestamp = new Date().getTime();
            timer(0, this.ONE_SECOND)
                .pipe(
                    takeUntil(this.stopTimer),
                    untilDestroyed(this),
                    map(
                        () =>
                            (startFrom + Math.floor((new Date().getTime() - startTimestamp) / this.ONE_SECOND)) *
                            this.ONE_SECOND
                    )
                )
                .subscribe((time: number) => {
                    this.currentTime = time;
                    chartInstance.setOption(
                        createOptions(this.status, time, this.plannedTime, this.themeService.currentTheme)
                    );
                });
        } else {
            timer(0, this.ONE_SECOND)
                .pipe(takeUntil(this.stopTimer), untilDestroyed(this))
                .subscribe(() =>
                    chartInstance.setOption(
                        createOptions(this.status, this.currentTime, this.plannedTime, this.themeService.currentTheme)
                    )
                );
        }
    }
}
@NgModule({
    declarations: [EchartTimeIndicatorComponent],
    exports: [EchartTimeIndicatorComponent],
    imports: [
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ],
})
export class EchartTimeIndicatorModule {}
