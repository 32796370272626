import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Activity } from '@data-terminal/shared-models';
import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';

@Injectable({
    providedIn: 'root',
})
export class ActivitiesApiService {
    private readonly ACTIVITY_LIST_URL: (machineId: string) => string = (machineId) =>
        `${this.backendUrl}${this.orgRequestPrefix}activitylist/${machineId}`;
    private readonly USER_ACTIVITY_LIST_URL: (userId: string) => string = (userId) =>
        `${this.backendUrl}${this.orgRequestPrefix}userActivities/${userId}`;
    private readonly OTHER_ACTIVITY_START_URL = `${this.backendUrl}${this.orgRequestPrefix}otheractivitystart`;
    private readonly OTHER_ACTIVITY_STOP_URL = `${this.backendUrl}${this.orgRequestPrefix}otheractivitystop`;

    constructor(
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    public getActivities(machineId: string): Observable<Activity[]> {
        return this.http.get<Activity[]>(this.ACTIVITY_LIST_URL(machineId));
    }

    public getUserActivities(userId: string): Observable<Activity[]> {
        return this.http.get<Activity[]>(this.USER_ACTIVITY_LIST_URL(userId));
    }

    public runActivity(activity: Activity, machineId: string, email: string): Observable<Activity[]> {
        return this.http.post<Activity[]>(this.OTHER_ACTIVITY_START_URL, {
            userId: email,
            activityId: activity.actId,
            machineId,
        });
    }

    public stopActivity(activity: Activity, machineId: string, email: string): Observable<Activity[]> {
        return this.http.post<Activity[]>(this.OTHER_ACTIVITY_STOP_URL, {
            userId: email,
            activityId: activity.actId,
            machineId,
        });
    }
}
