import { Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

import { HdmuiComponentsModule, HdmuiEmptyStatesModule } from '@heidelberg/hdmui-angular';

import { ConsumptionPreview } from '../../../../models/consumption.model';

@Component({
    selector: 'data-terminal-consumptions-preview',
    standalone: true,
    imports: [
        HdmuiComponentsModule,
        TranslateModule,
        HdmuiEmptyStatesModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
    ],
    templateUrl: './consumptions-preview.component.html',
    styleUrl: './consumptions-preview.component.scss',
})
export class ConsumptionsPreviewComponent {
    public readonly consumptions = input<ConsumptionPreview[]>([]);
    public readonly batchAllowed = input<boolean>(false);
    public readonly consumptionRemove = output<number>();
}
