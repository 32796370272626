import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DurationFormattingService {
    readonly #THIRTY = 30;
    readonly #SIXTY = 60;

    public formatDurationString(rawDurationValue: string | undefined): string | undefined {
        return [
            this.tryFormattingHoursWithMinutesInput.bind(this),
            this.tryFormattingSeparatorAtTheBeginningOfHourInput.bind(this),
            this.tryFormattingSeparatorAtTheBeginningOfMinuteInput.bind(this),
            this.tryFormattingSeparatorAtTheEndInput.bind(this),
            this.tryFormattingIntegerInput.bind(this),
            this.tryFormattingIntegerMinutesInput.bind(this),
            this.tryFormattingFloatHoursInput.bind(this),
            this.tryFormattingFloatMinutesInput.bind(this),
        ].reduce(
            (currentValue: string | undefined, formatterFunction: (value: string | undefined) => string | undefined) =>
                formatterFunction(currentValue),
            rawDurationValue
        );
    }

    private tryFormattingHoursWithMinutesInput(value: string | undefined): string | undefined {
        const hoursWithMinutesInputMatch = value?.match(/^\s*(\d+)\s*h\s*(\d*)\s*m?\s*$/);
        if (hoursWithMinutesInputMatch) {
            const hours = hoursWithMinutesInputMatch[1] ? parseInt(hoursWithMinutesInputMatch[1], 10) : 0;
            const minutes = hoursWithMinutesInputMatch[2] ? parseInt(hoursWithMinutesInputMatch[2], 10) : 0;
            const extraHours = Math.floor(minutes / this.#SIXTY);
            const finalHours = hours + extraHours;
            const finalMinutes = minutes % this.#SIXTY;

            if (finalHours === 0) {
                return `${finalMinutes}m`;
            } else if (finalMinutes === 0) {
                return `${finalHours}h`;
            } else {
                return `${finalHours}h ${finalMinutes}m`;
            }
        }
        return value;
    }

    private tryFormattingSeparatorAtTheBeginningOfHourInput(value: string | undefined): string | undefined {
        const separatorAtTheBeginningOfHourMatch = value?.match(/^\s*[,.](\d+)\s*h?\s*$/);
        if (separatorAtTheBeginningOfHourMatch) {
            const minutes = Number(`0.${separatorAtTheBeginningOfHourMatch[1]}`) * this.#SIXTY;
            const roundedMinutes = Math.round(minutes);

            if (roundedMinutes === this.#SIXTY) {
                return '1h';
            } else if (roundedMinutes === 0) {
                return '0h';
            } else {
                return `${roundedMinutes}m`;
            }
        }
        return value;
    }

    private tryFormattingSeparatorAtTheBeginningOfMinuteInput(value: string | undefined): string | undefined {
        const separatorAtTheBeginningOfMinuteMatch = value?.match(/^\s*[,.](\d+)\s*m?\s*$/);
        if (separatorAtTheBeginningOfMinuteMatch) {
            const seconds = Number(`0.${separatorAtTheBeginningOfMinuteMatch[1]}`) * this.#SIXTY;
            const roundedSeconds = Math.round(seconds);

            return roundedSeconds >= this.#THIRTY ? '1m' : '0m';
        }
        return value;
    }

    private tryFormattingSeparatorAtTheEndInput(value: string | undefined): string | undefined {
        const separatorAtTheEndMatch = value?.match(/^\s*(\d+)[,.]\s*([hm])?\s*$/);
        if (separatorAtTheEndMatch) {
            return separatorAtTheEndMatch[2]
                ? `${separatorAtTheEndMatch[1]}${separatorAtTheEndMatch[2]}`
                : `${separatorAtTheEndMatch[1]}`;
        }
        return value;
    }

    private tryFormattingIntegerInput(value: string | undefined): string | undefined {
        const integerMatch = value?.match(/^\s*(\d+)\s*$/);
        if (integerMatch) {
            return `${integerMatch[1]}h`;
        }
        return value;
    }

    private tryFormattingIntegerMinutesInput(value: string | undefined): string | undefined {
        const integerMinutesMatch = value?.match(/^\s*(\d+)\s*m\s*$/);
        if (integerMinutesMatch) {
            const num = integerMinutesMatch[1] ? parseInt(integerMinutesMatch[1], 10) : 0;
            const hours = num / this.#SIXTY;
            const roundedHours = Math.floor(hours);
            const minutes = (hours - roundedHours) * this.#SIXTY;
            const roundedMinutes = Math.round(minutes);

            if (roundedMinutes === this.#SIXTY) {
                return `${roundedHours + 1}h`;
            } else if (roundedMinutes === 0) {
                return `${roundedHours}h`;
            } else if (roundedHours === 0) {
                return `${roundedMinutes}m`;
            } else {
                return `${roundedHours}h ${roundedMinutes}m`;
            }
        }
        return value;
    }

    private tryFormattingFloatHoursInput(value: string | undefined): string | undefined {
        const floatHourMatch = value?.match(/^\s*(\d+)[,.](\d+)\s*h?\s*$/);
        if (floatHourMatch) {
            const hours = floatHourMatch[1] ? parseInt(floatHourMatch[1], 10) : 0;
            const minutes = Number(`0.${floatHourMatch[2]}`) * this.#SIXTY;
            const roundedMinutes = Math.round(minutes);

            if (roundedMinutes === this.#SIXTY) {
                return `${hours + 1}h`;
            } else if (roundedMinutes === 0) {
                return `${hours}h`;
            } else if (hours === 0) {
                return `${roundedMinutes}m`;
            } else {
                return `${hours}h ${roundedMinutes}m`;
            }
        }
        return value;
    }

    private tryFormattingFloatMinutesInput(value: string | undefined): string | undefined {
        const floatMinutesMatch = value?.match(/^\s*(\d+)[,.](\d+)\s*m?\s*$/);
        if (floatMinutesMatch) {
            const minutes = floatMinutesMatch[1] ? parseInt(floatMinutesMatch[1], 10) : 0;
            const seconds = Number(`0.${floatMinutesMatch[2]}`) * this.#SIXTY;
            const roundedSeconds = Math.round(seconds);

            return roundedSeconds >= this.#THIRTY ? `${minutes + 1}m` : `${minutes}m`;
        }
        return value;
    }
}
