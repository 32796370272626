import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { Operation } from '@data-terminal/shared-models';
import { CommonModule } from '@angular/common';
import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'data-terminal-operation-amount-info',
    templateUrl: './operation-amount-info.component.html',
    styleUrls: ['./operation-amount-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationAmountInfoComponent {
    @Input() public operation!: Operation;

    public isWasteExceeded(): boolean {
        return this.operation.opCtrWaste > this.operation.opPlannedWasteCtr && this.operation.opPlannedWasteCtr !== 0;
    }
    public isGoodExceeded(): boolean {
        return this.operation.opCtrGood > this.operation.opPlannedGoodCtr && this.operation.opPlannedGoodCtr !== 0;
    }
}
@NgModule({
    declarations: [OperationAmountInfoComponent],
    exports: [OperationAmountInfoComponent],
    imports: [CommonModule, HdmuiComponentsModule, TranslateModule],
})
export class OperationAmountInfoModule {}
