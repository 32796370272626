<section class="sign-off-operations">
    <p>
        {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.DESCRIPTION' | translate }}
    </p>
    <div class="sign-off-operations__list-of-operations">
        @for (signOff of signOffMachines; track identify($index, signOff)) {
            @if (shouldDisplayCard(signOff)) {
                <data-terminal-sign-off-operation-card
                    class="hdmui-m-3"
                    [machine]="signOff.machine"
                    (stateCardChange)="onDataChange($event)"
                ></data-terminal-sign-off-operation-card>
            }
        }
    </div>
</section>
