<div class="hdmui-container">
    <mat-drawer-container>
        <mat-drawer #drawer class="operation__side-nav" mode="side">
            <data-terminal-operation-filters
                (filterChange)="onFilterChange($event)"
                (closeFilterPanel)="drawer.toggle()"
            ></data-terminal-operation-filters>
        </mat-drawer>
        <as-split
            unit="pixel"
            direction="horizontal"
            [gutterStep]="CARD_WIDTH_WITH_MARGINS_PX"
            (dragEnd)="onSplitPanelDragEnd($event)"
        >
            <as-split-area
                class="operation__list"
                [order]="1"
                [size]="splitPanelConfig.leftPanelSizePx"
                [lockSize]="splitPanelConfig.isSplitPanelLocked"
                [minSize]="CARD_WIDTH_WITH_MARGINS_PX + PLACE_FOR_SCROLLBAR_PX"
                [maxSize]="splitPanelConfig.leftPanelMaxSizePx"
            >
                @if (operationList$ | async; as opList) {
                    @if (opList.data) {
                        <data-terminal-operations-list
                            [operations]="opList.data"
                            [filter]="filter"
                            [operationSettings]="operationSettings"
                            (operationClick)="operationClick($event)"
                            (operationCreation)="onCreateNewOperation($event)"
                            (filterClick)="drawer.toggle()"
                            [selectedOperation]="(selectedOperation$ | async)!"
                        ></data-terminal-operations-list>
                    }
                    <data-terminal-loading-indicator
                        class="operation__center"
                        *isLoading="opList"
                        [label]="'DC.OPERATION_PAGE.LOADING_OPERATIONS' | translate"
                    ></data-terminal-loading-indicator>
                    <data-terminal-error-message
                        class="operation__center"
                        *isError="opList"
                        [description]="opList.error?.message || 'DC.OPERATION_PAGE.ERROR' | translate"
                    ></data-terminal-error-message>
                }
            </as-split-area>
            <as-split-area
                class="operation__content"
                [order]="2"
                [lockSize]="splitPanelConfig.isSplitPanelLocked"
                [minSize]="splitPanelConfig.rightPanelMinSizePx"
                [maxSize]="splitPanelConfig.rightPanelMaxSizePx"
            >
                <router-outlet></router-outlet>
            </as-split-area>
        </as-split>
    </mat-drawer-container>
</div>
