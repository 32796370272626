import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { interval, map, Observable, take, tap } from 'rxjs';

import { HdmuiEmptyStatesModule, HdmuiIconsModule } from '@heidelberg/hdmui-angular';

import { Activity, GroupedActivities, OperationAction, ROUTE_PARAMS } from '@data-terminal/shared-models';
import {
    ErrorMessageComponentModule,
    LoadingIndicatorComponentModule,
    MetadataDirectivesModule,
} from '@data-terminal/ui-presentational';
import { RequestMetadata } from '@data-terminal/utils';

import {
    ActivityButtonClick,
    ActivityButtonsComponent,
} from '../../components/shared-components/activity-buttons/default-activity-buttons/activity-buttons.component';
import { EchartTimeIndicatorModule } from '../../components/shared-components/echart-time-indicator/echart-time-indicator.component';
import { groupOtherActivities } from '../../services/operation/operation.functions';
import { OperationToolbarButtonsModule } from '../../components/operations/operation-toolbar-buttons/operation-toolbar-buttons.component';
import { ActivityRunningToStatePipe } from '../../pipes/activity-running-to-state.pipe';
import { WorkstepActivitiesService } from '../../services/workstep-activities/workstep-activities.service';
import {
    DataTerminalSessionService,
    GLOBAL_RX_STATE,
    GlobalState,
    GlobalStateSelectors,
} from '@data-terminal/data-access';
import { RxState } from '@rx-angular/state';
import { ActivatedRoute } from '@angular/router';
import { ActionView } from '@data-terminal/feature-settings';

const REFRES_INTERVAL_5000 = 5000;

@Component({
    selector: 'data-terminal-activities-page',
    templateUrl: './activities-page.component.html',
    styleUrls: ['./activities-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [
        EchartTimeIndicatorModule,
        ActivityButtonsComponent,
        CommonModule,
        ErrorMessageComponentModule,
        LoadingIndicatorComponentModule,
        MetadataDirectivesModule,
        TranslateModule,
        OperationToolbarButtonsModule,
        ActivityRunningToStatePipe,
        HdmuiEmptyStatesModule,
        HdmuiIconsModule,
    ],
    providers: [WorkstepActivitiesService],
})
export class ActivitiesPageComponent {
    public activities$: Observable<RequestMetadata<GroupedActivities[]>>;
    public runningActivity: Activity | undefined;
    private readonly machineId: string;
    public captainFlag = false;

    protected readonly ActionView = ActionView;

    constructor(
        private readonly activitiesService: WorkstepActivitiesService,
        private readonly activatedRoute: ActivatedRoute,
        @Inject(GLOBAL_RX_STATE) private readonly globalState: RxState<GlobalState>,
        private readonly dtService: DataTerminalSessionService,
        private readonly cdRef: ChangeDetectorRef
    ) {
        this.activities$ = this.activitiesService.activities$.pipe(
            tap((metadata) => this.selectRunningActivity(metadata)),
            map((metadata) => this.groupOtherActivity(metadata))
        );
        this.machineId = this.activatedRoute.snapshot.params[ROUTE_PARAMS.machineId] || '';
        this.globalState.select(GlobalStateSelectors.USER_MACHINES).subscribe((data) => {
            const userMachinesData = data.data;
            if (userMachinesData !== null && userMachinesData !== undefined) {
                this.captainFlag =
                    userMachinesData.allMachines
                        .find((machine) => machine.machineId === this.machineId)
                        ?.signedOnUsers.find(
                            (user) => user.userId === this.dtService.getCurrentDataTerminalUser().userId
                        )?.captain || false;
            }
        });

        interval(REFRES_INTERVAL_5000).subscribe(() => {
            this.globalState
                .select(GlobalStateSelectors.USER_MACHINES)
                .pipe(take(1))
                .subscribe((data) => {
                    const userMachinesData = data.data;
                    if (userMachinesData !== null && userMachinesData !== undefined) {
                        this.captainFlag =
                            userMachinesData.allMachines
                                .find((machine) => machine.machineId === this.machineId)
                                ?.signedOnUsers.find(
                                    (user) => user.userId === this.dtService.getCurrentDataTerminalUser().userId
                                )?.captain || false;
                        this.cdRef.detectChanges();
                    }
                });
        });

        this.globalState.select(GlobalStateSelectors.USER_MACHINES).subscribe((data) => {
            const userMachinesData = data.data;
            if (userMachinesData !== null && userMachinesData !== undefined) {
                this.captainFlag =
                    userMachinesData.allMachines
                        .find((machine) => machine.machineId === this.machineId)
                        ?.signedOnUsers.find(
                            (user) => user.userId === this.dtService.getCurrentDataTerminalUser().userId
                        )?.captain || false;
            }
        });

        interval(REFRES_INTERVAL_5000).subscribe(() => {
            this.globalState
                .select(GlobalStateSelectors.USER_MACHINES)
                .pipe(take(1))
                .subscribe((data) => {
                    const userMachinesData = data.data;
                    if (userMachinesData !== null && userMachinesData !== undefined) {
                        this.captainFlag =
                            userMachinesData.allMachines
                                .find((machine) => machine.machineId === this.machineId)
                                ?.signedOnUsers.find(
                                    (user) => user.userId === this.dtService.getCurrentDataTerminalUser().userId
                                )?.captain || false;
                        this.cdRef.detectChanges();
                    }
                });
        });
    }

    public onActivityClick(activityButtonClick: ActivityButtonClick): void {
        this.activitiesService.triggerRunActivity(activityButtonClick.activity);
    }

    private selectRunningActivity(metadata: RequestMetadata<Activity[]>): void {
        this.runningActivity = metadata.data?.find((activity) => activity.running);
    }

    private groupOtherActivity(metadata: RequestMetadata<Activity[]>): RequestMetadata<GroupedActivities[]> {
        if (metadata.data) {
            return { ...metadata, data: groupOtherActivities(metadata.data || []) };
        }
        return { ...metadata, data: undefined };
    }

    public onActionClick(operationAction: OperationAction, activity?: Activity): void {
        if (!activity) {
            return;
        }

        this.activitiesService.triggerStopActivity(activity);
    }
}
