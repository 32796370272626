import { Pipe, PipeTransform } from '@angular/core';

import { Role, SignedOnUser } from '@data-terminal/shared-models';

@Pipe({
    standalone: true,
    name: 'filterAvailableCaptains',
})
export class FilterAvailableCaptainsPipe implements PipeTransform {
    transform(signedOnUsers: SignedOnUser[]): SignedOnUser[] {
        return signedOnUsers.filter((u) => !!u.zaikioId && u.role === Role.ASSISTANT);
    }
}
