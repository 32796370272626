<hdmui-base-dialog
    [dialogOptions]="option"
    (cancelClicked)="onCancelDialog()"
    (okClicked)="onConfirmDialog()"
    [contentNoPadding]="true"
    [okText]="'*.delete' | translate"
    [cancelText]="'*.action.cancel' | translate"
>
    <h2 class="hdmui-m-3 hdmui-pb-3">{{ 'DC.PURE_TIME.EDIT.PERMANENTLY_DELETE' | translate }}</h2>
    <p>
        {{ 'DC.PURE_TIME.EDIT.PERMANENTLY_DELETE.TEXT' | translate }}
    </p>
</hdmui-base-dialog>
