import { Pipe, PipeTransform } from '@angular/core';

import { Activity } from '@data-terminal/shared-models';

@Pipe({
    standalone: true,
    name: 'activityNameById',
})
export class ActivityNameByIdPipe implements PipeTransform {
    transform(activityId: string, activities: Activity[]): string {
        return activities.find((activity) => activity.actId === activityId)?.actName || '';
    }
}
