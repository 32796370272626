import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';

import { isCurrentUserAnOperator } from '@data-terminal/utils';
import { SignOffEmployees, SignOffMachine } from '@data-terminal/shared-models';

import { SignOffStepComponent } from '../sign-off-component.abstract';
import { SignOffEmployeesCardModule } from './sign-off-employees-card/sign-off-employees-card.component';

@Component({
    selector: 'data-terminal-sign-off-employees',
    templateUrl: './sign-off-employees.component.html',
    styleUrls: ['./sign-off-employees.component.scss'],
})
export class SignOffEmployeesComponent extends SignOffStepComponent<SignOffEmployees> {
    @Input() public userId!: string;

    public shouldDisplayCard(signOffData: SignOffMachine): boolean {
        return (
            signOffData.machine.signedOnUsers?.length > 1 && isCurrentUserAnOperator(signOffData.machine, this.userId)
        );
    }
}

@NgModule({
    declarations: [SignOffEmployeesComponent],
    exports: [SignOffEmployeesComponent],
    imports: [SignOffEmployeesCardModule, CommonModule, MatStepperModule, TranslateModule],
})
export class SignOffEmployeesModule {}
