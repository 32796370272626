import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { TranslateModule } from '@ngx-translate/core';

import { FooterDialogsActions } from '@data-terminal/shared-models';

@Component({
    templateUrl: './footer-leave-dialog.component.html',
    styleUrls: ['./footer-leave-dialog.component.scss'],
})
export class FooterLeaveDialogComponent {
    @Input()
    hasUserActiveMachines = false;

    public readonly option = HdmuiBaseDialogComponent.OPTION_NO_BUTTON;

    public readonly END_SHIFT = FooterDialogsActions.END_SHIFT;
    public readonly SIGN_OFF_WORKSTATION = FooterDialogsActions.SIGN_OFF_WORKSTATION;
    public readonly MANAGE_ASSISTANTS = FooterDialogsActions.MANAGE_ASSISTANTS;
    public readonly CANCEL = FooterDialogsActions.CANCEL;

    constructor(private readonly dialogRef: MatDialogRef<FooterLeaveDialogComponent>) {}

    public onButtonClick(action: FooterDialogsActions | false): void {
        this.dialogRef.close(action);
    }
}

@NgModule({
    declarations: [FooterLeaveDialogComponent],
    exports: [FooterLeaveDialogComponent],
    imports: [CommonModule, HdmuiComponentsModule, MatButtonModule, TranslateModule],
})
export class FooterLeaveDialogModule {}
