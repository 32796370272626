import { Component, NgModule, OnInit } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CustomDividerModule } from '@data-terminal/ui-presentational';
import { OperationActionSignOff, SignOffOperation } from '@data-terminal/shared-models';
import { requireMinValueOptional, requireOneControl } from '@data-terminal/utils';

import { SignOffStepCardComponent } from '../../sign-off-card-component.abstract';
import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { BehaviorSubject, filter } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FeatureOperationServiceModule } from '../../../../../../feature-workstation-details/src/lib/operation-service.module';
import { OperationService } from '../../../../../../feature-workstation-details/src/lib/services/operation/operation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    AmountDialogFormData,
    OperationAmountDetails,
} from '../../../../../../feature-workstation-details/src/lib/models/amount-report-details.model';
import { AmountReportDetailsModule } from '../../../../../../feature-workstation-details/src/lib/components/amount-report-details/amount-report-details.component';
import { NgxMaskDirective } from 'ngx-mask';

@UntilDestroy()
@Component({
    selector: 'data-terminal-sign-off-operation-card',
    templateUrl: './sign-off-operation-card.component.html',
    styleUrls: ['./sign-off-operation-card.component.scss'],
})
export class SignOffOperationCardComponent extends SignOffStepCardComponent<SignOffOperation> implements OnInit {
    public PAUSE = OperationActionSignOff.PAUSE;
    public FINISH = OperationActionSignOff.FINISH;

    public formData$ = new BehaviorSubject<AmountDialogFormData | null>({
        goodAmount: 0,
        wasteAmount: 0,
    });
    public operationData$ = new BehaviorSubject<OperationAmountDetails | null>(null);

    constructor(private readonly operationService: OperationService) {
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.getRunningOperationData();
    }

    public onInputOut(): void {
        this.formGroup.markAllAsTouched();
    }

    public override createFormGroup(): void {
        this.formGroup = new UntypedFormGroup(
            {
                goodCount: new UntypedFormControl(),
                wasteCount: new UntypedFormControl(),
                comment: new UntypedFormControl(),
                operationAction: new UntypedFormControl(this.FINISH),
                runningOperation: new UntypedFormControl(this.machine.runningOperation?.primaryKey),
            },
            [
                requireOneControl('goodCount', 'wasteCount'),
                requireMinValueOptional('goodCount', 0),
                requireMinValueOptional('wasteCount', 0),
            ]
        );
        this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
            this.formData$.next({
                goodAmount: data.goodCount || 0,
                wasteAmount: data.wasteCount || 0,
            });
        });
    }

    public getRunningOperationData(): void {
        if (this.machine.runningOperation !== null && this.machine.runningOperation !== undefined) {
            this.operationService
                .getOperationRequestMetadata(this.machine.runningOperation.primaryKey)
                .pipe(
                    filter((data) => data.isLoading === false),
                    untilDestroyed(this)
                )
                .subscribe((data) => {
                    if (data.data !== null && data.data !== undefined) {
                        this.operationData$.next({
                            currentGood: data.data.opCtrGood,
                            plannedGood: data.data.opPlannedGoodCtr,
                            currentWaste: data.data.opCtrWaste,
                            plannedWaste: data.data.opPlannedWasteCtr,
                        });
                    }
                });
        }
    }
}
@NgModule({
    declarations: [SignOffOperationCardComponent],
    exports: [SignOffOperationCardComponent],
    imports: [
        MatRadioModule,
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCardModule,
        CustomDividerModule,
        MatDividerModule,
        MatInputModule,
        ReactiveFormsModule,
        TranslateModule,
        HdmuiComponentsModule,
        MatProgressBarModule,
        FeatureOperationServiceModule,
        AmountReportDetailsModule,
        NgxMaskDirective,
    ],
})
export class SignOffOperationCardModule {}
