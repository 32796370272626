import { CommentsFilter } from '@data-terminal/shared-models';
import { GetCommentsResponse } from '@data-terminal/utils';

export function filterComments(
    filter: CommentsFilter | undefined,
    searchInput: string,
    comments: GetCommentsResponse[]
): GetCommentsResponse[] {
    if (searchInput.length > 0) {
        comments = filterSearchInput(searchInput, comments);
    }
    if (filter) {
        // Intentional Empty
    }
    return comments;
}

export function filterSearchInput(searchInput: string | null, comments: GetCommentsResponse[]): GetCommentsResponse[] {
    if (searchInput !== null) {
        const lcTextsnippet = searchInput?.toLowerCase();
        comments = comments.filter(
            (c) =>
                c.author.toLowerCase().includes(lcTextsnippet) ||
                c.text.toLowerCase().includes(lcTextsnippet) ||
                c.text.toLowerCase().includes(lcTextsnippet)
        );
    }

    return comments;
}

export function hasAnyFilterApplied(filter: CommentsFilter): boolean {
    return filter.textsnippet !== null && filter.textsnippet.length > 0;
}
