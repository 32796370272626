<form [formGroup]="form">
    <div class="log-time-form">
        <div class="hdmui-m-3 log-time-form__container">
            <p class="hdmui-caption hdmui-m-0 hdmui-p-0 log-time-form__info">
                {{ 'DC.REPORT_LAST_AMOUNTS.REQUIRE_INFO' | translate }}
            </p>

            <mat-form-field class="hdmui-mt-0 hdmui-pt-0 log-time-form__input">
                <mat-label>
                    {{ 'DC.MACHINE_CARD.ACTIVITY' | translate }}
                </mat-label>
                <mat-select formControlName="activity" [hideSingleSelectionIndicator]="true" [required]="true">
                    @for (activityGroup of activitiesMap.keys(); track activityGroup) {
                        <mat-optgroup [label]="activityGroup">
                            @for (activity of activitiesMap.get(activityGroup); track activity) {
                                <mat-option [value]="activity">
                                    {{ activity.actName }}
                                </mat-option>
                            }
                        </mat-optgroup>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field class="hdmui-mt-0 hdmui-pt-0 log-time-form__input">
                <mat-label>
                    {{ '*.duration' | translate }}
                </mat-label>
                <input
                    type="text"
                    matInput
                    formControlName="duration"
                    [required]="true"
                    (blur)="formatDurationString()"
                />
                @if (!editEntry) {
                    <mat-hint>
                        @if (
                            ((lastLoggedActivityAgoMinutes$ | async) || 0) * 60 * 1000
                                | timeFormatter: true : true : true;
                            as time
                        ) {
                            {{
                                'DC.PURE_TIME.LAST_LOGGED_ACTIVITY'
                                    | translate
                                        : {
                                              time: time === '-' ? '0h' : time,
                                          }
                            }}
                        }
                    </mat-hint>
                }
                @if (form.get('duration')?.hasError('pattern')) {
                    <mat-error>{{ 'DC.PURE_TIME.DURATION.PATTERN' | translate }} </mat-error>
                }
                @if (
                    form.get('duration')?.hasError('maxDurationSeconds') &&
                        (form.get('duration')?.getError('maxDurationSeconds').value * 1000
                            | timeFormatter: true : true : true);
                    as time
                ) {
                    <mat-error>
                        {{
                            'DC.PURE_TIME.DURATION.MAX'
                                | translate
                                    : {
                                          time: time === '-' ? '0h' : time,
                                      }
                        }}
                    </mat-error>
                }
                @if (form.get('duration')?.hasError('minDurationSeconds')) {
                    <mat-error>{{ 'DC.PURE_TIME.DURATION.MIN' | translate }} </mat-error>
                }
            </mat-form-field>

            <mat-form-field>
                <mat-label> {{ 'DC.NEW_OPERATION_DIALOG.JOB_ID' | translate }}</mat-label>
                <input type="string" matInput formControlName="jobId" [matAutocomplete]="auto" />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    @for (option of jobIdSuggestions$ | async; track option) {
                        <mat-option [value]="option">
                            {{ option }}
                        </mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>

            @if (machineClass !== MachineClass.ID_MANUALPREPRESS) {
                <mat-form-field class="hdmui-mt-0 hdmui-pt-0 log-time-form__input">
                    <mat-label>
                        {{ 'DC.REPORT_LAST_AMOUNTS.GOOD' | translate }}
                    </mat-label>
                    <input matInput formControlName="goodAmount" mask="separator.0" />
                </mat-form-field>
            }

            @if (machineClass !== MachineClass.ID_MANUALPREPRESS) {
                <mat-form-field class="hdmui-m-0 hdmui-p-0 log-time-form__input">
                    <mat-label>
                        {{ 'DC.REPORT_LAST_AMOUNTS.WASTE' | translate }}
                    </mat-label>
                    <input matInput formControlName="wasteAmount" mask="separator.0" />
                </mat-form-field>
            }

            <mat-form-field class="hdmui-mb-4">
                <mat-label>{{ 'DC.REPORT_LAST_AMOUNTS.DIALOG.COMMENT' | translate }}</mat-label>
                <textarea matInput rows="3" formControlName="comment"></textarea>
            </mat-form-field>
        </div>
    </div>
</form>
