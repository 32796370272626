import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveData, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { map, Observable, of, tap } from 'rxjs';

import { BreadcrumbsService, getParamsSnapshot } from '@data-terminal/utils';
import { Machine, ROUTE_PARAMS } from '@data-terminal/shared-models';
import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbsWorkstationResolver {
    private readonly MACHINE_BY_ID_URL: (machineId: string) => string = (machineId: string) =>
        `${this.backendUrl}${this.orgRequestPrefix}machinebyid/${machineId}`;

    constructor(
        private readonly router: Router,
        private readonly breadcrumbs: BreadcrumbsService,
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<string> {
        const machineId = getParamsSnapshot(route)[ROUTE_PARAMS.machineId];
        const navigationExtrasState = this.router.getCurrentNavigation()?.extras.state;
        if (navigationExtrasState?.workstation) {
            this.saveName(navigationExtrasState?.workstation.name);
            return of(navigationExtrasState?.workstation.name);
        } else {
            return this.getMachineName(machineId);
        }
    }

    private saveName(name: string): void {
        this.breadcrumbs.saveParam(ROUTE_PARAMS.machineId, name);
    }

    private getMachineName(machineId: string): Observable<string> {
        return this.http.get<Machine>(this.MACHINE_BY_ID_URL(machineId)).pipe(
            map((data) => data.machineName),
            tap((name) => {
                this.saveName(name);
            })
        );
    }
}

export function breadcrumbsWorkstationResolver(): ResolveData {
    return { title: BreadcrumbsWorkstationResolver };
}
