import { Component, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';
import { SignOffMachine, TimeModeEntry } from '@data-terminal/shared-models';
import { CommonModule } from '@angular/common';
import { SignOffSummaryCardModule } from './sign-off-summary-card/sign-off-summary-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { HdmuiInfoBoxModule } from '@heidelberg/hdmui-angular';

@Component({
    selector: 'data-terminal-sign-off-summary',
    templateUrl: './sign-off-summary.component.html',
    styleUrls: ['./sign-off-summary.component.scss'],
})
export class SignOffSummaryComponent implements OnChanges {
    @Input() public signOffMachines!: SignOffMachine[];
    @Input() public unsubmittedTimeEntries!: TimeModeEntry[];

    public machinesWithUnsubmittedEntriesStr = '';

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.signOffMachines && changes.unsubmittedTimeEntries) {
            const machinesWithUnsubmittedEntries: string[] = [];

            this.signOffMachines.forEach((machine) => {
                const timeEntry = this.unsubmittedTimeEntries.find((entry) => machine.machineId === entry.deviceId);

                if (timeEntry) {
                    machinesWithUnsubmittedEntries.push(timeEntry.deviceId);
                }
            });

            this.machinesWithUnsubmittedEntriesStr = machinesWithUnsubmittedEntries.join(', ');
        }
    }
}

@NgModule({
    declarations: [SignOffSummaryComponent],
    exports: [SignOffSummaryComponent],
    imports: [SignOffSummaryCardModule, CommonModule, TranslateModule, HdmuiInfoBoxModule],
})
export class SignOffSummaryModule {}
