import {
    ChangeDetectionStrategy,
    Component,
    effect,
    ElementRef,
    input,
    output,
    signal,
    Signal,
    viewChild,
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';

import { ConsumptionPreview, ConsumptionType } from '../../../../models/consumption.model';
import { Material } from '../../../../models/material.interface';

interface ConsumptionForm {
    materialId: FormControl<string>;
    batchNumber: FormControl<string>;
    amount: FormControl<number | undefined>;
    type: FormControl<ConsumptionType>;
}

function getBlackListKey(materialId = '', batchNumber = ''): string {
    return `${materialId}-${batchNumber}`;
}

export const ConsumptionBlackListValidator: (blackList: Set<string>) => ValidatorFn = (blackList: Set<string>) => {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const form = formGroup as FormGroup<ConsumptionForm>;

        const materialId = form.value.materialId;
        const batchNumber = form.value.batchNumber;

        if (blackList.has(getBlackListKey(materialId, batchNumber))) {
            return { blackList: true };
        }

        return null;
    };
};

@Component({
    selector: 'data-terminal-consumption-add-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        TranslateModule,
        NgxMaskDirective,
    ],
    templateUrl: './consumption-add-form.component.html',
    styleUrl: './consumption-add-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumptionAddFormComponent {
    readonly #MAX_AMOUNT = 9999999999;
    readonly #STRING_MAX_LENGTH = 30;

    public readonly batchAllowed = input<boolean>(false);
    public readonly allMaterials = input<Material[]>([]);
    public readonly consumptions = input<ConsumptionPreview[]>([]);
    public readonly consumptionAdd = output<ConsumptionPreview>();

    public readonly materialInputEl: Signal<ElementRef | undefined> = viewChild('materialInput', { read: ElementRef });

    public readonly form = this.consumptionGroup;

    public readonly existingMaterial = signal<Material | undefined>(undefined);

    public readonly consumptionTypes = Object.values(ConsumptionType);

    readonly #basicAmountValidators: ValidatorFn[] = [Validators.required, Validators.min(0)];
    readonly #defaultAmountValidators: ValidatorFn[] = [
        ...this.#basicAmountValidators,
        Validators.max(this.#MAX_AMOUNT),
    ];

    constructor() {
        effect(() => {
            const existingMaterial = this.existingMaterial();

            if (existingMaterial) {
                this.form.controls.amount.setValidators([
                    ...this.#basicAmountValidators,
                    Validators.max(existingMaterial.availableAmount),
                ]);
            } else {
                this.form.controls.amount.setValidators(this.#defaultAmountValidators);
            }

            this.form.controls.amount.updateValueAndValidity();
        });

        effect(() => {
            const consumptions = this.consumptions();
            const blackList: Set<string> = new Set(
                consumptions.map((consumption) => getBlackListKey(consumption.materialId, consumption.batchId))
            );

            this.form.setValidators([ConsumptionBlackListValidator(blackList)]);
            this.form.updateValueAndValidity();
        });
    }

    public onMaterialIdInput(): void {
        const materialId = this.form.value.materialId;
        const material = this.allMaterials().find((m) => m.materialId === materialId);

        this.existingMaterial.set(material);
    }

    public addConsumption(): void {
        if (this.form.valid) {
            const formValue = this.form.value;
            const consumption: ConsumptionPreview = {
                consumption: formValue.amount || 0,
                batchId: formValue.batchNumber,
                materialId: formValue.materialId || '',
                type: formValue.type || ConsumptionType.NORMAL,
                materialDescription: this.existingMaterial()?.materialDescription,
                unitCaption: this.existingMaterial()?.unitCaption,
            };

            this.consumptionAdd.emit(consumption);

            if (!this.batchAllowed()) {
                this.form.controls.materialId.reset();
                this.form.controls.materialId.markAsUntouched();
            }

            this.form.controls.amount.reset();
            this.form.controls.amount.markAsUntouched();
            this.form.controls.batchNumber.reset();
            this.form.controls.batchNumber.markAsUntouched();

            this.materialInputEl()?.nativeElement.focus();

            if (this.batchAllowed()) {
                this.materialInputEl()?.nativeElement.select();
            }
        }
    }

    private get consumptionGroup(): FormGroup<ConsumptionForm> {
        return new FormGroup<ConsumptionForm>({
            materialId: new FormControl<string>('', {
                nonNullable: true,
                validators: [Validators.required, Validators.maxLength(this.#STRING_MAX_LENGTH)],
            }),
            batchNumber: new FormControl<string>('', {
                nonNullable: true,
                validators: [Validators.maxLength(this.#STRING_MAX_LENGTH)],
            }),
            amount: new FormControl<number | undefined>(undefined, {
                nonNullable: true,
                validators: this.#defaultAmountValidators,
            }),
            type: new FormControl<ConsumptionType>(ConsumptionType.NORMAL, {
                nonNullable: true,
                validators: [Validators.required],
            }),
        });
    }
}
