@if (operationDetails) {
    <div class="operation-details">
        @if (transfer2Cloud) {
            <div class="operation-details__column">
                <data-terminal-operation-details-preview
                    [operationDetails]="operationDetails"
                ></data-terminal-operation-details-preview>
            </div>
        }
        <div class="operation-details__column">
            <data-terminal-operation-details-info
                [operationDetails]="operationDetails"
            ></data-terminal-operation-details-info>
        </div>
    </div>
}
