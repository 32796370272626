import { inject, Injectable } from '@angular/core';

import { LOCAL_STORAGE } from '@data-terminal/utils';

export enum OperationsSplitPanelLocalStorageKey {
    TABLET = 'OPERATIONS_SPLIT_PANEL_LEFT_PANEL_SIZE_PX_TABLET',
    DESKTOP_LARGE = 'OPERATIONS_SPLIT_PANEL_LEFT_PANEL_SIZE_PX_DESKTOP_LARGE',
    DESKTOP_SUPER_LARGE = 'OPERATIONS_SPLIT_PANEL_LEFT_PANEL_SIZE_PX_DESKTOP_SUPER_LARGE',
}

@Injectable({
    providedIn: 'root',
})
export class OperationsSplitPanelLocalStorageService {
    readonly #localStorage = inject(LOCAL_STORAGE);

    public saveLeftPanelSizePx(key: OperationsSplitPanelLocalStorageKey, leftPanelSizePx: number): void {
        this.#localStorage.setItem(key, JSON.stringify(leftPanelSizePx));
    }

    public getLeftPanelSizePx(key: OperationsSplitPanelLocalStorageKey): number | null {
        const leftPanelSizePx = this.#localStorage.getItem(key);
        return leftPanelSizePx ? JSON.parse(leftPanelSizePx) : null;
    }
}
