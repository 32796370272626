import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { TranslateService } from '@ngx-translate/core';

import { SignOffMachine, TimeModeEntry } from '@data-terminal/shared-models';
import { SignOffService } from '@data-terminal/feature-dialogs';
import {
    DataTerminalSessionService,
    GLOBAL_RX_STATE,
    GlobalState,
    NotificationSendingService,
} from '@data-terminal/data-access';
import { HplusRedirectionService, SnackBarService } from '@data-terminal/utils';

import { EndShiftDialogComponent } from '../../dialogs/end-shift-dialog/end-shift-dialog.component';
import { AbstractSignOffProcessService } from './abstract-sign-off-process.service';
import { UserMachinesService } from '../../../../../data-access/src/lib/user-machines';
import { PureTimeApiService } from '../../../../../feature-workstation-details/src/lib/services/pure-time-api/pure-time-api.service';

@Injectable({
    providedIn: 'root',
})
export class EndShiftProcessService extends AbstractSignOffProcessService {
    constructor(
        @Inject(GLOBAL_RX_STATE) protected readonly globalState: RxState<GlobalState>,
        protected readonly dialog: MatDialog,
        protected readonly signOffService: SignOffService,
        protected readonly router: Router,
        protected readonly activatedRoute: ActivatedRoute,
        protected readonly userMachinesService: UserMachinesService,
        protected readonly notificationservice: NotificationSendingService,
        protected readonly translate: TranslateService,
        protected readonly snackBarService: SnackBarService,
        protected readonly sessionService: DataTerminalSessionService,
        protected readonly hplusRedirectionService: HplusRedirectionService,
        protected readonly pureTimeApiService: PureTimeApiService
    ) {
        super(
            dialog,
            signOffService,
            router,
            activatedRoute,
            userMachinesService,
            globalState,
            notificationservice,
            translate,
            snackBarService,
            sessionService,
            hplusRedirectionService,
            pureTimeApiService
        );
    }

    protected prepareStream(unsubmittedTimeEntries: TimeModeEntry[]): Observable<SignOffMachine[]> {
        return this.openDialog(EndShiftDialogComponent, unsubmittedTimeEntries, true);
    }
}
