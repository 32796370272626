import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    NgModule,
    OnInit,
    Output,
} from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, interval, startWith } from 'rxjs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { OperationFilter } from '@data-terminal/shared-models';
import { ActivatedRoute } from '@angular/router';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { EmployeeService } from '../../../services/employee/employee.service';

const REFRESH_INTERVAL_5000 = 5000;
const DEBOUNCE_TIME_500 = 500;

@Component({
    selector: 'data-terminal-operation-filters',
    templateUrl: './operation-filters.component.html',
    styleUrls: ['./operation-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationFiltersComponent implements OnInit {
    constructor(
        private readonly route: ActivatedRoute,
        private readonly employeeService: EmployeeService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly ccAuthService: CCAuthService
    ) {}

    private readonly SESSION_STORAGE_KEY = 'filterOperation';
    private MACHINE_STORAGE_KEY = '';
    @Output() closeFilterPanel = new EventEmitter<void>();
    @Output() filterChange = new EventEmitter<OperationFilter>();

    public filterGroup!: UntypedFormGroup;
    public minDate = new Date();
    public machineLoginTimestamp = 0;
    public loginHours = 0;
    public loginMinutes = 0;

    public ngOnInit(): void {
        this.route.params.subscribe((data) => {
            this.MACHINE_STORAGE_KEY = data['machineId'];
        });
        this.createFormGroup();
        this.readDataFromSessionStorage();
        this.filterGroup.valueChanges
            .pipe(startWith(this.filterGroup.value), debounceTime(DEBOUNCE_TIME_500), distinctUntilChanged())
            .subscribe((data) => {
                if (this.filterGroup.valid) {
                    sessionStorage.setItem(
                        `${this.SESSION_STORAGE_KEY}_${this.MACHINE_STORAGE_KEY}`,
                        JSON.stringify(data)
                    );
                    this.filterChange.emit(data);
                }
            });

        const mail = this.ccAuthService.getCurrentUser()?.email;

        if (mail) {
            this.employeeService.fetchUserMachineLogins(mail).subscribe((data) => {
                const filterData = data.filter((d) => d.machineId === this.MACHINE_STORAGE_KEY);
                if (filterData.length > 0) {
                    this.machineLoginTimestamp = new Date(
                        this.employeeService
                            .getUserMachineLogins()
                            .filter(
                                (userMachineLogin) => userMachineLogin.machineId === this.MACHINE_STORAGE_KEY
                            )[0].timestamp
                    ).getTime();
                    this.updateLoginTimes();
                    interval(REFRESH_INTERVAL_5000).subscribe(() => {
                        this.updateLoginTimes();
                        this.cdRef.detectChanges();
                    });
                }
            });
        }
    }

    private updateLoginTimes(): void {
        this.loginHours = Math.floor((Date.now() - this.machineLoginTimestamp) / 1000 / 60 / 60);
        this.loginMinutes = Math.ceil((Date.now() - this.machineLoginTimestamp) / 1000 / 60 - this.loginHours) % 60;
    }

    public onFilterClose(): void {
        this.closeFilterPanel.emit();
    }

    public onDateRangeReset(): void {
        this.filterGroup.get('dueDate.start')?.setValue(null);
        this.filterGroup.get('dueDate.end')?.setValue(null);
    }

    public onShiftReset(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    }

    public onStateReset(): void {
        this.filterGroup.get('checkboxes.canBeStarted')?.setValue(false);
        this.filterGroup.get('checkboxes.startExceeded')?.setValue(false);
        this.filterGroup.get('checkboxes.waitingWarning')?.setValue(false);
        this.filterGroup.get('checkboxes.waitingError')?.setValue(false);
        this.filterGroup.get('checkboxes.paused')?.setValue(false);
    }

    private readDataFromSessionStorage(): void {
        const data = sessionStorage.getItem(`${this.SESSION_STORAGE_KEY}_${this.MACHINE_STORAGE_KEY}`);
        if (data) {
            this.filterGroup.setValue(JSON.parse(data));
        }
    }

    private createFormGroup(): void {
        this.filterGroup = new UntypedFormBuilder().group({
            plannedStart: new UntypedFormBuilder().group({
                hours: new UntypedFormControl(),
            }),
            checkboxes: new UntypedFormBuilder().group({
                canBeStarted: new UntypedFormControl(true),
                startExceeded: new UntypedFormControl(true),
                waitingWarning: new UntypedFormControl(true),
                waitingError: new UntypedFormControl(true),
                paused: new UntypedFormControl(true),
            }),
            dueDate: new UntypedFormGroup({
                start: new UntypedFormControl(null),
                end: new UntypedFormControl(null),
            }),
        });
    }
}

@NgModule({
    declarations: [OperationFiltersComponent],
    exports: [OperationFiltersComponent],
    imports: [
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatExpansionModule,
        MatRippleModule,
        MatCheckboxModule,
        MatDividerModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        CommonModule,
    ],
    providers: [MatDatepickerModule],
})
export class OperationFiltersModule {}
