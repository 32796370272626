import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cacheable } from 'ts-cacheable';

import { JobApiService } from '../job-api/job-api.service';
import { Job } from '../../../../../shared-models/src/lib/job';

@Injectable({
    providedIn: 'root',
})
export class JobService {
    constructor(private readonly jobApiService: JobApiService) {}

    @Cacheable({ maxAge: 1000 })
    public getJobList(searchQuery: string): Observable<Job[]> {
        return this.jobApiService.getJobList(searchQuery);
    }
}
