import { Component, inject } from '@angular/core';
import { OperationAction, OperationActionType } from '@data-terminal/shared-models';
import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { requireMinValueOptional } from '@data-terminal/utils';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AmountReportDetailsModule } from '../../amount-report-details/amount-report-details.component';
import { NgxMaskDirective } from 'ngx-mask';
import { ConfirmationSettingType, SettingsConfirmationService } from '@data-terminal/feature-settings';
import { MatCheckbox } from '@angular/material/checkbox';

export interface ReportWasteAmountDialogData {
    dialogType: OperationActionType;
    plannedWasteAmount: number;
    currentWasteAmount: number;
}

export interface WasteAmountForm {
    wasteAmount: FormControl<number>;
    comment: FormControl<string>;
}

@Component({
    selector: 'data-terminal-waste-amount-dialog',
    standalone: true,
    imports: [
        MatIcon,
        MatIconButton,
        HdmuiComponentsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        ReactiveFormsModule,
        CommonModule,
        AmountReportDetailsModule,
        NgxMaskDirective,
        MatCheckbox,
        FormsModule,
    ],
    templateUrl: './waste-amount-dialog.component.html',
    styleUrl: './waste-amount-dialog.component.scss',
})
export class WasteAmountDialogComponent {
    readonly #fb = inject(FormBuilder);

    readonly #dialogRef: MatDialogRef<WasteAmountDialogComponent> = inject(MatDialogRef<WasteAmountDialogComponent>);
    readonly dialogData: ReportWasteAmountDialogData = inject(MAT_DIALOG_DATA);
    readonly #settingsConfirmationService = inject(SettingsConfirmationService);

    readonly option = HdmuiBaseDialogComponent.OPTION_CONFIRM_CANCEL;
    readonly OperationActionType = OperationActionType;

    wasteAmountForm: FormGroup<WasteAmountForm> = this.#fb.group(
        {
            wasteAmount: new FormControl(),
            comment: new FormControl(),
        },
        { validators: [requireMinValueOptional('wasteAmount', 0)] }
    );

    isChecked = false;

    onCancelDialog(): void {
        this.#dialogRef.close(false);
    }

    onConfirmDialog(): void {
        if (this.isChecked) {
            this.#settingsConfirmationService.updateConfirmationSetting(
                ConfirmationSettingType.WASTE_SHEETS_ON_SWITCH_TO_GOOD_PRODUCTION,
                this.isChecked
            );
        }
        const operationReportAction: OperationAction = {
            ...this.wasteAmountForm.value,
            actionType: this.dialogData.dialogType,
        };
        this.#dialogRef.close(operationReportAction);
    }

    onInputOut(): void {
        this.wasteAmountForm.markAllAsTouched();
    }

    static returnDialogConfigData(data: ReportWasteAmountDialogData): MatDialogConfig {
        return {
            width: '320px',
            minHeight: '140px',
            maxHeight: '500px',
            height: '100%',
            data,
            disableClose: true,
        };
    }
}
