import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Machine, SignOffBaseStepObject } from '@data-terminal/shared-models';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    template: '',
})
export abstract class SignOffStepCardComponent<T extends SignOffBaseStepObject> implements OnInit {
    @Input() public machine!: Machine;
    @Output() public stateCardChange: EventEmitter<T> = new EventEmitter<T>();
    public formGroup!: UntypedFormGroup;

    public abstract createFormGroup(): void;

    public ngOnInit(): void {
        this.createFormGroup();
        this.listenFormValueChanges();
        this.emitInitialValue();
    }

    private listenFormValueChanges(): void {
        this.formGroup.valueChanges.subscribe((formValue) => {
            this.stateCardChange.emit({
                ...this.createObjectData(),
                ...formValue,
            });
        });
    }

    private createObjectData(): T {
        return {
            ...this.formGroup.value,
            isValid: this.formGroup.valid,
            machineId: this.machine.machineId,
        };
    }

    private emitInitialValue(): void {
        this.stateCardChange.emit(this.createObjectData());
    }
}
