@if (operation$ | async; as metadata) {
    @if (metadata.data; as operation) {
        <mat-toolbar>
            @if (machineToolbarData$ | async; as machineToolbarData) {
                <p
                    class="machine-toolbar__info-text hdmui-me-0 hdmui-font-small"
                    [matTooltip]="machineToolbarData.machineName"
                >
                    {{ machineToolbarData.machineName }}
                </p>
                <p class="hdmui-m-3">|</p>
                <p
                    class="machine-toolbar__info-text hdmui-m-0 hdmui-font-small"
                    [matTooltip]="machineToolbarData.machineName"
                >
                    @if (machineToolbarData.isCurrentUserAssistant) {
                        {{ 'DC.OPERATION_PAGE.SIGNED_IN_AS_ASSISTANT' | translate }}
                    } @else {
                        {{ 'DC.OPERATION_PAGE.SIGNED_IN_AS_OPERATOR' | translate }}
                    }
                    @if (machineToolbarData.isCurrentUserAssistant && machineToolbarData.isCurrentUserCaptain) {
                        {{ 'DC.OPERATION_PAGE.TEMPORARY_DEPUTY' | translate }}
                    }
                </p>
                <span class="spacer"></span>
                @if (machineToolbarData.isCurrentUserAssistant) {
                    <p class="machine-toolbar__info-text hdmui-me-0 hdmui-font-small">
                        {{ 'DC.OPERATION_PAGE.RESPONSIBLE_OPERATOR' | translate }}&nbsp;
                    </p>
                    <hdmui-avatar
                        [fullName]="
                            machineToolbarData.operator?.firstName + ' ' + machineToolbarData.operator?.lastName
                        "
                        [matTooltip]="machineToolbarData.operator?.userId"
                        matTooltipPosition="above"
                        class="hdmui-avatar-small"
                    ></hdmui-avatar>
                    <data-terminal-help-button>
                        <p class="hdmui-font-small hdmui-m-0 hdmui-mt-1">
                            {{ 'DC.OPERATION_PAGE.OPERATOR_HELP_DESK' | translate }}
                        </p>
                    </data-terminal-help-button>
                }
            }
        </mat-toolbar>
        <mat-toolbar>
            <h2 [matTooltip]="operation.opName">{{ operation.opName }}</h2>
            <span class="spacer"></span>
            <button mat-icon-button (click)="onOrderBagClick(operation.jobNumber)" [disabled]="!transfer2Cloud">
                <mat-icon svgIcon="hdmui:job"></mat-icon>
            </button>
            <button mat-icon-button [routerLink]="'./comments'">
                <mat-icon svgIcon="hdmui:dialog"></mat-icon>
            </button>
            <button mat-icon-button (click)="onPrintPalletSheetClick(operation)">
                <mat-icon svgIcon="hdmui:palletLabel"></mat-icon>
            </button>
            <button
                mat-button
                class="operation__details-button"
                [routerLink]="'./details'"
                [state]="{ operation: { name: operation.opName } }"
                [matTooltip]="'BTN_DETAILS' | translate"
                (click)="onDetailsClick()"
            >
                {{ 'BTN_DETAILS' | translate }}
                <mat-icon svgIcon="hdmui:arrowLarge-R"></mat-icon>
            </button>
        </mat-toolbar>
        <section class="operation">
            @if (
                operation.running &&
                (runningOnOtherMachine || (operation.runningOnMachine && machineId !== operation.runningOnMachine))
            ) {
                <hdmui-info-box
                    class="operation__info-box"
                    [icon]="'hdmui:exclamationMarkCircled'"
                    [background]="'full'"
                >
                    <p>{{ 'DC.OPERATION_PAGE.RUNNING_ON_OTHER_MACHINE' | translate }} {{ actualMachine }}</p>
                </hdmui-info-box>
            }
            <div id="operation__container" class="operation__container">
                <data-terminal-echart-time-indicator
                    [status]="operation.opState"
                    [currentTime]="currentTime"
                    [plannedTime]="plannedTime"
                    [running]="
                        operation.opState === inProgress ||
                        operation.opState === setUp ||
                        operation.opState === cleanUp ||
                        operation.opState === stopped
                    "
                ></data-terminal-echart-time-indicator>
                <div class="operation__buttons-container">
                    <data-terminal-operation-amount-info [operation]="operation"></data-terminal-operation-amount-info>
                    @if (
                        !(
                            operation.running &&
                            (runningOnOtherMachine ||
                                (operation.runningOnMachine && machineId !== operation.runningOnMachine))
                        )
                    ) {
                        <data-terminal-operation-toolbar-buttons
                            (sendReport)="onOperationActionClick($event, operation)"
                            (consumptionsReport)="onConsumptionsReport($event, operation)"
                            [opState]="operation.opState"
                            [plannedGoodAmount]="operation.opPlannedGoodCtr"
                            [plannedWasteAmount]="operation.opPlannedWasteCtr"
                            [currentGoodAmount]="operation.opCtrGood"
                            [currentWasteAmount]="operation.opCtrWaste"
                            [isCounterBox]="isCounterBox"
                            [counterBoxTimestamp]="counterBoxTimestamp"
                            [captainFlag]="captainFlag"
                            [isManualMachine]="isManualMachine"
                            [operationSettings]="operationSettings"
                            [organizationInfo]="organizationInfo"
                        ></data-terminal-operation-toolbar-buttons>
                    }
                    @if (
                        !(
                            operation.running &&
                            (runningOnOtherMachine ||
                                (operation.runningOnMachine && machineId !== operation.runningOnMachine))
                        )
                    ) {
                        <data-terminal-activity-buttons
                            [currentState]="operation.runningActivities[0]"
                            [currentOpState]="operation.opState"
                            [groupedActivities]="groupedActivities"
                            (activityClick)="onActivityClick($event, operation)"
                            [captainFlag]="captainFlag"
                            [operation]="operation"
                            [isManualMachine]="isManualMachine"
                            [actionView]="ActionView.OPERATION"
                        ></data-terminal-activity-buttons>
                    }
                </div>
            </div>
            <app-legal-line></app-legal-line>
        </section>
    } @else {
        @if (!metadata.isLoading) {
            <hdmui-empty-states icon="hdmui:exclamationMarkCircled">
                <hdmui-empty-states-title>{{ 'DC.OPERATIONS.EMPTY.TITLE' | translate }}</hdmui-empty-states-title>
                <hdmui-empty-states-description
                    >{{ 'DC.OPERATIONS.EMPTY.DESCRIPTION' | translate }}
                </hdmui-empty-states-description>
            </hdmui-empty-states>
        }
    }
    <data-terminal-loading-indicator
        class="operation__center"
        *isLoading="metadata"
        [label]="'DC.OPERATION_PAGE.LOADING_OPERATION' | translate"
    ></data-terminal-loading-indicator>
    @if (metadata.error; as err) {
        <data-terminal-error-message
            class="operation__center"
            *isError="metadata"
            [description]="'DC.OPERATION_PAGE.ERROR' | translate"
        >
        </data-terminal-error-message>
    }
}
