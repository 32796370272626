<mat-toolbar class="hdmui-no-border">
    <span class="spacer"></span>
    <data-terminal-search-input
        class="comment-toolbar__input-search"
        [barcodeAllowed]="false"
        (searchPhraseChange)="onSearchInputChange($event)"
    ></data-terminal-search-input>
    @if (false) {
        <div>
            <mat-divider vertical></mat-divider>
            @if (showFilterBadge) {
                <button
                    mat-icon-button
                    matBadge
                    (click)="drawer.toggle()"
                    [class]="'hdmui-badge-highlight hdmui-badge-minor'"
                >
                    <mat-icon svgIcon="hdmui:filter"></mat-icon>
                </button>
            } @else {
                <button mat-icon-button (click)="drawer.toggle()">
                    <mat-icon svgIcon="hdmui:filter"></mat-icon>
                </button>
            }
        </div>
    }
</mat-toolbar>
<mat-drawer-container>
    <section class="comments">
        @if (displayCommentList === undefined) {
            <div class="comments_loading-spinner">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        }
        @if (displayCommentList !== undefined && displayCommentList.length > 0) {
            @for (comment of displayCommentList; track comment) {
                <div class="comments__container">
                    <mat-card class="card-styling">
                        <p class="hdmui-caption comments__text">
                            {{ comment.date ? (comment.date | date: 'short') : '-' }}
                        </p>
                        <p class="hdmui-caption comments__text">{{ comment.author }}</p>
                        @if (comment.opState) {
                            <div>
                                <p class="comments__text-state-key">{{ 'DC.COMMENTS.CARD_STATE' | translate }}</p>
                                <p class="comments__text-state-value">{{ comment.opState }}</p>
                            </div>
                        }
                        <p>{{ comment.text }}</p>
                    </mat-card>
                    <br />
                </div>
            }
        } @else {
            @if (displayCommentList !== undefined) {
                <div>
                    <hdmui-empty-states icon="hdmui:exclamationMarkCircled">
                        <hdmui-empty-states-title
                            >{{ 'DC.COMMENTS.NO_COMMENTS_AVAILABLE' | translate }}
                        </hdmui-empty-states-title>
                    </hdmui-empty-states>
                </div>
            }
        }

        <app-legal-line></app-legal-line>
    </section>
    <mat-drawer #drawer class="comments__side-nav" mode="side" position="end">
        <data-terminal-comment-workflow-filters
            (closeFilterPanel)="drawer.toggle()"
            (filterChange)="onFilterChange($event)"
        ></data-terminal-comment-workflow-filters>
    </mat-drawer>
</mat-drawer-container>
