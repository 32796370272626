import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, Observable } from 'rxjs';
import { Operation, StartOperationActivity } from '@data-terminal/shared-models';
import { OngoingOperationDialogComponent } from '../../components/operations/ongoing-operation-dialog/ongoing-operation-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class OngoingOperationDialogService {
    constructor(private readonly dialog: MatDialog) {}

    public openOngoingOperationDialog(
        startOperationActivity: StartOperationActivity,
        runningOperation: Operation,
        isManualMachine: boolean,
        counterboxTimestamp: string
    ): Observable<StartOperationActivity> {
        return this.dialog
            .open(OngoingOperationDialogComponent, {
                width: '320px',
                height: '650px',
                data: { startOperationActivity, runningOperation, isManualMachine, counterboxTimestamp },
                disableClose: true,
            })
            .afterClosed()
            .pipe(filter((data) => data));
    }
}
