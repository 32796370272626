<section class="operation-filter">
    <mat-toolbar>
        <h2>{{ 'DC.OPERATION_FILTERS.TITLE' | translate }}</h2>
        <span class="spacer"></span>
        <button mat-icon-button (click)="onFilterClose()">
            <mat-icon svgIcon="hdmui:x"></mat-icon>
        </button>
    </mat-toolbar>
    <form [formGroup]="filterGroup">
        <div class="operation-filter__container hdmui-p-3">
            <div class="hdmui-p-3">
                <mat-toolbar class="hdmui-no-border hdmui-font-bold">
                    {{ 'DC.OPERATION_FILTERS.DATERANGE_TITLE' | translate }}
                    <span class="hdmui-spacer"></span>
                    <mat-divider [vertical]="true"></mat-divider>
                    <button mat-icon-button (click)="onDateRangeReset()">
                        <mat-icon svgIcon="hdmui:xCircled"></mat-icon>
                    </button>
                </mat-toolbar>
                <mat-form-field formGroupName="dueDate">
                    <mat-label> {{ 'DC.OPERATION_FILTERS.DATERANGE' | translate }} </mat-label>
                    <mat-date-range-input [rangePicker]="rangePicker" [min]="minDate" [max]="">
                        <input formControlName="start" matStartDate placeholder="Start date" />
                        <input formControlName="end" matEndDate placeholder="End date" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle [for]="rangePicker" matSuffix>
                        <mat-icon matDatepickerToggleIcon svgIcon="hdmui:calendar"></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-date-range-picker #rangePicker> </mat-date-range-picker>
                    <mat-hint align="start"> {{ 'DC.OPERATION_FILTERS.HINT_DATERANGE' | translate }}</mat-hint>
                </mat-form-field>

                <mat-toolbar class="hdmui-no-border hdmui-font-bold">
                    {{ 'DC.OPERATION_FILTERS.MYSHIFTTIME_TITLE' | translate }}
                    <span class="hdmui-spacer"></span>
                    <mat-divider [vertical]="true"></mat-divider>
                    <button mat-icon-button (click)="onShiftReset($event)">
                        <mat-icon svgIcon="hdmui:xCircled"></mat-icon>
                    </button>
                </mat-toolbar>
                <mat-form-field formGroupName="plannedStart">
                    <mat-label> {{ 'DC.OPERATION_FILTERS.SHIFT' | translate }}</mat-label>
                    <input
                        matInput
                        [placeholder]="'DC.NEW_OPERATION_DIALOG.HOURS' | translate"
                        type="number"
                        min="0"
                        formControlName="hours"
                    />
                    <span matSuffix>hours</span>
                    <mat-hint align="start">
                        {{ 'DC.OPERATION_FILTERS.HINT_SHIFTSTART' | translate }} {{ loginHours }}
                        {{ 'DC.OPERATION_FILTERS.HINT_SHIFTSTART_HOURS' | translate }} {{ loginMinutes }}
                        {{ 'DC.OPERATION_FILTERS.HINT_SHIFTSTART_MINUTES' | translate }}</mat-hint
                    >
                </mat-form-field>
                <div formGroupName="checkboxes">
                    <mat-toolbar class="hdmui-no-border hdmui-font-bold">
                        {{ 'DC.OPERATION_FILTERS.STATES' | translate }}
                        <span class="hdmui-spacer"></span>
                        <mat-divider [vertical]="true"></mat-divider>
                        <button mat-icon-button (click)="onStateReset()">
                            <mat-icon svgIcon="hdmui:xCircled"></mat-icon>
                        </button>
                    </mat-toolbar>
                    <div>
                        <mat-checkbox formControlName="paused">
                            <span>{{ 'DC.OPERATION_FILTERS.PAUSED' | translate }}</span>
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox formControlName="canBeStarted">
                            <span>{{ 'DC.OPERATION_FILTERS.CAN_BE_STARTED' | translate }}</span>
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox formControlName="startExceeded">
                            <span>{{ 'DC.OPERATION_FILTERS.START_EXCEEDED' | translate }}</span>
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox formControlName="waitingWarning">
                            <span>{{ 'DC.OPERATION_FILTERS.WAITING_WARNING' | translate }}</span>
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox formControlName="waitingError">
                            <span>{{ 'DC.OPERATION_FILTERS.WAITING_ERROR' | translate }}</span>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
