<section class="footer-leave-dialog hdmui-background-2">
    <div class="footer-leave-dialog__action-buttons">
        @if (hasUserActiveMachines) {
            <button mat-stroked-button (click)="onButtonClick(SIGN_OFF_WORKSTATION)">
                <span> {{ 'DC.LEAVE_DIALOG.SIGN_OFF_WORKSTATION' | translate }}</span>
            </button>
            <button mat-stroked-button (click)="onButtonClick(END_SHIFT)">
                <span> {{ 'DC.LEAVE_DIALOG.END_SHIFT' | translate }}</span>
            </button>
            <button class="button__last" mat-stroked-button (click)="onButtonClick(MANAGE_ASSISTANTS)">
                <span> {{ 'DC.LEAVE_DIALOG.MANAGE_ASSISTANTS' | translate }}</span>
            </button>
        }
        <button class="button__last" mat-button (click)="onButtonClick(false)">
            <span> {{ 'DC.LEAVE_DIALOG.CANCEL' | translate }}</span>
        </button>
    </div>
</section>
