import { Pipe, PipeTransform } from '@angular/core';

import { OperationDetails } from '@data-terminal/shared-models';
import { neitherNullNorUndefined } from '../../../../utils/src/lib/helpers/neither-null-nor-undefined';

@Pipe({
    name: 'otherOperationTimes',
    standalone: true,
})
export class OtherOperationTimesPipe implements PipeTransform {
    public transform(operationDetails?: OperationDetails): number {
        if (operationDetails) {
            const { actualTotal, actualGoodProduction, actualMakeReady } = operationDetails;
            return neitherNullNorUndefined(actualTotal) &&
                neitherNullNorUndefined(actualGoodProduction) &&
                neitherNullNorUndefined(actualMakeReady)
                ? actualTotal - actualGoodProduction - actualMakeReady
                : 0;
        }
        return 0;
    }
}
