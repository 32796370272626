<section class="comment-filter">
    <mat-toolbar>
        <h2>{{ 'DC.COMMENT_WORKFLOW_FILTERS.TITLE' | translate }}</h2>
        <span class="spacer"></span>
        <button mat-icon-button (click)="onFilterClose()">
            <mat-icon svgIcon="hdmui:x"></mat-icon>
        </button>
    </mat-toolbar>
    <form [formGroup]="filterGroup">
        <div class="comment-filter__container hdmui-p-3">
            <div class="hdmui-p-3">
                <mat-toolbar class="hdmui-no-border hdmui-font-bold">
                    {{ 'DC.COMMENT_WORKFLOW_FILTERS.TEXTSNIPPET_TITLE' | translate }}
                    <span class="hdmui-spacer"></span>
                    <mat-divider [vertical]="true"></mat-divider>
                    <button mat-icon-button (click)="onTextSnippetReset()">
                        <mat-icon svgIcon="hdmui:xCircled"></mat-icon>
                    </button>
                </mat-toolbar>
                <mat-form-field>
                    <input
                        matInput
                        [placeholder]="'DC.COMMENT_WORKFLOW_FILTERS.TEXTSNIPPET' | translate"
                        formControlName="textsnippet"
                    />
                </mat-form-field>
            </div>
        </div>
    </form>
</section>
