import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

import {
    Activity,
    CreateOperation,
    DataTerminalError,
    Operation,
    OperationAction,
    OperationActionRequest,
    OperationActionSignOff,
    OperationActionType,
    OperationDetails,
    SetCommentParam,
    SetCounterBody,
    StartOperationActivityBody,
} from '@data-terminal/shared-models';
import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';

import { FeatureOperationServiceModule } from '../../operation-service.module';
import { ApiRequestType } from '@data-terminal/utils';
import { ApiRequestResponseService } from '../../../../../data-access/src/lib/api-request-response/api-request-response.service';
import { IOTResponseError } from 'projects/shared-models/src/lib/datatransfer';

@Injectable({
    providedIn: FeatureOperationServiceModule,
})
export class OperationApiService {
    private readonly DO_START_URL = `${this.backendUrl}${this.orgRequestPrefix}dostart`;

    private readonly OPERATION_LIST_URL: (machineId: string) => string = (machineId) =>
        `${this.backendUrl}${this.orgRequestPrefix}operationlist/${machineId}`;

    private readonly OPERATION_BY_KEY_URL: (workstep: string) => string = (workstep) =>
        `${this.backendUrl}${this.orgRequestPrefix}getoperationbykey/${workstep}`;

    private readonly OPERATION_DETAIL_BY_KEY_URL: (workstep: string) => string = (workstep) =>
        `${this.backendUrl}${this.orgRequestPrefix}getoperationdetailbykey/${workstep}`;

    private readonly INTERRUPT_OPERATION_URL = `${this.backendUrl}${this.orgRequestPrefix}operationinterrupt`;

    private readonly FINISH_OPERATION_URL = `${this.backendUrl}${this.orgRequestPrefix}operationfinish`;

    private readonly SET_COUNTERS_URL = `${this.backendUrl}${this.orgRequestPrefix}setcounters`;

    private readonly ORDER_BAG_URL = this.backendUrl + 'api/session/getOrderbag';

    private readonly PRINT_PALLET_SHEET_URL = `${this.backendUrl}${this.orgRequestPrefix}printpalletsheet`;

    private readonly COMPLETED_OPERATIONLIST_URL: (machineId: string, jobId: string) => string = (machineId, jobId) =>
        this.backendUrl + this.orgRequestPrefix + `completedoperationlist/${machineId}/${jobId}`;

    private readonly OPERATIONLIST_BY_CLASS_URL: (machineId: string, jobId: string) => string = (machineId, jobId) =>
        this.backendUrl + this.orgRequestPrefix + `operationlistbyclass/${machineId}/${jobId}`;

    private readonly GET_COMMENTS_WORKFLOW: (workstep: string) => string = (workstep) =>
        this.backendUrl + this.orgRequestPrefix + `getComment/${workstep}`;

    private readonly OPERATIONLIST_BY_JOBID_URL: (jobId: string) => string = (jobId) =>
        this.backendUrl + this.orgRequestPrefix + `operationlistbyjob/${jobId}`;

    private readonly SET_COMMENT_WORKFLOW = this.backendUrl + this.orgRequestPrefix + `setComment`;

    private readonly CHECK_OPERATIONLIST_UPDATE: (machineId: string, operationListTime: string) => string = (
        machineId,
        operationListTime
    ) => `${this.backendUrl}${this.orgRequestPrefix}checkOperationListUpdate/${machineId}/${operationListTime}`;

    constructor(
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string,
        private readonly apiRequestResponseService: ApiRequestResponseService
    ) {}

    public getOperationList(machineId: string): Observable<Operation[]> {
        return this.http.get<Operation[]>(this.OPERATION_LIST_URL(machineId));
    }

    public checkOperationListUpdate(machineId: string, timestamp: string): Observable<boolean> {
        return this.http.get<boolean>(this.CHECK_OPERATIONLIST_UPDATE(machineId, timestamp));
    }

    public startOperationActivity(
        activity: Activity,
        operation: Operation,
        machineId: string,
        currentRunningOperation?: OperationAction
    ): Observable<Operation | DataTerminalError> {
        const body: StartOperationActivityBody = {
            primaryKey: operation.primaryKey,
            machineId: machineId,
            activityId: activity.actId,
            wasteCount: currentRunningOperation?.wasteAmount || 0,
            goodCount: currentRunningOperation?.goodAmount || 0,
            operationAction: this.mapActionType(currentRunningOperation?.actionType),
        };
        if (currentRunningOperation?.comment !== null && currentRunningOperation?.comment !== undefined) {
            body.comment = currentRunningOperation.comment;
        }
        return this.http.post<Operation | DataTerminalError>(this.DO_START_URL, body);
    }

    public getOperation(primaryKey: string): Observable<Operation> {
        return this.http.get<Operation>(this.OPERATION_BY_KEY_URL(primaryKey));
    }

    public getOperationDetails(primaryKey: string): Observable<OperationDetails> {
        return this.http.get<OperationDetails>(this.OPERATION_DETAIL_BY_KEY_URL(primaryKey));
    }

    public updateOperationAction({
        operationAction,
        primaryKey,
        machineId,
    }: OperationActionRequest): Observable<Operation> {
        const url = this.getOperationActionUrl(operationAction.actionType);
        const body = this.getSetCounterBody(operationAction, primaryKey, machineId);
        return this.http.post<Operation>(url, body);
    }

    public createNewOperation(
        createOperation: CreateOperation
    ): Observable<Operation | IOTResponseError | DataTerminalError> {
        return this.apiRequestResponseService
            .sendApiRequest<
                CreateOperation,
                Operation | IOTResponseError | DataTerminalError
            >(ApiRequestType.OPERATION_CREATE, createOperation)
            .pipe(map((requestMetadata) => requestMetadata?.data || ({} as Operation)));
    }

    private getSetCounterBody(operationAction: OperationAction, primaryKey: string, machineId: string): SetCounterBody {
        const body: SetCounterBody = {
            machineId: machineId,
            primaryKey: primaryKey,
        };
        if (operationAction.wasteAmount !== null && operationAction.wasteAmount !== undefined) {
            body.wasteCount = operationAction.wasteAmount || 0;
        }
        if (operationAction.goodAmount !== null && operationAction.goodAmount !== undefined) {
            body.goodCount = operationAction.goodAmount || 0;
        }
        if (operationAction.comment !== null && operationAction.comment !== undefined) {
            body.comment = operationAction.comment;
        }
        return body;
    }

    private getOperationActionUrl(actionType: OperationActionType): string {
        switch (actionType) {
            case OperationActionType.PAUSE:
                return this.INTERRUPT_OPERATION_URL;
            case OperationActionType.STOP:
                return this.FINISH_OPERATION_URL;
            case OperationActionType.ADD_REPORT:
                return this.SET_COUNTERS_URL;
        }
        return this.SET_COUNTERS_URL;
    }

    public getOrderBag(primaryKey: string, machineId: string): Observable<string> {
        return this.http.get<string>(this.ORDER_BAG_URL, {
            params: {
                primaryKey,
                machineId,
            },
        });
    }

    private mapActionType(action?: OperationActionType): OperationActionSignOff | undefined {
        switch (action) {
            case OperationActionType.STOP:
                return OperationActionSignOff.FINISH;
            case OperationActionType.PAUSE:
                return OperationActionSignOff.PAUSE;
            default:
                return undefined;
        }
    }

    public printPalletSheet(primaryKey: string, machineId: string): Observable<boolean> {
        return this.http.post<boolean>(this.PRINT_PALLET_SHEET_URL, {
            params: {
                primaryKey,
                machineId,
            },
        });
    }

    public completedOperationlist(machineId: string, jobId: string): Observable<Operation[]> {
        return this.http.get<Operation[]>(this.COMPLETED_OPERATIONLIST_URL(machineId, jobId));
    }

    public operationlistByClass(machineId: string, jobId: string): Observable<Operation[]> {
        return this.http.get<Operation[]>(this.OPERATIONLIST_BY_CLASS_URL(machineId, jobId));
    }

    public operationlistByJobId(jobId: string): Observable<Operation[]> {
        return this.http.get<Operation[]>(this.OPERATIONLIST_BY_JOBID_URL(jobId));
    }

    public getComment(workstep: string): Observable<string[]> {
        return this.http.get<string[]>(this.GET_COMMENTS_WORKFLOW(workstep));
    }

    public setComment(commentParam: SetCommentParam): Observable<boolean> {
        return this.http.post<boolean>(this.SET_COMMENT_WORKFLOW, commentParam);
    }
}
