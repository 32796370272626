import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

import { Operation, OperationActionType, StartOperationActivity } from '@data-terminal/shared-models';
import { requireMinValueOptional, requireOneControl } from '@data-terminal/utils';
import { AmountReportDetailsModule } from '../../amount-report-details/amount-report-details.component';
import { BehaviorSubject } from 'rxjs';
import { AmountDialogFormData, OperationAmountDetails } from '../../../models/amount-report-details.model';
import { NgxMaskDirective } from 'ngx-mask';

const TICK_250 = 250;

interface OperationActionForm {
    goodAmount: FormControl<number>;
    wasteAmount: FormControl<number>;
    comment: FormControl<string>;
    actionType: FormControl<OperationActionType>;
}

@UntilDestroy()
@Component({
    standalone: true,
    templateUrl: './ongoing-operation-dialog.component.html',
    styleUrls: ['./ongoing-operation-dialog.component.scss'],
    imports: [
        HdmuiComponentsModule,
        CommonModule,
        MatButtonModule,
        TranslateModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatInputModule,
        AmountReportDetailsModule,
        NgxMaskDirective,
    ],
})
export class OngoingOperationDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('goodAmountInput') goodAmountInput!: ElementRef<HTMLInputElement>;
    public readonly DIALOG_OPTION = HdmuiBaseDialogComponent.OPTION_YES_NO;

    public isYesButtonEnabled = false;

    public PAUSE_ACTION = OperationActionType.PAUSE;
    public STOP_ACTION = OperationActionType.STOP;

    public formGroup!: FormGroup<OperationActionForm>;

    public runningOperation: Operation;
    public counterboxTimestamp: string;

    public formData$ = new BehaviorSubject<AmountDialogFormData | null>({
        goodAmount: 0,
        wasteAmount: 0,
    });
    public operationData$ = new BehaviorSubject<OperationAmountDetails | null>(null);

    constructor(
        private readonly dialogRef: MatDialogRef<OngoingOperationDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly dialogData: {
            startOperationActivity: StartOperationActivity;
            runningOperation: Operation;
            isManualMachine: boolean;
            counterboxTimestamp: string;
        }
    ) {
        this.runningOperation = dialogData.runningOperation;
        this.counterboxTimestamp = dialogData.counterboxTimestamp;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.formGroup.markAllAsTouched();
            this.goodAmountInput.nativeElement.select();
        }, TICK_250);
    }

    ngOnInit(): void {
        this.createFormGroup();
    }

    public submit(): void {
        this.dialogRef.close({
            ...this.dialogData.startOperationActivity,
            currentRunningOperation: { ...this.formGroup.value },
        } as StartOperationActivity);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public onInputOut(): void {
        this.formGroup.markAllAsTouched();
    }

    private createFormGroup(): void {
        this.formGroup = new FormGroup<OperationActionForm>(
            {
                goodAmount: new FormControl(),
                wasteAmount: new FormControl(),
                comment: new FormControl(),
                actionType: new FormControl(this.STOP_ACTION, { nonNullable: true }),
            },
            [
                requireOneControl('goodAmount', 'wasteAmount'),
                requireMinValueOptional('goodAmount', 0),
                requireMinValueOptional('wasteAmount', 0),
            ]
        );
        this.listenFormValueChanges();
        if (
            this.runningOperation.opPlannedGoodCtr !== undefined &&
            this.runningOperation.opPlannedGoodCtr !== null &&
            this.runningOperation.goodAmount !== undefined &&
            this.runningOperation.goodAmount !== null
        ) {
            if (this.counterboxTimestamp.length > 0) {
                this.formGroup.get('goodAmount')?.setValue(0);
            } else {
                this.formGroup
                    .get('goodAmount')
                    ?.setValue(Math.max(this.runningOperation.opPlannedGoodCtr - this.runningOperation.goodAmount, 0));
            }

            this.isYesButtonEnabled = this.formGroup.valid;
            this.operationData$.next({
                currentGood: this.runningOperation.opCtrGood,
                plannedGood: this.runningOperation.opPlannedGoodCtr,
                currentWaste: this.runningOperation.opCtrWaste,
                plannedWaste: this.runningOperation.opPlannedWasteCtr,
            });
        }
    }

    private listenFormValueChanges(): void {
        this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((data) => {
            this.isYesButtonEnabled = this.formGroup.valid;
            this.formData$.next({
                goodAmount: data.goodAmount || 0,
                wasteAmount: data.wasteAmount || 0,
            });
        });
    }
}
