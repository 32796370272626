import { Component, Input, NgModule, OnInit } from '@angular/core';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AssistantsSignOff, SignOffEmployees } from '@data-terminal/shared-models';

import { SignOffStepCardComponent } from '../../sign-off-card-component.abstract';
import { FilterAvailableCaptainsPipe } from '../../../pipes/filter-available-captains.pipe';

@Component({
    selector: 'data-terminal-sign-off-employees-card',
    templateUrl: './sign-off-employees-card.component.html',
    styleUrls: ['./sign-off-employees-card.component.scss'],
})
export class SignOffEmployeesCardComponent extends SignOffStepCardComponent<SignOffEmployees> implements OnInit {
    @Input() public operatorId!: string;

    public readonly STAY = AssistantsSignOff.STAY;
    public readonly LEAVE = AssistantsSignOff.LEAVE;

    public setSelectState($event: MatRadioChange): void {
        if ($event.value === AssistantsSignOff.LEAVE) {
            this.formGroup.controls.newCaptain.disable();
        } else {
            this.formGroup.controls.newCaptain.enable();
        }
    }

    public override createFormGroup(): void {
        this.formGroup = new UntypedFormGroup({
            assistantsSignOff: new UntypedFormControl(AssistantsSignOff.LEAVE, Validators.required),
            newCaptain: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
        });
    }
}

@NgModule({
    declarations: [SignOffEmployeesCardComponent],
    exports: [SignOffEmployeesCardComponent],
    imports: [
        MatRadioModule,
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCardModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FilterAvailableCaptainsPipe,
    ],
})
export class SignOffEmployeesCardModule {}
