<div class="operation-details-info">
    <section class="hdmui-content hdmui-mb-4">
        <h2 class="operation-details-info__category-header hdmui-mb-4">
            {{ 'DC.OPERATIONS.OPERATION_DETAILS.GENERAL_DATA' | translate }}
        </h2>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.OPERATION_STATUS' | translate" class="hdmui-ms-0">
            {{ operationDetails.status || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.STATUS' | translate" class="hdmui-ms-0 hdmui-mb-4">
            {{
                operationDetails.operationStatus !== undefined
                    ? (operationDetails.operationStatus | operationState | translate)
                    : '-'
            }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.JOB_NUMBER' | translate" class="hdmui-ms-0">
            {{ operationDetails.jobNumber || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.JOB_NAME' | translate" class="hdmui-ms-0">
            {{ operationDetails.jobName || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.MACHINE' | translate" class="hdmui-ms-0 hdmui-mb-4">
            {{ operationDetails.machine || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.DUE_DATE' | translate" class="hdmui-ms-0">
            {{ operationDetails.dueDate ? (operationDetails.dueDate | date: 'short' || '-') : '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.DELIVERY_QUANTITY' | translate" class="hdmui-ms-0">
            {{ (operationDetails.deliveryQuantity | number) || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.CUSTOMER_NAME' | translate" class="hdmui-ms-0">
            {{ operationDetails.customerName || '-' }}
        </hdmui-key-value>
    </section>

    <section class="hdmui-content hdmui-mb-4 hdmui-mt-4">
        <h2 class="operation-details-info__category-header hdmui-mb-4">
            {{ 'DC.OPERATIONS.OPERATION_DETAILS.TIMES' | translate }}
        </h2>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.PLANNED_START' | translate" class="hdmui-ms-0">
            {{ operationDetails.plannedStart ? (operationDetails.plannedStart | date: 'short' || '-') : '-' }}
        </hdmui-key-value>
        <hdmui-key-value
            [label]="'DC.OPERATIONS.OPERATION_DETAILS.PLANNED_END' | translate"
            class="hdmui-ms-0 hdmui-mb-4"
        >
            {{ operationDetails.plannedEnd ? (operationDetails.plannedEnd | date: 'short' || '-') : '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.PLANNED_MAKE_READY' | translate" class="hdmui-ms-0">
            {{ operationDetails.plannedMakeReady | timeFormatter }}
        </hdmui-key-value>
        <hdmui-key-value
            [label]="'DC.OPERATIONS.OPERATION_DETAILS.PLANNED_GOOD_PRODUCTION' | translate"
            class="hdmui-ms-0"
        >
            {{ operationDetails.plannedGoodProduction | timeFormatter }}
        </hdmui-key-value>
        <hdmui-key-value
            [label]="'DC.OPERATIONS.OPERATION_DETAILS.PLANNED_TOTAL' | translate"
            class="hdmui-ms-0 hdmui-mb-4"
        >
            {{ operationDetails.plannedTotal | timeFormatter }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.ACTUAL_MAKE_READY' | translate" class="hdmui-ms-0">
            {{ operationDetails.actualMakeReady | timeFormatter }}
        </hdmui-key-value>
        <hdmui-key-value
            [label]="'DC.OPERATIONS.OPERATION_DETAILS.ACTUAL_GOOD_PRODUCTION' | translate"
            class="hdmui-ms-0"
        >
            {{ operationDetails.actualGoodProduction | timeFormatter }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.OTHER_TIMES' | translate" class="hdmui-ms-0">
            {{ operationDetails | otherOperationTimes | timeFormatter }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.ACTUAL_TOTAL' | translate" class="hdmui-ms-0">
            {{ operationDetails.actualTotal | timeFormatter }}
        </hdmui-key-value>
    </section>

    <section class="hdmui-content hdmui-mb-4 hdmui-mt-4">
        <h2 class="operation-details-info__category-header hdmui-mb-4">
            {{ 'DC.OPERATIONS.OPERATION_DETAILS.AMOUNTS' | translate }}
        </h2>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.PLANNED_AMOUNT' | translate" class="hdmui-ms-0">
            {{ (operationDetails.plannedAmount | number) || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.PLANNED_GOOD' | translate" class="hdmui-ms-0">
            {{ (operationDetails.plannedGood | number) || '-' }}
        </hdmui-key-value>
        <hdmui-key-value
            [label]="'DC.OPERATIONS.OPERATION_DETAILS.PLANNED_WASTE' | translate"
            class="hdmui-ms-0 hdmui-mb-4"
        >
            {{ (operationDetails.plannedWaste | number) || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.PRODUCED_AMOUNT' | translate" class="hdmui-ms-0">
            {{ (operationDetails.producedAmount | number) || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.PRODUCED_GOOD' | translate" class="hdmui-ms-0">
            {{ (operationDetails.producedGood | number) || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.PRODUCED_WASTE' | translate" class="hdmui-ms-0">
            {{ (operationDetails.producedWaste | number) || '-' }}
        </hdmui-key-value>
    </section>

    <section class="hdmui-content hdmui-mb-4 hdmui-mt-4">
        <h2 class="operation-details-info__category-header hdmui-mb-4">
            {{ 'DC.OPERATIONS.OPERATION_DETAILS.PAPER_INFORMATION' | translate }}
        </h2>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.BRAND' | translate" class="hdmui-ms-0">
            {{ operationDetails.brand || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.GRAMMATURE' | translate" class="hdmui-ms-0">
            {{ (operationDetails.grammage | unitFormatter: unitType.GRAMMAGE : 3) || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'DC.OPERATIONS.OPERATION_DETAILS.PAPER_ID' | translate" class="hdmui-ms-0">
            {{ operationDetails.paperId || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'CR.REPORTS.INFORMATION.PAPER_GRADE' | translate" class="hdmui-ms-0">
            {{ operationDetails.paperGrade || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'COM_ATTR_THICKNESS' | translate" class="hdmui-ms-0">
            {{ (operationDetails.thickness | unitFormatter: unitType.LENGTH : 3) || '-' }}
        </hdmui-key-value>
        <hdmui-key-value [label]="'COM_ATTR_GRAINDIRECTION' | translate" class="hdmui-ms-0">
            {{ operationDetails.grainDirection || '-' }}
        </hdmui-key-value>
    </section>

    <app-legal-line></app-legal-line>
</div>
