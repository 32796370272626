import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { AngularSplitModule, IOutputData } from 'angular-split';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, filter, map, Observable, switchMap, tap } from 'rxjs';

import {
    ActivityState,
    NewOperation,
    Operation,
    OperationFilter,
    OperationSettingsEntry,
    ROUTE_PARAMS,
    SplitPanelConfig,
} from '@data-terminal/shared-models';
import {
    ErrorMessageComponentModule,
    LoadingIndicatorComponentModule,
    MetadataDirectivesModule,
} from '@data-terminal/ui-presentational';
import {
    getParams,
    PAGE_VISIBILITY_CHANGE,
    RequestMetadata,
    ResizeService,
    takeUntilPageVisible,
} from '@data-terminal/utils';

import { OperationsListModule } from '../../components/operations/operations-list/operations-list.component';
import { OperationComponentModule } from '../../components/operations/operation/operation.component';
import { OperationFiltersModule } from '../../components/operations/operation-filters/operation-filters.component';
import { OperationPresenter } from './operation.presenter';
import {
    OperationsSplitPanelLocalStorageKey,
    OperationsSplitPanelLocalStorageService,
} from '../../services/operations-split-panel-local-storage/operations-split-panel-local-storage.service';

const PANELSIZE_MIN_3 = 3;
const PANELSIZE_MAX_3 = 3;

@UntilDestroy()
@Component({
    selector: 'data-terminal-operations-page',
    templateUrl: './operations-page.component.html',
    styleUrls: ['./operations-page.component.scss'],
    providers: [OperationPresenter],
})
export class OperationsPageComponent implements OnInit {
    public readonly CARD_WIDTH_WITH_MARGINS_PX = 324;
    public readonly PLACE_FOR_SCROLLBAR_PX = 32;

    public operationSettings!: OperationSettingsEntry;

    public operationList$!: Observable<RequestMetadata<Operation[]>>;
    public filter?: OperationFilter;

    public splitPanelConfig: SplitPanelConfig = {
        isSplitPanelLocked: false,
        leftPanelSizePx: null,
        leftPanelMaxSizePx: null,
        rightPanelMaxSizePx: null,
        rightPanelMinSizePx: null,
    };
    public selectedOperation$: Observable<Operation | undefined> = this.operationPresenter.getSelectedOperation$().pipe(
        filter((d) => !!d.data),
        map((d) => d.data)
    );

    private _firstLoad = true;

    private currentSplitPanelLocalStorageKey!: OperationsSplitPanelLocalStorageKey;

    constructor(
        @Inject(PAGE_VISIBILITY_CHANGE) public readonly visibilityChange$: Observable<boolean>,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly operationPresenter: OperationPresenter,
        private readonly resizeService: ResizeService,
        private readonly splitPanelLocalStorageService: OperationsSplitPanelLocalStorageService
    ) {
        this.operationPresenter.openedMachineOperationSettings$
            .pipe(untilDestroyed(this))
            .subscribe((operationSettings) => {
                this.operationSettings = operationSettings;
            });
    }

    public ngOnInit(): void {
        this.operationList$ = this.visibilityChange$.pipe(
            untilDestroyed(this),
            filter((isVisible) => isVisible),
            switchMap(() =>
                this.operationPresenter.operationList$.pipe(
                    filter((data) => !!data.data || this._firstLoad),
                    tap((metadata) => {
                        this._firstLoad = false;
                        this.redirectToOperationChild(metadata);
                    }),
                    takeUntilPageVisible(this.visibilityChange$)
                )
            )
        );

        this.listenToWindowResizing();
    }

    public operationClick(operation: Operation): void {
        this.operationPresenter.triggerSelectOperation(operation.primaryKey);
    }

    public onCreateNewOperation(createOperation: NewOperation): void {
        this.operationPresenter.triggerCreateOperation(createOperation);
    }

    public onFilterChange(localFilter: OperationFilter): void {
        this.filter = localFilter;
    }

    public onSplitPanelDragEnd(event: IOutputData): void {
        const leftPanelSizePx = event.sizes[0];

        if (typeof leftPanelSizePx === 'number') {
            this.splitPanelConfig.leftPanelSizePx = leftPanelSizePx;
            this.splitPanelLocalStorageService.saveLeftPanelSizePx(
                this.currentSplitPanelLocalStorageKey,
                this.splitPanelConfig.leftPanelSizePx
            );
        }
    }

    private redirectToOperationChild(metadata: RequestMetadata<Operation[]>): void {
        if (metadata.data && metadata.data?.length > 0) {
            const params = getParams(this.activatedRoute);
            const primaryKey = params[ROUTE_PARAMS.primaryKey]
                ? params[ROUTE_PARAMS.primaryKey]
                : metadata.data.find(
                      (el) =>
                          el.opState === ActivityState.SETUP ||
                          el.opState === ActivityState.IN_PROGRESS ||
                          el.opState === ActivityState.CLEANUP ||
                          el.opState === ActivityState.STOPPED
                  )?.primaryKey || metadata.data[0].primaryKey;
            this.router.navigate([primaryKey], { relativeTo: this.activatedRoute });

            //TODO: Nicing up (alternate path in tenary expression not needed)
            // if (params[ROUTE_PARAMS.primaryKey] !== undefined) {
            //     const primaryKey = params[ROUTE_PARAMS.primaryKey]
            //         ? params[ROUTE_PARAMS.primaryKey]
            //         : metadata.data.find(
            //               (el) => el.opState === ActivityState.SETUP || el.opState === ActivityState.IN_PROGRESS
            //           )?.primaryKey || metadata.data[0].primaryKey;
            //     this.router.navigate([primaryKey], { relativeTo: this.activatedRoute });
            // }
        }
    }

    private listenToWindowResizing(): void {
        combineLatest([
            this.resizeService.isTablet$,
            this.resizeService.isDesktopLarge$,
            this.resizeService.isDesktopSuperLarge$,
            this.resizeService.windowSize$,
        ])
            .pipe(
                untilDestroyed(this),
                map(([isTablet, isDesktopLarge, isDesktopSuperLarge, windowSize]) => ({
                    isTablet,
                    isDesktopLarge,
                    isDesktopSuperLarge,
                    windowSize,
                }))
            )
            .subscribe(({ isTablet, isDesktopLarge, isDesktopSuperLarge, windowSize }) => {
                this.splitPanelConfig.rightPanelMaxSizePx =
                    windowSize - this.CARD_WIDTH_WITH_MARGINS_PX - this.PLACE_FOR_SCROLLBAR_PX;

                const setPanelSizes: (cardsInRow: number, maxCardsInRow: number) => void = (
                    cardsInRow,
                    maxCardsInRow
                ) => {
                    const savedLeftPanelSizePx = this.splitPanelLocalStorageService.getLeftPanelSizePx(
                        this.currentSplitPanelLocalStorageKey
                    );

                    this.splitPanelConfig.leftPanelSizePx = savedLeftPanelSizePx
                        ? savedLeftPanelSizePx
                        : cardsInRow * this.CARD_WIDTH_WITH_MARGINS_PX + this.PLACE_FOR_SCROLLBAR_PX;

                    this.splitPanelConfig.leftPanelMaxSizePx =
                        maxCardsInRow * this.CARD_WIDTH_WITH_MARGINS_PX + this.PLACE_FOR_SCROLLBAR_PX;

                    this.splitPanelConfig.rightPanelMinSizePx = windowSize - this.splitPanelConfig.leftPanelMaxSizePx;
                };

                if (isTablet) {
                    this.currentSplitPanelLocalStorageKey = OperationsSplitPanelLocalStorageKey.TABLET;
                    setPanelSizes(1, 1);
                    this.splitPanelConfig.isSplitPanelLocked = true;
                }

                if (isDesktopLarge) {
                    this.currentSplitPanelLocalStorageKey = OperationsSplitPanelLocalStorageKey.DESKTOP_LARGE;
                    setPanelSizes(2, PANELSIZE_MAX_3);
                    this.splitPanelConfig.isSplitPanelLocked = false;
                }

                if (isDesktopSuperLarge) {
                    this.currentSplitPanelLocalStorageKey = OperationsSplitPanelLocalStorageKey.DESKTOP_SUPER_LARGE;
                    setPanelSizes(PANELSIZE_MIN_3, 4);
                    this.splitPanelConfig.isSplitPanelLocked = false;
                }
            });
    }
}

@NgModule({
    declarations: [OperationsPageComponent],
    exports: [OperationsPageComponent],
    imports: [
        AngularSplitModule,
        OperationsListModule,
        OperationComponentModule,
        CommonModule,
        LoadingIndicatorComponentModule,
        MetadataDirectivesModule,
        ErrorMessageComponentModule,
        MatSidenavModule,
        TranslateModule,
        RouterModule,
        OperationFiltersModule,
    ],
})
export class OperationsPageModule {}
