import { inject, Injectable } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { combineLatest, filter, interval, map, Subject, switchMap, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { MS_IN_SECOND, SnackBarService } from '@data-terminal/utils';
import { DataTerminalError, TimeModeEntry, TimeModeStatus } from '@data-terminal/shared-models';

import { PureTimeApiService } from '../services/pure-time-api/pure-time-api.service';
import { TimeModeErrorId } from '../models/time-mode-error-id.enum';

export interface PureTimeModeState {
    machineSignInTimestamp: number;
    entries: TimeModeEntry[];
    initialLoadDone: boolean;
    isSubmitting: boolean;
}

@Injectable()
export class PureTimeModeFacade {
    readonly #state: RxState<PureTimeModeState> = inject(RxState<PureTimeModeState>);
    readonly #pureTimeApiService = inject(PureTimeApiService);
    readonly #snackBarService = inject(SnackBarService);
    readonly #translateService = inject(TranslateService);

    public readonly state$ = this.#state.select();

    readonly updateEntries$ = new Subject<TimeModeEntry[]>();
    readonly deleteEntry$ = new Subject<TimeModeEntry>();
    readonly submitEntries$ = new Subject<void>();
    readonly fetchEntries$ = new Subject<void>();

    constructor() {
        this.#state.set({
            initialLoadDone: false,
            entries: [],
            isSubmitting: false,
        });

        this.#state.connect(
            'entries',
            combineLatest([this.#state.select('machineSignInTimestamp'), this.fetchEntries$]).pipe(
                map(([machineSignInTimestamp]) => machineSignInTimestamp),
                filter((machineSignInTimestamp) => !!machineSignInTimestamp),
                switchMap((machineSignInTimestamp) => this.#pureTimeApiService.getTimeEntries(machineSignInTimestamp)),
                tap((entries) =>
                    this.#state.set({
                        initialLoadDone: true,
                        isSubmitting: entries.some((entry) => entry.status === TimeModeStatus.PENDING),
                    })
                )
            )
        );

        this.#state.hold(
            this.updateEntries$.pipe(
                switchMap((entries) =>
                    this.#pureTimeApiService.updateEntries(entries, this.#state.get('machineSignInTimestamp')).pipe(
                        tap((response) => {
                            const hasError = (response as DataTerminalError).error;

                            if (hasError) {
                                const error = response as DataTerminalError;
                                const errorMessage: string =
                                    error.errorId === TimeModeErrorId.FUTURE_DURATION
                                        ? 'DC.PURE_TIME.UPDATE.ERROR.FUTURE_TIME'
                                        : 'DC.PURE_TIME.UPDATE.ERROR';

                                this.#snackBarService.openSnackBar(
                                    this.#translateService.instant(errorMessage),
                                    'hdmui-error'
                                );
                            } else {
                                this.#state.set({
                                    entries: response as TimeModeEntry[],
                                });
                            }
                        })
                    )
                )
            )
        );

        this.#state.hold(
            this.deleteEntry$.pipe(
                switchMap((entry) =>
                    this.#pureTimeApiService.deleteEntry(entry, this.#state.get('machineSignInTimestamp')).pipe(
                        tap((entries) =>
                            this.#state.set({
                                entries: entries,
                            })
                        )
                    )
                )
            )
        );

        this.#state.hold(
            this.submitEntries$.pipe(
                tap(() => {
                    this.#state.set({ isSubmitting: true });
                }),
                switchMap(() =>
                    this.#pureTimeApiService.startSubmittingEntries(this.#state.get('machineSignInTimestamp'))
                )
            )
        );

        this.#state.hold(
            interval(MS_IN_SECOND).pipe(
                filter(() => this.#state.get('isSubmitting')),
                tap(() => this.triggerFetch())
            )
        );
    }

    public setMachineSignInTimestamp(machineSignInTimestamp: number): void {
        this.#state.set({ machineSignInTimestamp });
    }

    public updateEntry(entry: TimeModeEntry): void {
        this.updateEntries$.next([entry]);
    }

    public deleteEntry(entry: TimeModeEntry): void {
        this.deleteEntry$.next(entry);
    }

    public submitEntries(): void {
        this.submitEntries$.next();
    }

    public triggerFetch(): void {
        this.fetchEntries$.next();
    }
}
