import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveData, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';

import { getParamsSnapshot } from '@data-terminal/utils';
import { Machine, ROUTE_PARAMS } from '@data-terminal/shared-models';
import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';

@Injectable({
    providedIn: 'root',
})
export class MachineClassResolver {
    private readonly MACHINE_BY_ID_URL: (machineId: string) => string = (machineId: string) =>
        `${this.backendUrl}${this.orgRequestPrefix}machinebyid/${machineId}`;

    constructor(
        private readonly router: Router,
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<string> {
        const machineId = getParamsSnapshot(route)[ROUTE_PARAMS.machineId];
        const navigationExtrasState = this.router.getCurrentNavigation()?.extras.state;
        if (navigationExtrasState?.workstation.machineClass) {
            return of(navigationExtrasState?.workstation.machineClass);
        } else {
            return this.getMachineClass(machineId);
        }
    }

    private getMachineClass(machineId: string): Observable<string> {
        return this.http.get<Machine>(this.MACHINE_BY_ID_URL(machineId)).pipe(map((data) => data.machineClass));
    }
}

export function machineClassResolver(): ResolveData {
    return { machineClass: MachineClassResolver };
}
