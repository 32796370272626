<hdmui-base-dialog
    [okEnabled]="!!logTimeFormComponent?.form?.valid"
    [dialogOptions]="option"
    (cancelClicked)="onCancelDialog()"
    (okClicked)="onConfirmDialog()"
    [contentNoPadding]="true"
    [okText]="'DC.PURE_TIME.LOG_TIME' | translate"
    [cancelText]="'*.action.cancel' | translate"
>
    <div class="hdmui-container">
        <header>
            <h2 class="hdmui-m-3 hdmui-pb-3">{{ 'DC.PURE_TIME.LOG_TIME' | translate }}</h2>
        </header>
        <section class="hdmui-content hdmui-p-0">
            <data-terminal-log-time-form
                #logTimeFormComponent
                [activities]="data.activities"
                [lastLoggedActivityTimestamp]="data.lastLoggedActivityTimestamp"
                [machineClass]="data.machineClass"
            ></data-terminal-log-time-form>
        </section>
    </div>
</hdmui-base-dialog>
