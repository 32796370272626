import { Injectable } from '@angular/core';
import { SignOffApiService } from '../sign-off-api/sign-off-api.service';
import { SignOffList, SignOffMachine, User, UserMachines } from '@data-terminal/shared-models';
import { Observable, Subject } from 'rxjs';
import { RequestMetadata } from '@data-terminal/utils';

@Injectable({
    providedIn: 'root',
})
export class SignOffService {
    private readonly _triggerUpdateMachines$ = new Subject<RequestMetadata<UserMachines>>();

    constructor(private readonly signOffApi: SignOffApiService) {}

    public signOffMachines(data: SignOffMachine[], user: User): Observable<UserMachines> {
        const signOffList: SignOffList = {
            userId: user.userId,
            signofflist: data.map(
                (d) =>
                    ({
                        machineId: d.machineId,
                        assistantsSignOff: d.assistantsSignOff,
                        runningOperation: d.runningOperation,
                        operationAction: d.operationAction,
                        newCaptain: d.newCaptain,
                        goodCount: d.goodCount,
                        wasteCount: d.wasteCount,
                        comment: d.comment,
                        isAssistant: d.isAssistant,
                    }) as SignOffMachine
            ),
        };
        return this.signOffApi.signOff(signOffList);
    }

    public getUserMachinesMetadata$(): Observable<RequestMetadata<UserMachines>> {
        return this._triggerUpdateMachines$.asObservable();
    }

    public dispatchMachines(data: UserMachines): void {
        this._triggerUpdateMachines$.next({ data } as RequestMetadata<UserMachines>);
    }
}
