import { Injectable } from '@angular/core';
import { EMPTY, filter, map, Observable, of, Subject, switchMap, tap } from 'rxjs';

import { LoginTypeAndMachineId, Machine, Role, SetAssistantsEntry, UserMachines } from '@data-terminal/shared-models';
import { CloudSessionService, DataTerminalSessionService } from '@data-terminal/data-access';
import { RequestMetadata } from '@data-terminal/utils';
import { SignOffService } from '@data-terminal/feature-dialogs';

import { UserMachinesApiService } from './user-machines-api.service';

@Injectable({
    providedIn: 'root',
})
export class UserMachinesService {
    public readonly machinesAfterSignOff$: Observable<RequestMetadata<UserMachines>>;

    private readonly _updateMachinesTriggerSubject = new Subject<void>();
    private readonly updateMachinesTrigger$ = this._updateMachinesTriggerSubject.asObservable();

    constructor(
        private readonly signOffService: SignOffService,
        private readonly apiService: UserMachinesApiService,
        private readonly cloudSessionService: CloudSessionService,
        private readonly dataTerminalSessionService: DataTerminalSessionService
    ) {
        this.machinesAfterSignOff$ = this.signOffService.getUserMachinesMetadata$().pipe(this.handleUserMachinesData);
    }

    public getUserMachines(): Observable<RequestMetadata<UserMachines>> {
        if (this.dataTerminalSessionService.getCurrentDataTerminalUser()) {
            const { userId } = this.dataTerminalSessionService.getCurrentDataTerminalUser();
            return this.apiService.getUserMachines(userId).pipe(this.handleUserMachinesData);
        }
        return EMPTY;
    }

    public setAssistants(entries: SetAssistantsEntry[]): Observable<RequestMetadata<UserMachines>> {
        if (this.dataTerminalSessionService.getCurrentDataTerminalUser()) {
            const { userId } = this.dataTerminalSessionService.getCurrentDataTerminalUser();

            return this.apiService.setAssistants(userId, entries).pipe(
                this.handleUserMachinesData,
                switchMap((metadata) => this.updatePreselectedMachines(metadata))
            );
        }
        return EMPTY;
    }

    public hasMachineAnotherOperator(machine: Machine): boolean {
        const currentUser = this.dataTerminalSessionService.getCurrentDataTerminalUser();
        return !!machine.signedOnUsers?.find(
            (user) => user.role === Role.OPERATOR && user.userId !== currentUser.userId
        );
    }

    public assignAndUpdateMachines(signInData: LoginTypeAndMachineId[]): Observable<RequestMetadata<UserMachines>> {
        return this.apiService
            .assignMachines(this.dataTerminalSessionService.getCurrentDataTerminalUser(), signInData)
            .pipe(
                this.handleUserMachinesData,
                switchMap((metadata) => this.updatePreselectedMachines(metadata))
            );
    }

    public triggerUpdateMachines(): void {
        this._updateMachinesTriggerSubject.next();
    }

    public getUpdateMachinesTrigger$(): Observable<void> {
        return this.updateMachinesTrigger$;
    }

    private readonly handleUserMachinesData = (
        stream$: Observable<RequestMetadata<UserMachines>>
    ): Observable<RequestMetadata<UserMachines>> =>
        stream$.pipe(
            filter((metadata) => !!metadata.data),
            map((metadata) => this.addPreselectedMachines(metadata.data || ({} as UserMachines))),
            tap((metadata) => this.updateLoggedOnMachine(metadata.data || ({} as UserMachines)))
        );

    private addPreselectedMachines(userMachines: UserMachines): RequestMetadata<UserMachines> {
        return {
            isLoading: false,
            data: { ...userMachines, preselectedMachines: this.getPreselectedMachines(userMachines) },
        };
    }

    private getPreselectedMachines(data: UserMachines): Machine[] {
        const machineFromDayBefore = this.cloudSessionService.getSessionData().machines || [];
        return [
            ...data.allMachines.filter(
                (machine) => !!machineFromDayBefore.find((id: string) => id === machine.machineId)
            ),
        ];
    }

    private updateLoggedOnMachine(machines: UserMachines): void {
        this.dataTerminalSessionService.setLoggedOnMachines(machines.assignedMachines);
    }

    private updatePreselectedMachines(
        metadata: RequestMetadata<UserMachines>
    ): Observable<RequestMetadata<UserMachines>> {
        if (metadata.data) {
            const machines = metadata.data.assignedMachines.map((el) => el.machineId);
            return this.cloudSessionService.updateMachines(machines).pipe(map(() => metadata));
        }

        return of(metadata);
    }
}
