import {
    AssistantsSignOff,
    Machine,
    OperationActionSignOff,
    SignOffDialogConfig,
    SignOffMachine,
    TimeModeEntry,
    User,
} from '@data-terminal/shared-models';
import { isCurrentUserAnOperator } from '@data-terminal/utils';

export function shouldDisplayDialog(user: User, unsubmittedTimeEntries: TimeModeEntry[]): boolean {
    if (hasMoreThanOneMachine(user.userMachines)) {
        return true;
    }

    if (hasTeamMembers(user.userMachines[0])) {
        return isCurrentUserAnOperator(user.userMachines[0], user.userId);
    }

    if (hasUnsubmittedTimeEntries(unsubmittedTimeEntries)) {
        return true;
    }

    return isOperationActivityRunning(user.userMachines[0]);
}

export function hasMoreThanOneMachine(machines: Machine[]): boolean {
    return machines.length > 1;
}

export function hasTeamMembers(machine: Machine): boolean {
    return machine.signedOnUsers.length > 1;
}

export function isOperationActivityRunning(machine: Machine): boolean {
    return !!machine.runningOperation?.opName;
}

export function hasUnsubmittedTimeEntries(unsubmittedTimeEntries: TimeModeEntry[]): boolean {
    return !!unsubmittedTimeEntries.length;
}

export function shouldHandleOperationBecauseAssistantsWillBeLoggedOff(signOffMachine: SignOffMachine): boolean {
    return !signOffMachine.assistantsSignOff || signOffMachine.assistantsSignOff === AssistantsSignOff.LEAVE;
}

export function updatesOperation(currentMachine: SignOffMachine): SignOffMachine {
    return currentMachine.assistantsSignOff === AssistantsSignOff.STAY
        ? {
              ...currentMachine,
              operationAction: undefined,
              runningOperation: undefined,
              goodCount: undefined,
              wasteCount: undefined,
              comment: undefined,
          }
        : { ...currentMachine };
}

export function createInitialDialogProcessConfig(
    machines: Machine[],
    user: User,
    unsubmittedTimeEntries: TimeModeEntry[],
    isEndShift = false
): SignOffDialogConfig {
    const hasTeam = hasAtLeastOneTeamInMachines(machines, user.userId);
    const hasOperationRunning = hasAtLeastOneOperationRunningInMachines(machines, user.userId);
    const multipleMachines = hasMoreThanOneMachine(user.userMachines);
    const hasUnsubmittedTimes = hasUnsubmittedTimeEntries(unsubmittedTimeEntries);
    return {
        workstations: isEndShift ? false : multipleMachines,
        employees: isEndShift ? false : hasTeam,
        operations: hasOperationRunning,
        summary: multipleMachines || hasOperationRunning || hasTeam || isEndShift || hasUnsubmittedTimes,
    };
}

function hasAtLeastOneTeamInMachines(machines: Machine[], userId: string): boolean {
    return !!machines.find((machine) => hasTeamMembers(machine) && isCurrentUserAnOperator(machine, userId));
}

function hasAtLeastOneOperationRunningInMachines(machines: Machine[], userId: string): boolean {
    return !!machines.find(
        (machine) => isOperationActivityRunning(machine) && isCurrentUserAnOperator(machine, userId)
    );
}

export function prepareUserMachinesToSignOff(machines: Machine[], user: User): SignOffMachine[] {
    return machines.map((machine) => {
        if (isCurrentUserAnOperator(machine, user.userId)) {
            return createOperatorSignOffMachineObject(machine);
        } else {
            return createDefaultSignOffMachineObject(machine);
        }
    });
}

function createDefaultSignOffMachineObject(machine: Machine): SignOffMachine {
    return {
        machine: machine,
        machineId: machine.machineId,
        assistantsSignOff: undefined,
        runningOperation: undefined,
        wasteCount: undefined,
        goodCount: undefined,
        comment: undefined,
        newCaptain: undefined,
        operationAction: undefined,
        isAssistant: true,
    } as SignOffMachine;
}

function createOperatorSignOffMachineObject(machine: Machine): SignOffMachine {
    return {
        machine: machine,
        machineId: machine.machineId,
        assistantsSignOff: machine.signedOnUsers.length > 1 ? AssistantsSignOff.LEAVE : undefined,
        runningOperation: machine.runningOperation?.primaryKey ? machine.runningOperation?.primaryKey : undefined,
        operationAction: machine.runningOperation?.primaryKey ? OperationActionSignOff.PAUSE : undefined,
        wasteCount: undefined,
        goodCount: undefined,
        comment: undefined,
        newCaptain: undefined,
        isAssistant: false,
    } as SignOffMachine;
}

export function updateDialogProcessConfig(signOffMachine: SignOffMachine[], user: User): SignOffDialogConfig {
    const hasTeam = hasAtLeastOneTeamWithinSignOffMachinesList(signOffMachine, user.userId);
    const hasOperationRunning = hasAtLeastOneOperationWithinSignOffMachinesList(signOffMachine, user.userId);
    const multipleMachines = hasMoreThanOneMachine(user.userMachines);

    return {
        workstations: multipleMachines,
        employees: hasTeam,
        operations: hasOperationRunning,
        summary: multipleMachines || hasTeam || hasOperationRunning,
    };
}

function hasAtLeastOneTeamWithinSignOffMachinesList(signOffMachine: SignOffMachine[], userId: string): boolean {
    return !!signOffMachine.find((obj) => hasTeamMembers(obj.machine) && isCurrentUserAnOperator(obj.machine, userId));
}

function hasAtLeastOneOperationWithinSignOffMachinesList(signOffMachine: SignOffMachine[], userId: string): boolean {
    return !!signOffMachine.find(
        (obj) =>
            isOperationActivityRunning(obj.machine) &&
            shouldHandleOperationBecauseAssistantsWillBeLoggedOff(obj) &&
            isCurrentUserAnOperator(obj.machine, userId)
    );
}
