import { Entity } from '@heidelberg/control-center-frontend-integration/cc';
import { Material } from './material.interface';

export enum ConsumptionType {
    NORMAL = 'ID_NORMAL',
    CUSTOMER = 'ID_CUSTOMER',
    INTERNAL = 'ID_INTERNAL',
}

export interface Consumption {
    materialId: string;
    batchId?: string;
    consumption: number;
    type: ConsumptionType;
}

export interface ConsumptionEntity extends Consumption, Entity {
    deviceId: string;
    operationPrimaryKey: string;
    jobId: string;
    goodAmount: number;
    wasteAmount: number;
}

export interface ReportConsumptionsBody {
    operationPrimaryKey: string;
    deviceId: string;
    consumptions: Consumption[];
}

export interface ReportConsumptionsResponse {
    successes: ConsumptionEntity[];
    errors: ConsumptionEntity[];
}

export type ConsumptionPreview = Consumption & Partial<Pick<Material, 'materialDescription' | 'unitCaption'>>;
