import { Component, Input, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { OperationDetails } from '@data-terminal/shared-models';
import { CommonModule } from '@angular/common';
import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'data-terminal-operation-details-preview',
    templateUrl: './operation-details-preview.component.html',
    styleUrls: ['./operation-details-preview.component.scss'],
})
export class OperationDetailsPreviewComponent {
    @Input() public operationDetails!: OperationDetails;
}

@NgModule({
    declarations: [OperationDetailsPreviewComponent],
    exports: [OperationDetailsPreviewComponent],
    imports: [MatIconModule, CommonModule, HdmuiComponentsModule, MatProgressSpinnerModule],
})
export class OperationDetailsPreviewModule {}
