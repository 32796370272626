import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { OperationService } from '../../services/operation/operation.service';
import { SetCommentParam } from '@data-terminal/shared-models';
import { ActivatedRouteDataService } from '@data-terminal/data-access';

@UntilDestroy()
@Component({
    templateUrl: './add-comment-dialog.component.html',
    styleUrls: ['./add-comment-dialog.component.scss'],
})
export class AddCommentDialogComponent {
    public readonly option = HdmuiBaseDialogComponent.OPTION_CONFIRM_CANCEL;
    public addCommentForm!: UntypedFormGroup;
    private primaryKey = '';

    constructor(
        private readonly dialogRef: MatDialogRef<AddCommentDialogComponent>,
        private readonly operationService: OperationService,
        private readonly activatedRouteDataService: ActivatedRouteDataService
    ) {
        this.createFormGroup();
        this.activatedRouteDataService.activatedRouteData$.subscribe((route) => {
            if (route !== undefined) {
                this.primaryKey = route?.operationDetails?.primaryKey;
            }
        });
    }

    public onCancelDialog(): void {
        this.dialogRef.close(false);
    }

    public onConfirmDialog(): void {
        const commentObj: SetCommentParam = {
            comment: this.addCommentForm.value.comment,
            primaryKey: this.primaryKey,
        };
        this.operationService.setComment(commentObj).subscribe();
        this.dialogRef.close(commentObj);
    }

    private createFormGroup(): void {
        this.addCommentForm = new UntypedFormGroup({
            comment: new UntypedFormControl('', Validators.required),
        });
    }
}

@NgModule({
    declarations: [AddCommentDialogComponent],
    exports: [AddCommentDialogComponent],
    imports: [
        HdmuiComponentsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule,
        ReactiveFormsModule,
        CommonModule,
    ],
})
export class AddCommentDialogModule {}
