import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';

import { Job } from '../../../../../shared-models/src/lib/job';

@Injectable({
    providedIn: 'root',
})
export class JobApiService {
    private readonly JOB_LIST_URL = `${this.backendUrl}${this.orgRequestPrefix}joblist`;

    constructor(
        private readonly http: HttpClient,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    public getJobList(searchQuery: string): Observable<Job[]> {
        return this.http.get<Job[]>(this.JOB_LIST_URL, {
            params: {
                filterJobId: searchQuery,
            },
        });
    }
}
