import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

@Component({
    templateUrl: './delete-time-entry-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [HdmuiComponentsModule, TranslateModule],
})
export class DeleteTimeEntryDialogComponent {
    readonly #dialogRef = inject(MatDialogRef<DeleteTimeEntryDialogComponent>);

    public readonly option = HdmuiBaseDialogComponent.OPTION_CONFIRM_CANCEL;

    public onCancelDialog(): void {
        this.#dialogRef.close(false);
    }

    public onConfirmDialog(): void {
        this.#dialogRef.close(true);
    }
}
