<hdmui-base-dialog
    [dialogOptions]="option"
    (cancelClicked)="closeDialog()"
    (okClicked)="confirmActivity()"
    [contentNoPadding]="true"
    [okText]="'DC.ACTIVITY_CONFIRMATION.DIALOG.OKTEXT' | translate"
    [cancelText]="'DC.ACTIVITY_CONFIRMATION.DIALOG.CANCELTEXT' | translate"
>
    <div class="m-5">
        <div class="ms-3">
            {{
                'DC.ACTIVITY_CONFIRMATION.DIALOG.START_ACTIVITY' | translate: { activityName: dialogData.activityName }
            }}
        </div>
        <mat-checkbox [(ngModel)]="isChecked"
            ><span>{{ 'DC.ACTIVITY_CONFIRMATION.DIALOG.CHECKBOX' | translate }}</span></mat-checkbox
        >
    </div>
</hdmui-base-dialog>
