@if (!isManualMachine) {
    <div class="amount-counter">
        @if (operationData !== null && operationData !== undefined && formData !== null && formData !== undefined) {
            <div class="amount-counter__container">
                <hdmui-key-value
                    class="hdmui-mb-3 hdmui-ms-0 hdmui-py-4"
                    [label]="'DC.REPORT_LAST_AMOUNTS.DIALOG.PLANNED_AMOUNT' | translate"
                    [wrapText]="true"
                >
                    {{ operationData.plannedGood | number }}
                </hdmui-key-value>
                <hdmui-key-value
                    class="hdmui-ms-0"
                    [ngClass]="{
                        exceeded: operationData.currentGood + formData.goodAmount > operationData.plannedGood,
                    }"
                    [class.subceeded]="
                        isFinishOperation && operationData.currentGood + formData.goodAmount < operationData.plannedGood
                    "
                    [label]="'DC.REPORT_LAST_AMOUNTS.DIALOG.ACCUMULATED_GOOD_AMOUNT' | translate"
                    [wrapText]="true"
                >
                    {{ operationData.currentGood + formData.goodAmount | number }}</hdmui-key-value
                >
                <hdmui-key-value
                    class="hdmui-mb-3 hdmui-ms-0 hdmui-py-2"
                    [label]="'DC.REPORT_LAST_AMOUNTS.DIALOG.ACCUMULATED_WASTE_AMOUNT' | translate"
                    [ngClass]="{
                        exceeded: operationData.currentWaste + formData.wasteAmount > operationData.plannedWaste,
                    }"
                    [wrapText]="true"
                >
                    {{ operationData.currentWaste + formData.wasteAmount | number }}
                </hdmui-key-value>
            </div>
        } @else {
            <div class="progress-bar-container">
                <mat-progress-bar mode="query"></mat-progress-bar>
            </div>
        }
    </div>
}
