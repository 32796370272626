<section>
    <p>
        {{ 'DC.SIGN_OFF_DIALOG.WORKSTATIONS.DESCRIPTION' | translate }}
    </p>
    <data-terminal-machine-list
        [machines]="machines"
        [otherActivities]="otherActivities"
        (selectedMachines)="onSelectedMachines($event)"
        [isSelectableMode]="true"
        [showOperationInfo]="true"
    ></data-terminal-machine-list>
</section>
