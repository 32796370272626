import { ChangeDetectorRef, Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRouteDataService, ApiRequestResponseService } from '@data-terminal/data-access';
import { OperationService } from '../../../services/operation/operation.service';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HdmuiComponentsModule, HdmuiEmptyStatesModule } from '@heidelberg/hdmui-angular';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddCommentDialogComponent } from '../../../dialogs/add-comment-dialog/add-comment-dialog.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommentWorkflowFiltersModule } from '../../../components/comment-workflow/comment-workflow-filters/comment-workflow-filters/comment-workflow-filters.component';
import { CommentsFilter, ROUTE_PARAMS } from '@data-terminal/shared-models';
import { filterComments } from '../../../components/comment-workflow/comment-workflow-filters/comment-workflow-filters/comment-workflow.filter';
import { MatBadgeModule } from '@angular/material/badge';
import { SearchInputModule } from '@data-terminal/ui-presentational';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiRequestType, GetCommentsBody, GetCommentsResponse } from '@data-terminal/utils';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LegalLineComponent } from '../../../../../../../src/app/components/legal-line/legal-line.component';

interface CommentCard {
    date: number;
    author: string;
    opState: string | undefined;
    text: string;
    type: string;
}

@UntilDestroy()
@Component({
    selector: 'data-terminal-comment-workflow-page',
    templateUrl: './comment-workflow-page.component.html',
    styleUrls: ['./comment-workflow-page.component.scss'],
})
export class CommentWorkflowPageComponent implements OnInit {
    public commentList: GetCommentsResponse[] = [];
    public displayCommentList: CommentCard[] | undefined = undefined;
    public filter?: CommentsFilter;
    public searchInput = '';
    private readonly workstep = '';
    private dialogRef: MatDialogRef<AddCommentDialogComponent> | undefined;
    public showFilterBadge = false;

    private readonly machineId: string;
    private jobIdList: string[] = [];
    private jobId = '';

    constructor(
        private readonly activatedRouteDataService: ActivatedRouteDataService,
        private readonly operationService: OperationService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly dialog: MatDialog,
        private readonly apiRequestResponseService: ApiRequestResponseService,
        private readonly activatedRoute: ActivatedRoute
    ) {
        this.machineId = this.activatedRoute.snapshot.params[ROUTE_PARAMS.machineId];
    }

    ngOnInit(): void {
        // this.operationService
        //     .getOperationRequestMetadata(this.primaryKey)
        //     .pipe(filter((metadata) => !metadata.isLoading))
        //     .subscribe((metadata) => {
        //         if (metadata.data !== null && metadata.data !== undefined) {
        //             this.jobId = metadata.data?.jobNumber;
        //             this.getComments()
        //         }

        //     });
        this.activatedRouteDataService.activatedRouteData$.pipe(untilDestroyed(this)).subscribe((route) => {
            if (route !== undefined) {
                this.jobId = route?.operationDetails?.jobNumber;
                this.operationService.operationlistByJobId(this.jobId).subscribe((data) => {
                    this.jobIdList = Array.from(new Set(data.map((d) => d.opName)).values());
                    this.getComments();
                });
            }
        });
    }

    getComments(): void {
        if (this.workstep !== undefined && this.machineId !== undefined) {
            const body: GetCommentsBody = {
                machineId: this.machineId,
                jobId: this.jobId,
            };

            this.apiRequestResponseService
                .sendApiRequest<GetCommentsBody, GetCommentsResponse[]>(ApiRequestType.GETCOMMENTS, body, false)
                .subscribe((response) => {
                    if (response.data !== null && response.data !== undefined) {
                        this.commentList = response.data;
                        this.displayCommentList = response.data.map((data) => this.transformComment(data));
                    }
                });
        }
    }

    onFilterChange(filter: CommentsFilter): void {
        console.log(filter);
        //     this.filter = filter;
        //     this.displayCommentList = filterComments(filter, this.searchInput, this.commentList);
        //     this.showFilterBadge = hasAnyFilterApplied(filter);
        //     this.cdRef.detectChanges();
    }

    onSearchInputChange(searchInput: string): void {
        this.searchInput = searchInput;
        this.displayCommentList = filterComments(this.filter, searchInput, this.commentList).map((data) =>
            this.transformComment(data)
        );
        this.cdRef.detectChanges();
    }

    onCommentAdd(): void {
        this.dialogRef = this.dialog.open(AddCommentDialogComponent, {
            disableClose: true,
            // height: '460px',
            width: '320px',
        });
        this.dialogRef.afterClosed().subscribe((data) => {
            if (data !== false) {
                this.getComments();
            }
        });
    }

    transformComment(comment: GetCommentsResponse): CommentCard {
        return {
            date: comment.date,
            author: this.getAuthorName(comment.author),
            opState: this.getOPState(comment.text),
            text: this.getOPState(comment.text)
                ? comment.text.replace(this.getOPState(comment.text) + ':', '')
                : comment.text,
            type: comment.type,
        };
    }

    getAuthorName(author: string): string {
        return author.includes('@') ? author.split('@')[0] + ' - ' + author.split('@')[1] : author;
    }

    getOPState(text: string): string | undefined {
        return this.jobIdList.find((jobId) => text.startsWith(jobId));
    }
}

@NgModule({
    declarations: [CommentWorkflowPageComponent],
    exports: [CommentWorkflowPageComponent],
    imports: [
        MatCardModule,
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        HdmuiComponentsModule,
        MatDividerModule,
        MatSidenavModule,
        CommentWorkflowFiltersModule,
        MatBadgeModule,
        SearchInputModule,
        MatProgressSpinnerModule,
        HdmuiEmptyStatesModule,
        TranslateModule,
        LegalLineComponent,
    ],
})
export class JoinOrTakeOverDialogModule {}
