import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'machineSignInTimestampToHoursRange',
})
export class MachineSignInTimestampToHoursRangePipe implements PipeTransform {
    // the "_" argument is just for refreshing the displayed data
    // a value from interval(1000) observable with filtering (minute === 0 && seconds === 0) should be provided
    transform(machineSignInTimestamp: number, _: number | null): number[] {
        const signInDate = new Date(machineSignInTimestamp);

        const signInHour = signInDate.getHours();
        const nowHour = new Date().getHours();

        const range: number[] = [];
        for (let i = signInHour; i <= nowHour; i++) {
            range.push(i);
        }

        return range;
    }
}
