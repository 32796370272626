import { Injectable } from '@angular/core';
import { UserLoginTimestamps } from 'projects/shared-models/src/lib/employee';
import { Observable, tap } from 'rxjs';
import { EmployeeApiService } from '../employee-api/employee-api.service';

@Injectable({
    providedIn: 'root',
})
export class EmployeeService {
    constructor(private readonly employeeApiService: EmployeeApiService) {}
    private userLoginTimestamps: UserLoginTimestamps[] = [];

    public fetchUserMachineLogins(userId: string): Observable<UserLoginTimestamps[]> {
        return this.employeeApiService.getUserMachineLogins(userId).pipe(
            tap((val) => {
                if (val !== undefined && val !== null) {
                    this.userLoginTimestamps = val;
                }
            })
        );
    }

    public getUserMachineLogins(): UserLoginTimestamps[] {
        return this.userLoginTimestamps;
    }
}
