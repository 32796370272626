import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { filter, map, Observable, of, switchMap, take } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

import {
    Activity,
    ActivityRequired,
    ActivityState,
    GroupedActivities,
    Operation,
    OperationAction,
    OperationActionType,
} from '@data-terminal/shared-models';
import { ActionView, ConfirmationSettingType, SettingsConfirmationService } from '@data-terminal/feature-settings';

import {
    ActivityConfirmationDialogComponent,
    ActivityConfirmationDialogData,
} from '../activity-confirmation-dialog/activity-confirmation-dialog.component';
import { CleanupHintDialogComponent } from '../cleanup-hint-dialog/cleanup-hint-dialog.component';
import {
    ReportLastAmountDialogClose,
    ReportLastAmountDialogData,
    ReportLastAmountsDialogComponent,
} from '../../../operations/operation-toolbar-buttons/report-last-amounts-dialog/report-last-amounts-dialog.component';
import {
    ReportWasteAmountDialogData,
    WasteAmountDialogComponent,
} from '../../waste-amount-dialog/waste-amount-dialog.component';

export interface ActivityButtonClick {
    activity: Activity;
    operationAction?: OperationAction;
}

@Component({
    selector: 'data-terminal-activity-buttons',
    templateUrl: './activity-buttons.component.html',
    styleUrls: ['./activity-buttons.component.scss'],
    standalone: true,
    imports: [NgClass, MatTooltip, MatButton, MatExpansionModule, NgTemplateOutlet],
})
export class ActivityButtonsComponent {
    @Input() public currentState!: string;
    @Input() public currentOpState!: string;
    @Input() public groupedActivities: GroupedActivities[] = [];
    @Input() public captainFlag = false;
    @Input() public operation?: Operation;
    @Input() public isManualMachine = false;
    @Input() actionView: ActionView | undefined = undefined;
    @Output() public activityClick: EventEmitter<ActivityButtonClick> = new EventEmitter<ActivityButtonClick>();

    constructor(
        private readonly dialog: MatDialog,
        private readonly settingsConfirmationService: SettingsConfirmationService,
        private readonly activatedRoute: ActivatedRoute
    ) {}

    public onActivityClick(activity: Activity): void {
        if (this.canOpenCleanupHintDialog(activity)) {
            this.handleActivityClickWithHintDialog(activity);
        } else {
            if (this.currentOpState === ActivityState.SETUP && activity.jobStatus === ActivityState.IN_PROGRESS) {
                this.handleProductionRunButton(activity);
            } else {
                this.handleActivityConfirmation(activity)
                    .pipe(take(1))
                    .subscribe((confirmed) => {
                        if (confirmed) {
                            this.activityClick.emit({ activity });
                        }
                    });
            }
        }
    }

    private handleActivityClickWithHintDialog(activity: Activity): void {
        this.openCleanupHintDialog(activity.actName, activity.jobStatus === 'Cleanup', activity.jobStatus === 'Stopped')
            .pipe(
                take(1),
                switchMap((cleanupResult) => (cleanupResult ? this.handleActivityConfirmation(activity) : of(false)))
            )
            .subscribe((confirmed) => {
                if (confirmed && this.operation !== undefined && this.operation !== null) {
                    const data: ReportLastAmountDialogData = {
                        dialogType: OperationActionType.ADD_REPORT,
                        i18nHeaderKey: 'DC.REPORT_LAST_AMOUNTS.ADD_REPORT_HEADER',
                        isManualMachine: this.isManualMachine,
                        plannedGoodAmount: this.operation.opPlannedGoodCtr,
                        plannedWasteAmount: this.operation.opPlannedWasteCtr,
                        currentGoodAmount: this.operation.goodAmount,
                        currentWasteAmount: this.operation.wasteAmount,
                        counterBoxTimestamp: '',
                    };
                    this.dialog
                        .open<
                            ReportLastAmountsDialogComponent,
                            ReportLastAmountDialogData,
                            ReportLastAmountDialogClose
                        >(
                            ReportLastAmountsDialogComponent,
                            ReportLastAmountsDialogComponent.returnDialogConfigData(data)
                        )
                        .afterClosed()
                        .pipe(filter((d) => !!d))
                        .subscribe((d) => {
                            if (d?.action) {
                                this.activityClick.emit({ activity, operationAction: d.action });
                            }
                        });
                }
            });
    }

    private handleProductionRunButton(activity: Activity): void {
        const shouldShowConfirmationForProductionRun = this.shouldShowConfirmationDialog(activity);

        const shouldShowConfirmationForWasteSheetsAmount =
            this.settingsConfirmationService.shouldShowConfirmationDialog(
                ConfirmationSettingType.WASTE_SHEETS_ON_SWITCH_TO_GOOD_PRODUCTION
            );

        const data: ReportWasteAmountDialogData = {
            dialogType: OperationActionType.ADD_REPORT,
            plannedWasteAmount: this.operation?.opPlannedWasteCtr as number,
            currentWasteAmount: this.operation?.wasteAmount as number,
        };

        if (shouldShowConfirmationForProductionRun) {
            this.handleProductionRunWithConfirmationDialog(activity, shouldShowConfirmationForWasteSheetsAmount, data);
        } else if (shouldShowConfirmationForWasteSheetsAmount) {
            this.handleProductionRunWithWasteSheetsOnly(activity, data);
        } else {
            this.activityClick.emit({ activity });
        }
    }

    private canOpenCleanupHintDialog(activity: Activity): boolean {
        return (
            (activity.jobStatus === 'Cleanup' || activity.jobStatus === 'Stopped') &&
            ActivityRequired.OTHER_ACTIVITY !== this.activatedRoute.snapshot.data.id &&
            this.currentOpState !== 'Stopped' &&
            this.currentOpState !== 'Cleanup'
        );
    }

    private openCleanupHintDialog(activityName: string, isCleanup: boolean, isStopped: boolean): Observable<boolean> {
        const dialogData = {
            width: '320px',
            minHeight: '140px',
            data: { activityName, isCleanup, isStopped },
            disableClose: true,
        };

        return this.dialog
            .open<CleanupHintDialogComponent>(CleanupHintDialogComponent, dialogData)
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    return of(!!result);
                })
            );
    }

    private handleActivityConfirmation(activity: Activity): Observable<boolean> {
        const dialogData = this.setConfirmationDialogData(activity);

        if (this.shouldShowConfirmationDialog(activity)) {
            return this.dialog
                .open<ActivityConfirmationDialogComponent, ActivityConfirmationDialogData>(
                    ActivityConfirmationDialogComponent,
                    dialogData
                )
                .afterClosed()
                .pipe(
                    switchMap((result) => {
                        return of(!!result);
                    })
                );
        } else {
            return of(true);
        }
    }

    private shouldShowConfirmationDialog(activity: Activity): boolean {
        return this.settingsConfirmationService.shouldShowConfirmationDialog(
            activity.actName as ConfirmationSettingType,
            this.actionView,
            activity.jobStatus as ActivityState
        );
    }

    private setConfirmationDialogData(activity: Activity): MatDialogConfig<ActivityConfirmationDialogData> {
        return {
            width: '320px',
            minHeight: '140px',
            data: {
                activityName: activity.actName,
                actionView: this.actionView,
                jobStatus: activity.jobStatus as ActivityState,
                isCleanup: false,
                isStopped: false,
            },
            disableClose: true,
        };
    }

    private handleProductionRunWithConfirmationDialog(
        activity: Activity,
        shouldShowConfirmationForWasteSheetsAmount: boolean,
        data: ReportWasteAmountDialogData
    ): void {
        const dialogData = this.setConfirmationDialogData(activity);

        this.dialog
            .open<ActivityConfirmationDialogComponent, ActivityConfirmationDialogData>(
                ActivityConfirmationDialogComponent,
                dialogData
            )
            .afterClosed()
            .pipe(
                switchMap((confirmed) => {
                    if (confirmed && shouldShowConfirmationForWasteSheetsAmount) {
                        return this.dialog
                            .open<WasteAmountDialogComponent, ReportWasteAmountDialogData>(
                                WasteAmountDialogComponent,
                                WasteAmountDialogComponent.returnDialogConfigData(data)
                            )
                            .afterClosed()
                            .pipe(
                                take(1),
                                map((secondDialogData) => {
                                    return {
                                        firstDialogData: true,
                                        secondDialogData,
                                    };
                                })
                            );
                    } else if (confirmed && !shouldShowConfirmationForWasteSheetsAmount) {
                        return of({ firstDialogData: true, secondDialogData: false });
                    }
                    return of({ firstDialogData: true, secondDialogData: undefined });
                })
            )
            .subscribe((confirmed) => {
                if (confirmed.firstDialogData && confirmed.secondDialogData) {
                    this.activityClick.emit({
                        activity,
                        operationAction: confirmed.secondDialogData,
                    });
                } else if (confirmed.firstDialogData && !shouldShowConfirmationForWasteSheetsAmount) {
                    this.activityClick.emit({ activity });
                }
            });
    }

    handleProductionRunWithWasteSheetsOnly(activity: Activity, data: ReportWasteAmountDialogData): void {
        this.dialog
            .open<WasteAmountDialogComponent, ReportWasteAmountDialogData>(
                WasteAmountDialogComponent,
                WasteAmountDialogComponent.returnDialogConfigData(data)
            )
            .afterClosed()
            .pipe(take(1))
            .subscribe((operationAction) => {
                if (operationAction) {
                    this.activityClick.emit({ activity, operationAction });
                }
            });
    }
}
