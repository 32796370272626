<div class="waste-good-info">
    <div class="waste-good-info__left" [ngClass]="{ exceeded: isGoodExceeded() }">
        <hdmui-key-value-vertical class="hdmui-my-0 hdmui-py-0" [label]="'DC.OPERATION_AMOUNT_INFO.GOOD' | translate">
            {{ operation.opCtrGood || 0 | number }} / {{ operation.opPlannedGoodCtr || 0 | number }}
        </hdmui-key-value-vertical>
    </div>
    <div class="waste-good-info__right" [ngClass]="{ exceeded: isWasteExceeded() }">
        <hdmui-key-value-vertical class="hdmui-my-0 hdmui-py-0" [label]="'DC.OPERATION_AMOUNT_INFO.WASTE' | translate">
            {{ operation.opCtrWaste || 0 | number }} /
            {{ operation.opPlannedWasteCtr || 0 | number }}
        </hdmui-key-value-vertical>
    </div>
</div>
