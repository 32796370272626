import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    interval,
    map,
    Observable,
    of,
    switchMap,
    take,
} from 'rxjs';
import { DateTime } from 'luxon';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';

import { Activity, MachineClass, TimeModeEntry } from '@data-terminal/shared-models';
import { MS_IN_SECOND, SECONDS_IN_MIN, TimeFormatterPipeModule } from '@data-terminal/utils';

import { JobService } from '../../services/job/job.service';
import { DurationFormattingService } from '../../services/duration-formatting/duration-formatting.service';
import { DURATION_STR_PATTERN } from '../../functions/duration-str-to-ms.function';
import { NgxMaskDirective } from 'ngx-mask';

function maxDurationValidator(differenceMinutes: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const valueMatch = control.value.match(DURATION_STR_PATTERN);
        if (valueMatch) {
            const hours = valueMatch[1] ? parseInt(valueMatch[1], 10) : 0;
            const minutes = valueMatch[2] ? parseInt(valueMatch[2], 10) : 0;

            const calculatedCurrentMinutes = 60 * hours + minutes;

            if (calculatedCurrentMinutes > differenceMinutes) {
                return { maxDurationSeconds: { value: differenceMinutes * 60 } };
            }
        }

        return null;
    };
}

function minDurationValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const valueMatch = control.value.match(DURATION_STR_PATTERN);
        if (valueMatch) {
            const hours = valueMatch[1] ? parseInt(valueMatch[1], 10) : 0;
            const minutes = valueMatch[2] ? parseInt(valueMatch[2], 10) : 0;

            if (hours === 0 && !minutes) {
                return { minDurationSeconds: { value: 0 } };
            }
        }

        return null;
    };
}

@UntilDestroy()
@Component({
    selector: 'data-terminal-log-time-form',
    templateUrl: './log-time-form.component.html',
    styleUrls: ['./log-time-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TranslateModule,
        MatInputModule,
        MatSelectModule,
        TimeFormatterPipeModule,
        MatAutocompleteModule,
        CommonModule,
        ReactiveFormsModule,
        NgxMaskDirective,
    ],
})
export class LogTimeFormComponent implements OnInit {
    readonly #fb = inject(FormBuilder);
    readonly #jobService = inject(JobService);
    readonly #durationFormattingService = inject(DurationFormattingService);

    @Input()
    lastLoggedActivityTimestamp!: number;

    @Input()
    activities: Activity[] = [];

    @Input()
    machineClass!: MachineClass;

    @Input()
    isSubmitting!: boolean;

    @Input()
    editEntry?: TimeModeEntry;

    public readonly MachineClass = MachineClass;

    public jobIdSuggestions$!: Observable<string[]>;
    public activitiesMap = new Map<Activity['groupName'], Activity[]>();

    public form!: FormGroup<{
        activity: FormControl<Activity>;
        duration: FormControl<string>;
        jobId: FormControl<string | undefined>;
        goodAmount: FormControl<number | undefined>;
        wasteAmount: FormControl<number | undefined>;
        comment: FormControl<string | undefined>;
    }>;

    public lastLoggedActivityAgoMinutes$ = new BehaviorSubject<number>(0);

    ngOnInit(): void {
        this.prepareActivitiesMap();
        this.initFG();
        this.listenForJobIdSuggestions();
        this.listenForLastLoggedActivityAgoMinutesChanges();
    }

    public formatDurationString(): void {
        const formattedValue = this.#durationFormattingService.formatDurationString(this.form.get('duration')?.value);

        if (formattedValue) {
            this.form.get('duration')?.setValue(formattedValue);
        }
    }

    private prepareActivitiesMap(): void {
        (this.activities || []).forEach((activity) => {
            const existingActivities = this.activitiesMap.get(activity.groupName);

            if (existingActivities) {
                this.activitiesMap.set(activity.groupName, [...existingActivities, activity]);
            } else {
                this.activitiesMap.set(activity.groupName, [activity]);
            }
        });
    }

    private listenForLastLoggedActivityAgoMinutesChanges(): void {
        this.lastLoggedActivityAgoMinutes$.next(this.calcLastLoggedActivityAgoMinutes());

        interval(1000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.lastLoggedActivityAgoMinutes$.next(this.calcLastLoggedActivityAgoMinutes());
            });

        this.lastLoggedActivityAgoMinutes$
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe((lastLoggedActivityAgoMinutes) => {
                const durationFC = this.form.get('duration');

                if (durationFC) {
                    durationFC.clearValidators();
                    durationFC.setValidators(
                        this.getDurationValidators(
                            this.editEntry
                                ? lastLoggedActivityAgoMinutes + this.editEntry.duration / MS_IN_SECOND / SECONDS_IN_MIN
                                : lastLoggedActivityAgoMinutes
                        )
                    );

                    if (!this.editEntry && !durationFC.touched) {
                        durationFC.setValue(`${lastLoggedActivityAgoMinutes}m`);
                        this.formatDurationString();
                    }

                    durationFC.updateValueAndValidity();
                }
            });
    }

    private listenForJobIdSuggestions(): void {
        const JOB_SUGGESTION_MIN_LENGTH = 3;
        const jobIdFC: FormControl = this.form.get('jobId') as FormControl;

        if (jobIdFC) {
            this.jobIdSuggestions$ = jobIdFC.valueChanges.pipe(
                untilDestroyed(this),
                debounceTime(1000),
                distinctUntilChanged(),
                switchMap((jobId: string) =>
                    jobId.length >= JOB_SUGGESTION_MIN_LENGTH
                        ? this.#jobService.getJobList(jobId).pipe(take(1))
                        : of([])
                ),
                map((jobList) => jobList.map((job) => job.jobId))
            );
        }
    }

    private initFG(): void {
        const selectedEntryActivity = this.activities.find((activity) => this.editEntry?.activity === activity.actId);

        this.form = this.#fb.group({
            activity: this.#fb.nonNullable.control<Activity>(
                {
                    value: selectedEntryActivity ? selectedEntryActivity : this.activities[0],
                    disabled: this.isSubmitting,
                },
                {
                    validators: [Validators.required],
                }
            ),
            duration: this.#fb.nonNullable.control<string>(
                {
                    value: this.editEntry?.duration
                        ? `${this.editEntry.duration / MS_IN_SECOND / SECONDS_IN_MIN}m`
                        : '0m',
                    disabled: this.isSubmitting,
                },
                this.getDurationValidators(0)
            ),
            jobId: this.#fb.nonNullable.control<string | undefined>({
                value: this.editEntry?.jobId,
                disabled: this.isSubmitting,
            }),
            goodAmount: this.#fb.nonNullable.control<number | undefined>(
                { value: this.editEntry?.goodAmount, disabled: this.isSubmitting },
                [Validators.min(0)]
            ),
            wasteAmount: this.#fb.nonNullable.control<number | undefined>(
                {
                    value: this.editEntry?.wasteAmount,
                    disabled: this.isSubmitting,
                },
                [Validators.min(0)]
            ),
            comment: this.#fb.nonNullable.control<string | undefined>({
                value: this.editEntry?.comment,
                disabled: this.isSubmitting,
            }),
        });

        this.formatDurationString();
    }

    private getCalculatedMinutesFromDate(date: DateTime): number {
        return 60 * date.hour + date.minute + date.second / 60;
    }

    private getDurationValidators(maxDuration: number): ValidatorFn[] {
        return [
            Validators.required,
            Validators.pattern(DURATION_STR_PATTERN),
            maxDurationValidator(maxDuration),
            minDurationValidator(),
        ];
    }

    private calcLastLoggedActivityAgoMinutes(): number {
        return Math.floor(
            this.getCalculatedMinutesFromDate(DateTime.now()) -
                this.getCalculatedMinutesFromDate(DateTime.fromMillis(this.lastLoggedActivityTimestamp))
        );
    }
}
