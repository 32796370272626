import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { AssistantsSignOff, SignOffMachine } from '@data-terminal/shared-models';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'data-terminal-sign-off-summary-card',
    templateUrl: './sign-off-summary-card.component.html',
    styleUrls: ['./sign-off-summary-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignOffSummaryCardComponent {
    @Input() public signOffMachine!: SignOffMachine;
    public STAY = AssistantsSignOff.STAY;
    public LEAVE = AssistantsSignOff.LEAVE;

    public getNewCaptainName(newCaptain: string): string {
        const user = this.signOffMachine.machine.signedOnUsers.find((el) => el.userId === newCaptain);
        return user?.userId || '';
    }
}
@NgModule({
    declarations: [SignOffSummaryCardComponent],
    exports: [SignOffSummaryCardComponent],
    imports: [MatCardModule, CommonModule, TranslateModule],
})
export class SignOffSummaryCardModule {}
