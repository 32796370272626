<form [formGroup]="addCommentForm" class="report">
    <hdmui-base-dialog
        [okEnabled]="addCommentForm.valid"
        [dialogOptions]="option"
        (cancelClicked)="onCancelDialog()"
        (okClicked)="onConfirmDialog()"
        [okText]="'DC.ADD_COMMENT_DIALOG.OK_TEXT' | translate"
        [cancelText]="'DC.ADD_COMMENT_DIALOG.CANCEL_TEXT' | translate"
    >
        <div class="report-form">
            <h2 class="hdmui-m-3">{{ 'DC.ADD_COMMENT_DIALOG.HEADER' | translate }}</h2>
            <div class="hdmui-m-3 report-form__container">
                <mat-form-field class="hdmui-mb-4">
                    <mat-label> {{ 'DC.ADD_COMMENT_DIALOG.COMMENT' | translate }}</mat-label>
                    <textarea matInput rows="3" formControlName="comment"></textarea>
                </mat-form-field>
            </div>
        </div>
    </hdmui-base-dialog>
</form>
