<div class="hdmui hdmui-m-0 wrapper hdmui-container">
    <header>
        <mat-toolbar class="dialog-toolbar">
            <h2>{{ 'DC.REPORT_CONSUMPTION_DIALOG.TITLE' | translate }}</h2>
            <button mat-icon-button (click)="cancel()">
                <mat-icon svgIcon="hdmui:x"></mat-icon>
            </button>
        </mat-toolbar>

        <data-terminal-consumption-add-form
            [allMaterials]="data.allMaterials"
            [batchAllowed]="data.batchAllowed"
            [consumptions]="consumptions()"
            (consumptionAdd)="addConsumption($event)"
        ></data-terminal-consumption-add-form>

        <mat-divider class="hdmui-my-4"></mat-divider>
    </header>

    <section class="hdmui-content">
        <data-terminal-consumptions-preview
            [consumptions]="consumptions()"
            [batchAllowed]="data.batchAllowed"
            (consumptionRemove)="removeConsumption($event)"
        ></data-terminal-consumptions-preview>
    </section>

    <mat-divider></mat-divider>

    <footer>
        <button mat-stroked-button (click)="cancel()">
            {{ '*.action.cancel' | translate }}
        </button>
        <button mat-flat-button (click)="report()" [disabled]="!consumptions().length">
            {{ '*.action.report' | translate }}
        </button>
    </footer>
</div>
