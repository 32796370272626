import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SignOffBaseStepObject, SignOffMachine } from '@data-terminal/shared-models';

@Component({
    template: '',
})
export abstract class SignOffStepComponent<T extends SignOffBaseStepObject> implements OnChanges {
    @Output() dataChange: EventEmitter<SignOffMachine[]> = new EventEmitter<SignOffMachine[]>();
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() signOffMachines: SignOffMachine[] = [];

    protected validityMap = new Map<string, T>();

    public abstract shouldDisplayCard(signOffMachines: SignOffMachine): boolean;

    public ngOnChanges(changes: SimpleChanges): void {
        this.updateValidity(changes?.signOffMachines?.currentValue);
        this.checkValidity();
    }

    public onDataChange(data: T): void {
        const list = this.signOffMachines.map((el) =>
            el.machineId === data.machineId ? { ...el, ...data } : { ...el }
        );
        this.validityMap.set(data.machineId, data);
        this.checkValidity();
        this.emitValues(list);
    }

    public identify(index: number, item: SignOffMachine): string {
        return item.machineId;
    }

    public checkValidity(): void {
        this.isValid.emit(
            ![...this.validityMap].map(([, value]) => value).find((el: { isValid: boolean }) => !el.isValid)
        );
    }

    private emitValues(values: SignOffMachine[]): void {
        this.dataChange.emit([...values]);
    }

    private updateValidity(signOffMachines: T[] | undefined): void {
        const ids = signOffMachines?.map((el) => el.machineId).filter((el) => el) || [];
        [...this.validityMap].forEach(([key]) => !ids.includes(key) && this.validityMap.delete(key));
    }
}
