import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveData } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { BreadcrumbsService } from '@data-terminal/utils';
import { ROUTE_PARAMS } from '@data-terminal/shared-models';
import { OperationService } from '../../operation/operation.service';
import { getAllParams } from '../get-params-from-path.function';
import { FeatureOperationServiceModule } from '../../../operation-service.module';

@Injectable({
    providedIn: FeatureOperationServiceModule,
})
export class OperationDetailsNameResolver {
    constructor(
        private readonly operationService: OperationService,
        private readonly breadcrumbs: BreadcrumbsService
    ) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<string> {
        const primaryKey = getAllParams(route)[ROUTE_PARAMS.primaryKey];
        return this.operationService.getOperationDetails(primaryKey).pipe(
            tap((data) => this.saveName(data.opName)),
            map((data) => data.opName)
        );
    }

    private saveName(name: string): void {
        this.breadcrumbs.saveParam(ROUTE_PARAMS.primaryKey, name);
    }
}

export function operationDetailsNameResolver(): ResolveData {
    return { title: OperationDetailsNameResolver };
}
