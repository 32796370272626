@if (machines) {
    <div class="hdmui hdmui-m-0 sign-off hdmui-container">
        <header>
            <mat-toolbar class="sign-off__header">
                <h2>{{ 'DC.SIGN_OFF_DIALOG.TITLE' | translate }}</h2>
            </mat-toolbar>
        </header>
        <section class="hdmui-content sign-off__content">
            @if (config$ | async; as config) {
                <mat-stepper [linear]="true" (selectionChange)="actionButtonHandle($event)">
                    <ng-template matStepperIcon="edit">
                        <mat-icon class="sign-off__done_icon" svgIcon="hdmui:hook"></mat-icon>
                    </ng-template>
                    @if (config.workstations) {
                        <mat-step
                            [label]="'DC.SIGN_OFF_DIALOG.WORKSTATIONS.TITLE' | translate"
                            [stepControl]="dialogForm.controls.workstations"
                            [editable]="true"
                            [state]="'workstations'"
                        >
                            <data-terminal-sign-off-workstations
                                [machines]="machines || []"
                                [otherActivities]="(otherActivities$ | async)!.data!"
                                (selectMachines)="onWorkstationStateChange($event)"
                                (isValid)="isStepValid('workstations', $event)"
                            ></data-terminal-sign-off-workstations>
                        </mat-step>
                    }
                    @if (config.employees) {
                        <mat-step
                            [label]="'DC.SIGN_OFF_DIALOG.EMPLOYEES.TITLE' | translate"
                            [stepControl]="dialogForm.controls.employees"
                            [editable]="true"
                            [state]="'employees'"
                        >
                            <data-terminal-sign-off-employees
                                [userId]="dialogData.userId"
                                [signOffMachines]="(machinesToSignOff$ | async) || []"
                                (dataChange)="onEmployeesStateChange($event)"
                                (isValid)="isStepValid('employees', $event)"
                            ></data-terminal-sign-off-employees>
                        </mat-step>
                    }
                    @if (config.operations) {
                        <mat-step
                            [label]="'DC.SIGN_OFF_DIALOG.OPERATIONS.TITLE' | translate"
                            [stepControl]="dialogForm.controls.operations"
                            [editable]="true"
                            [state]="'operations'"
                        >
                            <data-terminal-sign-off-operations
                                [userId]="dialogData.userId"
                                [signOffMachines]="(machinesToSignOff$ | async) || []"
                                (dataChange)="onOperationStateChange($event)"
                                (isValid)="isStepValid('operations', $event)"
                            ></data-terminal-sign-off-operations>
                        </mat-step>
                    }
                    @if (config.summary) {
                        <mat-step
                            [label]="'DC.SIGN_OFF_DIALOG.SUMMARY.TITLE' | translate"
                            [stepControl]="dialogForm.controls.summary"
                            [editable]="true"
                            [state]="'summary'"
                        >
                            <data-terminal-sign-off-summary
                                [unsubmittedTimeEntries]="unsubmittedTimeEntries"
                                [signOffMachines]="(machinesToSignOff$ | async) || []"
                            ></data-terminal-sign-off-summary>
                        </mat-step>
                    }
                </mat-stepper>
            }
        </section>
        <footer class="sign-off__action-buttons">
            <button mat-stroked-button (click)="onCancelClick()">{{ 'DC.SIGN_OFF_DIALOG.CANCEL' | translate }}</button>
            <div>
                <button
                    mat-stroked-button
                    matStepperPrevious
                    (click)="onPreviousClick()"
                    [disabled]="isPreviousButtonDisable"
                >
                    {{ 'DC.SIGN_OFF_DIALOG.PREVIOUS' | translate }}
                </button>
                <button mat-flat-button (click)="onNextClick()" [disabled]="isActionButtonDisable">
                    {{ 'DC.SIGN_OFF_DIALOG.' + actionButtonText | translate }}
                </button>
            </div>
        </footer>
    </div>
}
