import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

import { ApiRequestResponseService, BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import {
    ApiRequestType,
    mapToRequestMetadataWithRetry,
    RequestMetadata,
    RETRY_TIME_DELAY_MS,
} from '@data-terminal/utils';
import {
    LoginTypeAndMachineId,
    RequestType,
    SetAssistantsBody,
    SetAssistantsEntry,
    User,
    UserMachines,
} from '@data-terminal/shared-models';

import { ErrorDialogComponent } from '../../../../../src/app/components/error-dialog/error-dialog/error-dialog.component';

export interface SignInBody {
    wishlist: LoginTypeAndMachineId[];
    userId: string;
    locale: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserMachinesApiService {
    private readonly USER_MACHINES_URL: (userId: string) => string = (userId) =>
        `${this.backendUrl}${this.orgRequestPrefix}usermachines/${userId}`;

    constructor(
        private readonly http: HttpClient,
        private readonly translateService: TranslateService,
        private readonly snackBar: MatSnackBar,
        private readonly apiRequestResponseService: ApiRequestResponseService,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string,
        private readonly dialog: MatDialog
    ) {}

    // @Cacheable({ maxAge: 1000 })
    public getUserMachines(userId: string): Observable<RequestMetadata<UserMachines>> {
        return this.http.get<UserMachines>(this.USER_MACHINES_URL(userId)).pipe(
            mapToRequestMetadataWithRetry({
                baseDelayMs: RETRY_TIME_DELAY_MS,
            })
        );
    }

    public setAssistants(userId: string, entries: SetAssistantsEntry[]): Observable<RequestMetadata<UserMachines>> {
        let timeout = true;

        const body: SetAssistantsBody = {
            userId,
            entries,
            locale: this.translateService.currentLang,
        };

        return this.apiRequestResponseService
            .sendApiRequest<SetAssistantsBody, UserMachines>(ApiRequestType.SET_ASSISTANTS, body)
            .pipe(
                tap({
                    // Error Handling
                    next: (response) => {
                        if (response.data !== undefined && response.data !== null) {
                            timeout = false;
                            const errorData = response.data.serviceError;
                            if (errorData.errorMsg !== undefined && errorData.errorMsg.length > 0) {
                                this.dialog.open(ErrorDialogComponent, {
                                    disableClose: true,
                                    data: {
                                        errorCode: 'ERR_SETASSISTANTS',
                                        errorMessage: JSON.stringify({
                                            payload: JSON.parse(errorData.errorMsg),
                                            request: RequestType.SETASSISTANTS,
                                            deviceId: '',
                                        }),
                                    },
                                });
                            }
                        }
                    },
                    complete: () => {
                        if (timeout) {
                            this.dialog.open(ErrorDialogComponent, {
                                disableClose: true,
                                data: {
                                    errorCode: 'ERR_SETASSISTANTS',
                                    errorMessage: JSON.stringify({
                                        payload: { payload: [] },
                                        request: RequestType.SETASSISTANTS,
                                        deviceId: '',
                                    }),
                                },
                            });
                        }
                    },
                })
            );
    }

    public assignMachines(
        user: User,
        machinesIdWithRole: LoginTypeAndMachineId[]
    ): Observable<RequestMetadata<UserMachines>> {
        const body: SignInBody = {
            userId: user.userId,
            wishlist: machinesIdWithRole,
            locale: this.translateService.currentLang,
        };
        let timeout = true;
        return this.apiRequestResponseService
            .sendApiRequest<SignInBody, UserMachines>(ApiRequestType.SIGN_IN, body)
            .pipe(
                tap((response) => {
                    // User Special Permissions
                    if (response.data !== undefined && response.data !== null) {
                        const machineId = machinesIdWithRole.map((item) => item.machineId);
                        const responseData = response.data;
                        const matchedMachine = responseData.assignedMachines.find(
                            (machine) => machineId && machineId.includes(machine.machineId)
                        );

                        if (matchedMachine) {
                            const signedOnUsers = matchedMachine.signedOnUsers;
                            const loggedInUser = user.userId;
                            const userMatch = signedOnUsers.find(
                                (signedOnUser) =>
                                    signedOnUser.userId === loggedInUser &&
                                    signedOnUser.role === 'Assistant' &&
                                    signedOnUser.captain &&
                                    machineId &&
                                    machineId.includes(matchedMachine.machineId)
                            );
                            if (userMatch) {
                                this.snackBar.open(
                                    `No operator is signed in at machine ${matchedMachine.machineId}, you have temp. access to all actions.` +
                                        `
                                    Note, if an operator signs in your permissions will be restricted again`,
                                    'Close',
                                    {
                                        duration: 8000,
                                    }
                                );
                            }
                        }
                    }
                }),
                tap({
                    // Error Handling
                    next: (response) => {
                        if (response.data !== undefined && response.data !== null) {
                            timeout = false;
                            const errorData = response.data.serviceError;
                            if (errorData.errorMsg !== undefined && errorData.errorMsg.length > 0) {
                                this.dialog.open(ErrorDialogComponent, {
                                    disableClose: true,
                                    data: {
                                        errorCode: 'ERR_SIGNIN',
                                        errorMessage: JSON.stringify({
                                            payload: JSON.parse(errorData.errorMsg),
                                            request: RequestType.SIGNIN,
                                            deviceId: '',
                                        }),
                                        reqeustType: RequestType.SIGNIN,
                                    },
                                });
                            }
                        }
                    },
                    complete: () => {
                        if (timeout) {
                            this.dialog.open(ErrorDialogComponent, {
                                disableClose: true,
                                data: {
                                    errorCode: 'ERR_SIGNIN',
                                    errorMessage: JSON.stringify({
                                        payload: { payload: [] },
                                        request: RequestType.SIGNIN,
                                        deviceId: '',
                                    }),
                                    requestType: RequestType.SIGNIN,
                                },
                            });
                        }
                    },
                })
            );
    }

    // public assignMachines(
    //     user: User,
    //     machinesIdWithRole: LoginTypeAndMachineId[],
    //     pin?: string
    // ): Observable<RequestMetadata<UserMachines>> {
    //     const body: SignInBody = {
    //         userId: user.userId,
    //         wishlist: machinesIdWithRole,
    //         locale: this.translateService.currentLang,
    //         pwd: pin,
    //     };
    //     let timeout = true;
    //     return this.apiRequestResponseService
    //         .sendApiRequest<SignInBody, UserMachines>(ApiRequestType.SIGN_IN, body)
    //         .pipe(
    //             tap((response) => {
    //                 // User Special Permissions
    //                 if (response.data !== undefined && response.data !== null) {
    //                     const machineId = machinesIdWithRole.map((item) => item.machineId);
    //                     const responseData = response.data as UserMachines;
    //                     const matchedMachine = responseData.assignedMachines.find(
    //                         (machine) => machineId && machineId.includes(machine.machineId)
    //                     );

    //                     if (matchedMachine) {
    //                         const signedOnUsers = matchedMachine.signedOnUsers;
    //                         const loggedInUser = user.userId;
    //                         const userMatch = signedOnUsers.find(
    //                             (user) =>
    //                                 user.userId === loggedInUser &&
    //                                 user.role === 'Assistant' &&
    //                                 user.captain &&
    //                                 machineId &&
    //                                 machineId.includes(matchedMachine.machineId)
    //                         );
    //                         if (userMatch) {
    //                             this.snackBar.open(
    //                                 `No operator is signed in at machine ${matchedMachine.machineId}, you have temp. access to all actions.` +
    //                                      ` Note, if an operator signs in your permissions will be restricted again`,
    //                                 'Close',
    //                                 {
    //                                     duration: 8000,
    //                                 }
    //                             );
    //                         }
    //                     }
    //                 }
    //             }),
    //             tap({
    //                 // Error Handling
    //                 next: (response) => {
    //                     if (response.data !== undefined && response.data !== null) {
    //                         timeout = false;
    //                         const errorData = response.data.serviceError;
    //                         if (errorData.errorMsg !== undefined && errorData.errorMsg.length > 0) {
    //                             this.dialog.open(ErrorDialogComponent, {
    //                                 disableClose: true,
    //                                 data: {
    //                                     errorCode: 'ERR_SIGNIN',
    //                                     errorMessage: JSON.stringify({
    //                                         payload: JSON.parse(errorData.errorMsg),
    //                                         request: RequestType.SIGNIN,
    //                                         deviceId: '',
    //                                     }),
    //                                 },
    //                             });
    //                         }
    //                     }
    //                 },
    //                 complete: () => {
    //                     if (timeout) {
    //                         this.dialog.open(ErrorDialogComponent, {
    //                             disableClose: true,
    //                             data: {
    //                                 errorCode: 'ERR_SIGNIN',
    //                                 errorMessage: JSON.stringify({
    //                                     payload: { payload: [] },
    //                                     request: RequestType.SIGNIN,
    //                                     deviceId: '',
    //                                 }),
    //                             },
    //                         });
    //                     }
    //                 },
    //             })
    //         );
    // }
}
