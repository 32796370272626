import { Pipe, PipeTransform } from '@angular/core';

import { TimeModeEntry } from '@data-terminal/shared-models';

@Pipe({
    standalone: true,
    name: 'totalEntriesDurationMs',
})
export class TotalEntriesDurationMsPipe implements PipeTransform {
    transform(entries: TimeModeEntry[]): number {
        return entries.reduce((acc, entry) => acc + entry.duration, 0);
    }
}
