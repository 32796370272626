@for (group of groupedActivities; track group) {
    <ng-template #activityButtons>
        <div class="activity-buttons">
            @for (activity of group.activities; track activity) {
                <button
                    [ngClass]="{ 'activity-buttons__selected': currentState === activity.actId }"
                    [disabled]="!captainFlag"
                    (click)="onActivityClick(activity)"
                    class="activity-buttons__button"
                    mat-stroked-button
                    [matTooltip]="activity.actName"
                >
                    {{ activity.actName }}
                </button>
            }
        </div>
    </ng-template>
    @if (group.showExpandedPanel) {
        <div class="hdmui hdmui-m-0 hdmui-p-0 hdmui-my-4 hdmui-background-2">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header matRipple>
                    <mat-panel-title>
                        <h2>{{ group.activities[0].groupName }}</h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngTemplateOutlet="activityButtons"></ng-container>
            </mat-expansion-panel>
        </div>
    } @else {
        <ng-container *ngTemplateOutlet="activityButtons"></ng-container>
    }
}
