<mat-card appearance="outlined" class="sign-off-summary-card hdmui-p-4">
    <div>
        <h1 class="hdmui-font-serif hdmui-m-0 sign-off-summary-card__info-text">
            {{ signOffMachine.machine.machineName }}
        </h1>
        <h1 class="hdmui-font-bold hdmui-m-0">
            {{ signOffMachine.machine.machineId }}
        </h1>
    </div>
    <div class="hdmui-my-4">
        <ul class="sign-off-summary-card__content">
            <li>
                {{
                    (signOffMachine.assistantsSignOff === LEAVE
                        ? 'DC.SIGN_OFF_DIALOG.SUMMARY.CARD.SIGN_OFF_WITH_ASSISTANCE'
                        : 'DC.SIGN_OFF_DIALOG.SUMMARY.CARD.SIGN_OFF'
                    ) | translate
                }}
            </li>
            @if (signOffMachine.assistantsSignOff === STAY) {
                <li
                    [innerHTML]="
                        'DC.SIGN_OFF_DIALOG.SUMMARY.CARD.SIGN_OFF_DEPUTY_ASSISTANCE'
                            | translate: { newCaptain: getNewCaptainName(signOffMachine.newCaptain!) }
                    "
                ></li>
            }
            @if (
                signOffMachine.runningOperation &&
                signOffMachine.operationAction &&
                (signOffMachine.assistantsSignOff === LEAVE || !signOffMachine.assistantsSignOff)
            ) {
                <li
                    [innerHTML]="
                        'DC.SIGN_OFF_DIALOG.SUMMARY.CARD.OPERATION_ACTION'
                            | translate
                                : {
                                      opName: signOffMachine.machine.runningOperation?.opName,
                                      action:
                                          'DC.SIGN_OFF_DIALOG.SUMMARY.CARD.' + signOffMachine.operationAction
                                          | translate,
                                  }
                    "
                ></li>
            }
        </ul>
    </div>
</mat-card>
