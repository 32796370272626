@if (!consumptions().length) {
    <hdmui-empty-states icon="hdmui:exclamationMarkCircled">
        <hdmui-empty-states-title>
            {{ 'DC.REPORT_CONSUMPTION_DIALOG.NO_CONSUMPTIONS_ADDED' | translate }}
        </hdmui-empty-states-title>
        <hdmui-empty-states-description>
            {{
                'DC.REPORT_CONSUMPTION_DIALOG.NO_CONSUMPTIONS_ADDED.DESCRIPTION'
                    | translate: { buttonText: 'DC.REPORT_CONSUMPTION_DIALOG.ADD_CONSUMPTION' | translate }
            }}
        </hdmui-empty-states-description>
    </hdmui-empty-states>
}
@for (consumption of consumptions(); track $index) {
    @if ($index > 0) {
        <mat-divider class="hdmui-my-4"></mat-divider>
    }
    <div class="consumption-preview">
        <div class="consumption-preview__grid">
            <hdmui-key-value-vertical class="hdmui-mt-0 hdmui-mb-1" [label]="'WORKSTEPS.TABLE.MATERIAL' | translate">
                {{ consumption.materialId }}
                @if (consumption.materialDescription) {
                    <span>- {{ consumption.materialDescription }}</span>
                }
            </hdmui-key-value-vertical>
            <hdmui-key-value-vertical class="hdmui-mt-0 hdmui-mb-1" [label]="'AMOUNT' | translate">
                {{ consumption.consumption }}
                @if (consumption.unitCaption) {
                    <span>{{ consumption.unitCaption }}</span>
                }
            </hdmui-key-value-vertical>
            @if (batchAllowed()) {
                <hdmui-key-value-vertical
                    class="hdmui-mt-0 hdmui-mb-1"
                    [label]="'DC.REPORT_CONSUMPTION_DIALOG.BATCH_NUMBER' | translate"
                >
                    {{ consumption.batchId }}
                </hdmui-key-value-vertical>
            }
            <hdmui-key-value-vertical class="hdmui-mt-0 hdmui-mb-1" [label]="'*.type' | translate">
                {{ 'DC.CONSUMPTION.TYPES.' + consumption.type | translate }}
            </hdmui-key-value-vertical>
        </div>

        <button
            mat-icon-button
            class="hdmui-ms-1 consumption-form__remove-button"
            [disabled]="!consumptions().length"
            (click)="consumptionRemove.emit($index)"
        >
            <mat-icon svgIcon="hdmui:xCircled"></mat-icon>
        </button>
    </div>
}
