import { Component, Inject, NgModule, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivityConfirmationDialogComponent } from '../../components/shared-components/activity-buttons/activity-confirmation-dialog/activity-confirmation-dialog.component';
import { ApiRequestType, SnackBarService, getParams } from '@data-terminal/utils';
import { SnackbarClass } from '@data-terminal/shared-models';
import { CommonModule } from '@angular/common';
import { ApiRequestResponseService } from '@data-terminal/data-access';
import { OrderBagBody } from '../../pages/operations-page/operation.presenter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const CANNOT_LOAD_ORDERBAG = 'CANNOT_LOAD_ORDERBAG';
const ORDERBAG_DOWNLOAD_DELAY = 250;

@UntilDestroy()
@Component({
    selector: 'data-terminal-show-orderbag-dialog',
    templateUrl: './show-orderbag-dialog.component.html',
    styleUrls: ['./show-orderbag-dialog.component.scss'],
})
export class ShowOrderbagDialogComponent implements OnDestroy {
    orderBagPages: string[];
    orderBagPageCount = 1;
    machineId: string;
    loadingComplete = false;
    isDestroyed = false;
    constructor(
        private readonly translate: TranslateService,
        private readonly dialogRef: MatDialogRef<ActivityConfirmationDialogComponent>,
        private readonly snackBarService: SnackBarService,
        @Inject(MAT_DIALOG_DATA) public dialogData: { base64String: string; orderbagNumber: string },
        private readonly apiRequestResponseService: ApiRequestResponseService,
        protected readonly activatedRoute: ActivatedRoute
    ) {
        this.orderBagPages = [dialogData.base64String];
        const { machineId } = getParams(this.activatedRoute);
        this.machineId = machineId;
        this.getNextOrderbagPage();
    }
    ngOnDestroy(): void {
        this.isDestroyed = true;
    }

    public closeDialog(): void {
        this.dialogRef.close(false);
    }

    public download(): void {
        this.orderBagPages.forEach((page, idx) => {
            setTimeout(() => {
                const downloadSrc = 'data:application/octet-stream;base64,' + page;
                const downloadLink = document.createElement('a');
                downloadLink.href = downloadSrc;
                downloadLink.download = `${this.translate.instant('DC.ORDERBAG_DIALOG.ORDERBAG')}_${
                    this.dialogData.orderbagNumber
                }_Page${idx + 1}.jpeg`;
                downloadLink.click();
                if (idx === 0) {
                    this.snackBarService.openSnackBar(
                        this.translate.instant('DC.ORDERBAG.SNACKBAR.START_DOWNLOAD'),
                        SnackbarClass.DEFAULT
                    );
                }
                if (idx === this.orderBagPages.length - 1) {
                    this.snackBarService.openSnackBar(
                        this.translate.instant('DC.ORDERBAG.SNACKBAR.FINISHED_DOWNLOAD'),
                        SnackbarClass.SUCCESS
                    );
                }
            }, idx * ORDERBAG_DOWNLOAD_DELAY);
        });
    }

    private getNextOrderbagPage(): void {
        let pageFound = false;
        this.orderBagPageCount += 1;
        const body: OrderBagBody = {
            machineId: this.machineId,
            jobId: this.dialogData.orderbagNumber,
            page: this.orderBagPageCount,
        };
        this.apiRequestResponseService
            .sendApiRequest<OrderBagBody, string>(ApiRequestType.ORDERBAG, body, false)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (response) => {
                    if (response.isLoading === false) {
                        const responseData = response.data;
                        if (
                            responseData !== null &&
                            responseData !== undefined &&
                            responseData !== CANNOT_LOAD_ORDERBAG &&
                            !responseData.startsWith('NullPointerException')
                        ) {
                            this.orderBagPages.push(responseData);
                            pageFound = true;
                        }
                    }
                },
                complete: () => {
                    if (pageFound) {
                        this.getNextOrderbagPage();
                    } else {
                        this.loadingComplete = true;
                    }
                },
            });
    }
}

@NgModule({
    declarations: [ShowOrderbagDialogComponent],
    exports: [ShowOrderbagDialogComponent],
    imports: [HdmuiComponentsModule, TranslateModule, CommonModule, MatProgressSpinnerModule],
})
export class ShowOrderbagDialogModule {}
