import { NgModule } from '@angular/core';
import { OperationsPageModule } from './pages/operations-page/operations-page.component';
import { FeatureOperationServiceModule } from './operation-service.module';
import { OperationDetailsPageModule } from './pages/operation-details-page/operation-details-page.component';
import { ActivitiesPageComponent } from './pages/activities-page/activities-page.component';

@NgModule({
    imports: [
        OperationsPageModule,
        ActivitiesPageComponent,
        OperationsPageModule,
        OperationDetailsPageModule,
        FeatureOperationServiceModule,
    ],
    declarations: [],
})
export class FeatureWorkstationDetailsModule {}
