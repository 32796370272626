import { Activity, ActivityRequired, DefaultActivity, GroupedActivities } from '@data-terminal/shared-models';

export function groupOtherActivities(activites: Activity[]): GroupedActivities[] {
    const groupActivities = groupedActivitiesFor(ActivityRequired.OTHER_ACTIVITY, activites);
    return createGroups(groupActivities);
}

export function groupProductionActivities(activites: Activity[]): GroupedActivities[] {
    const isDefaultButton = (a: Activity): boolean =>
        a.defaultActivity === DefaultActivity.MAKE_READY || a.defaultActivity === DefaultActivity.PRODUCTION;

    const defaultButtons: GroupedActivities = {
        activities: sortDefaultActivities(activites.filter(isDefaultButton)),
        showExpandedPanel: false,
    };

    activites = activites.filter((a) => !isDefaultButton(a));

    const groupActivities = groupedActivitiesFor(ActivityRequired.OPERATION, activites);
    const productionButtons = createGroups(groupActivities);
    productionButtons.unshift(defaultButtons);

    return productionButtons;
}

function sortDefaultActivities(activities: Activity[]): Activity[] {
    const sortOrder = [DefaultActivity.MAKE_READY, DefaultActivity.PRODUCTION];
    const orderMap = new Map<string, number>();

    sortOrder.forEach((activity, index) => {
        orderMap.set(activity, index);
    });

    return activities.sort((a, b) => {
        const orderA = orderMap.get(a.defaultActivity) ?? sortOrder.length;
        const orderB = orderMap.get(b.defaultActivity) ?? sortOrder.length;
        return orderA - orderB;
    });
}

function groupedActivitiesFor(required: ActivityRequired, activities: Activity[]): { [key: string]: Activity[] } {
    const acts = activities.filter((activity) => activity.required.includes(required));
    return acts.reduce((groups: { [key: string]: Activity[] }, item) => {
        const group = groups[item.groupId] || [];
        group.push(item);
        groups[item.groupId] = group;
        return groups;
    }, {});
}

function createGroups(groupActivities: { [key: string]: Activity[] }): GroupedActivities[] {
    return Object.values(groupActivities).map((group) => ({
        activities: group,
        showExpandedPanel: true,
    }));
}
