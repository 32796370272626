import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { filter, map, Observable } from 'rxjs';

import { getParamsSnapshot } from '@data-terminal/utils';
import { CaptureMode, ROUTE_PARAMS } from '@data-terminal/shared-models';

import { FeatureDashboardDataAccessModule } from '../feature-dashboard-data-access.module';
import { UserMachinesService } from '../../../../data-access/src/lib/user-machines';
import { MachineGuardType } from '@data-terminal/feature-dashboards-data-access';

@Injectable({
    providedIn: FeatureDashboardDataAccessModule,
})
export class UserMachineGuard {
    constructor(
        private readonly userMachinesService: UserMachinesService,
        private readonly router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const params = getParamsSnapshot(route);
        const guardType: MachineGuardType = route.data['guardType'];
        if (guardType === null || guardType === undefined) {
            throw new Error('No guardType set in route configuration ( data: { guardType: MachineGuardType } )');
        }

        return this.userMachinesService.getUserMachines().pipe(
            filter((metadata) => !!metadata?.data),
            map((metadata) => metadata?.data),
            map((machines) => {
                const machineItem = machines?.assignedMachines.find(
                    (machine) => machine.machineId === params[ROUTE_PARAMS.machineId]
                );
                let shouldAllow = false;
                switch (guardType) {
                    case MachineGuardType.OPERATIONS: {
                        shouldAllow = machineItem?.captureMode === CaptureMode.START_STOP;
                        break;
                    }
                    case MachineGuardType.TIMELOGGING: {
                        shouldAllow = machineItem?.captureMode === CaptureMode.TIME;
                        break;
                    }
                    default: {
                        shouldAllow = machineItem !== null && machineItem !== undefined;
                    }
                }
                return shouldAllow || this.router.parseUrl(params[ROUTE_PARAMS.orgId]);
            })
        );
    }
}
