import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { TranslateModule } from '@ngx-translate/core';
import { AmountDialogFormData, OperationAmountDetails } from '../../models/amount-report-details.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@UntilDestroy()
@Component({
    selector: 'data-terminal-amount-report-details',
    templateUrl: './amount-report-details.component.html',
    styleUrls: ['./amount-report-details.component.scss'],
})
export class AmountReportDetailsComponent {
    @Input() public formData!: AmountDialogFormData | null;
    @Input() public operationData!: OperationAmountDetails | null;
    @Input() public isFinishOperation = false;
    @Input() public isManualMachine?: boolean = true;
}

@NgModule({
    declarations: [AmountReportDetailsComponent],
    exports: [AmountReportDetailsComponent],
    imports: [CommonModule, TranslateModule, HdmuiComponentsModule, MatProgressBarModule],
})
export class AmountReportDetailsModule {}
