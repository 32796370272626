import { Component, Input, NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { TranslateModule } from '@ngx-translate/core';
import { OperationDetails, UnitType } from '@data-terminal/shared-models';
import { CommonModule } from '@angular/common';
import { OperationStateModule } from '../../../../../../ui-smart/src/lib/components/operation-card/operation-state/operation-state.pipe';
import { TimeFormatterPipeModule, UnitFormatterPipeModule } from '@data-terminal/utils';
import { LegalLineComponent } from '../../../../../../../src/app/components/legal-line/legal-line.component';
import { OtherOperationTimesPipe } from '../../../pipes/other-operation-times.pipe';

@Component({
    selector: 'data-terminal-operation-details-info',
    templateUrl: './operation-details-info.component.html',
    styleUrls: ['./operation-details-info.component.scss'],
})
export class OperationDetailsInfoComponent {
    @Input() public operationDetails!: OperationDetails;
    public unitType = UnitType;
}

@NgModule({
    declarations: [OperationDetailsInfoComponent],
    exports: [OperationDetailsInfoComponent],
    imports: [
        MatListModule,
        HdmuiComponentsModule,
        TranslateModule,
        CommonModule,
        TimeFormatterPipeModule,
        OperationStateModule,
        UnitFormatterPipeModule,
        LegalLineComponent,
        OtherOperationTimesPipe,
    ],
})
export class OperationDetailsInfoModule {}
