<section class="sign-off-summary">
    <p>
        {{ 'DC.SIGN_OFF_DIALOG.SUMMARY.DESCRIPTION' | translate }}
    </p>
    <div class="sign-off-summary__list-of-summary-cards">
        @for (signOff of signOffMachines; track signOff) {
            <data-terminal-sign-off-summary-card
                class="hdmui-m-3"
                [signOffMachine]="signOff"
            ></data-terminal-sign-off-summary-card>
        }
    </div>
    @if (!!machinesWithUnsubmittedEntriesStr.length) {
        <hdmui-info-box
            [icon]="'hdmui:exclamationMarkCircled'"
            [backgroundColor]="'warning-strong'"
            [background]="'full'"
        >
            <p>
                {{
                    'DC.SIGN_OFF_DIALOG.WORKSTATIONS.UNSUBMITTED_TIME_ENTRIES'
                        | translate: { machines: machinesWithUnsubmittedEntriesStr }
                }}
            </p>
        </hdmui-info-box>
    }
</section>
