import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { getParamsSnapshot } from '@data-terminal/utils';
import { ROUTE_PARAMS } from '@data-terminal/shared-models';

import { EmployeeService } from '../../employee/employee.service';

export const machineSignInTimestampResolver: ResolveFn<number> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    employeeService: EmployeeService = inject(EmployeeService),
    ccAuthService: CCAuthService = inject(CCAuthService)
): Observable<number> =>
    employeeService.fetchUserMachineLogins(ccAuthService.getCurrentUser()?.email || '').pipe(
        map((machineLogins) => {
            const timeStamp = machineLogins.find(
                (machineLogin) => machineLogin.machineId === getParamsSnapshot(route)[ROUTE_PARAMS.machineId]
            );

            return timeStamp ? timeStamp.timestamp : new Date().getTime();
        })
    );
