import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import { Material } from '../../../../models/material.interface';
import { Consumption, ConsumptionPreview } from '../../../../models/consumption.model';
import { ConsumptionAddFormComponent } from '../consumption-add-form/consumption-add-form.component';
import { ConsumptionsPreviewComponent } from '../consumptions-preview/consumptions-preview.component';

export interface ConsumptionDialogData {
    allMaterials: Material[];
    batchAllowed: boolean;
}

@UntilDestroy()
@Component({
    templateUrl: './consumption-dialog.component.html',
    styleUrls: ['./consumption-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ConsumptionAddFormComponent,
        ConsumptionsPreviewComponent,
        MatToolbarModule,
        MatDividerModule,
        TranslateModule,
        MatButtonModule,
        MatIcon,
    ],
})
export class ConsumptionDialogComponent {
    readonly #dialogRef: MatDialogRef<ConsumptionDialogComponent, Consumption[]> = inject(MatDialogRef);

    public readonly data: ConsumptionDialogData = inject(MAT_DIALOG_DATA);

    public readonly consumptions = signal<ConsumptionPreview[]>([]);

    public report(): void {
        if (this.consumptions().length) {
            return this.#dialogRef.close(
                this.consumptions().map((c) => ({
                    consumption: c.consumption,
                    materialId: c.materialId,
                    type: c.type,
                    batchId: c.batchId || undefined,
                }))
            );
        }
    }

    public cancel(): void {
        return this.#dialogRef.close();
    }

    public addConsumption(consumption: ConsumptionPreview): void {
        this.consumptions.update((consumptions) => [...consumptions, consumption]);
    }

    public removeConsumption(index: number): void {
        this.consumptions.update((consumptions) => consumptions.filter((_, i) => i !== index));
    }
}
