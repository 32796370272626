import { Component, inject, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

import { ActionView, ConfirmationSettingType, SettingsConfirmationService } from '@data-terminal/feature-settings';
import { ActivityState } from '@data-terminal/shared-models';

export interface ActivityConfirmationDialogData {
    activityName: string;
    actionView?: ActionView;
    jobStatus?: ActivityState;
    isCleanup: boolean;
    isStopped: boolean;
}
@Component({
    templateUrl: './activity-confirmation-dialog.component.html',
})
export class ActivityConfirmationDialogComponent {
    readonly #dialogRef = inject(MatDialogRef<ActivityConfirmationDialogComponent>);
    readonly #settingsConfirmationService = inject(SettingsConfirmationService);

    readonly dialogData: ActivityConfirmationDialogData = inject(MAT_DIALOG_DATA);

    public option = HdmuiBaseDialogComponent.OPTION_CONFIRM_CANCEL;
    public isChecked = false;

    public closeDialog(): void {
        this.#dialogRef.close(false);
    }

    public confirmActivity(): void {
        if (this.isChecked) {
            this.#settingsConfirmationService.updateConfirmationSetting(
                this.dialogData.activityName as ConfirmationSettingType,
                this.isChecked,
                this.dialogData.actionView,
                this.dialogData.jobStatus
            );
        }
        this.#dialogRef.close(true);
    }
}

@NgModule({
    declarations: [ActivityConfirmationDialogComponent],
    exports: [ActivityConfirmationDialogComponent],
    imports: [HdmuiComponentsModule, MatCheckboxModule, TranslateModule, FormsModule, CommonModule],
})
export class ActivityConfirmationDialogModule {}
