import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, filter, map, tap } from 'rxjs';

import {
    ApiRequestResponseService,
    BACKEND_URL,
    DataTerminalSessionService,
    ORGANIZATION_REQUEST_PREFIX,
} from '@data-terminal/data-access';
import { SignOffList, UserMachines } from '@data-terminal/shared-models';
import { ApiRequestType } from '@data-terminal/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class SignOffApiService {
    constructor(
        private readonly http: HttpClient,
        private readonly snackBar: MatSnackBar,
        private readonly dataTerminalSessionService: DataTerminalSessionService,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string,
        private readonly apiRequestResponseService: ApiRequestResponseService,
        private readonly dialog: MatDialog
    ) {}

    public signOff(signOffList: SignOffList): Observable<UserMachines> {
        return this.apiRequestResponseService
            .sendApiRequest<SignOffList, UserMachines>(ApiRequestType.SIGN_OFF, signOffList)
            .pipe(
                filter((mapToRequestMetadata) => mapToRequestMetadata.data !== undefined),
                tap({
                    // // Error Handling
                    // next: (response) => {
                    //     if (response.data !== undefined && response.data !== null) {
                    //         timeout = false;
                    //         const errorData = response.data.serviceError;
                    //         if (errorData.errorMsg !== undefined) {
                    //             this.dialog.open(ErrorDialogComponent, {
                    //                 disableClose: true,
                    //                 data: {
                    //                     errorCode: 'ERR_SIGNOFF',
                    //                     errorMessage: JSON.stringify({
                    //                         payload: JSON.parse(errorData.errorMsg),
                    //                         request: RequestType.SIGNOFF_SHIFTEND,
                    //                         deviceId: '',
                    //                     }),
                    //                 },
                    //             });
                    //         }
                    //     }
                    // },
                    // complete: () => {
                    //     if (timeout) {
                    //         this.dialog.open(ErrorDialogComponent, {
                    //             disableClose: true,
                    //             data: {
                    //                 errorCode: 'ERR_SIGNOFF',
                    //                 errorMessage: JSON.stringify({
                    //                     payload: { payload: [] },
                    //                     request: RequestType.SIGNOFF_SHIFTEND,
                    //                     deviceId: '',
                    //                 }),
                    //             },
                    //         });
                    //     }
                    // },
                }),
                map((requestMetadata) => requestMetadata?.data as UserMachines)
            );
    }
}
