import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import {
    isOperationActivityRunning,
    shouldHandleOperationBecauseAssistantsWillBeLoggedOff,
} from '@data-terminal/feature-dialogs';
import { isCurrentUserAnOperator } from '@data-terminal/utils';
import { SignOffMachine, SignOffOperation } from '@data-terminal/shared-models';

import { SignOffOperationCardModule } from './sign-off-operations-card/sign-off-operation-card.component';
import { SignOffStepComponent } from '../sign-off-component.abstract';

@Component({
    selector: 'data-terminal-sign-off-operations',
    templateUrl: './sign-off-operations.component.html',
    styleUrls: ['./sign-off-operations.component.scss'],
})
export class SignOffOperationsComponent extends SignOffStepComponent<SignOffOperation> {
    @Input() public userId!: string;

    public override shouldDisplayCard(signOffData: SignOffMachine): boolean {
        const shouldDisplay =
            shouldHandleOperationBecauseAssistantsWillBeLoggedOff(signOffData) &&
            isOperationActivityRunning(signOffData.machine) &&
            isCurrentUserAnOperator(signOffData.machine, this.userId);
        if (!shouldDisplay) {
            this.validityMap.delete(signOffData.machineId);
        }
        return shouldDisplay;
    }
}

@NgModule({
    declarations: [SignOffOperationsComponent],
    exports: [SignOffOperationsComponent],
    imports: [SignOffOperationCardModule, CommonModule, TranslateModule],
})
export class SignOffOperationsModule {}
