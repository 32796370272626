<form [formGroup]="wasteAmountForm" class="report">
    <hdmui-base-dialog
        [dialogOptions]="option"
        (cancelClicked)="onCancelDialog()"
        (okClicked)="onConfirmDialog()"
        [contentNoPadding]="true"
        [okText]="'DC.REPORT_LAST_AMOUNTS.DIALOG.OKTEXT' | translate"
        [cancelText]="'DC.REPORT_LAST_AMOUNTS.DIALOG.CANCELTEXT' | translate"
    >
        <div class="report-form">
            <h2 class="hdmui-m-3">{{ 'DC.REPORT_WASTE_AMOUNTS.DIALOG.TITLE' | translate }}</h2>
            <div class="hdmui-m-3 report-form__container">
                <mat-form-field class="hdmui-m-0 hdmui-p-0 report-form__input">
                    <mat-label>
                        {{ 'DC.REPORT_LAST_AMOUNTS.WASTE' | translate }}
                    </mat-label>
                    <input
                        matInput
                        formControlName="wasteAmount"
                        (blur)="onInputOut()"
                        mask="separator.0"
                        #wasteAmountInput
                    />
                </mat-form-field>
                <p class="hdmui-caption hdmui-m-0 hdmui-p-0 report-form__value-hint">
                    {{ 'DC.REPORT_LAST_AMOUNTS.DIALOG.PLANNED_AMOUNT' | translate }}:&nbsp;
                    {{ dialogData.plannedWasteAmount }}
                </p>

                <mat-form-field class="hdmui-mb-4">
                    <mat-label>{{ 'DC.REPORT_LAST_AMOUNTS.DIALOG.COMMENT' | translate }}</mat-label>
                    <textarea matInput rows="3" formControlName="comment"></textarea>
                </mat-form-field>

                <hdmui-key-value
                    class="hdmui-mb-3 hdmui-ms-0 hdmui-py-2"
                    [label]="'DC.REPORT_LAST_AMOUNTS.DIALOG.ACCUMULATED_WASTE_AMOUNT' | translate"
                    [ngClass]="{
                        exceeded:
                            dialogData.currentWasteAmount + wasteAmountForm.controls.wasteAmount.value >
                            dialogData.plannedWasteAmount,
                    }"
                    [wrapText]="true"
                >
                    {{ dialogData.currentWasteAmount + wasteAmountForm.controls.wasteAmount.value | number }}
                </hdmui-key-value>
                <mat-checkbox [(ngModel)]="isChecked" [ngModelOptions]="{ standalone: true }"
                    ><span>{{ 'DC.ACTIVITY_CONFIRMATION.DIALOG.CHECKBOX' | translate }}</span>
                </mat-checkbox>
            </div>
        </div>
    </hdmui-base-dialog>
</form>
