import { MS_IN_SECOND, SECONDS_IN_MIN } from '@data-terminal/utils';

import { TimeModeCard } from '../models/time-mode-card.interface';

export function calcCardHeightPx(durationMs: number): number {
    const LINE_HEIGHT_PX = 33;
    const MINUTES_PER_LINE = 15;

    const pixelsPerMinute = LINE_HEIGHT_PX / MINUTES_PER_LINE;

    return (durationMs / MS_IN_SECOND / SECONDS_IN_MIN) * pixelsPerMinute;
}

export function calcCardTopPx(cards: TimeModeCard[], machineSignInTimestamp: number, index: number): number {
    const date = new Date(machineSignInTimestamp);

    let prevHeights = calcCardHeightPx(
        (date.getMinutes() * SECONDS_IN_MIN + date.getSeconds()) * MS_IN_SECOND + date.getMilliseconds()
    );

    for (let i = 0; i < index; i++) {
        prevHeights += cards[i].height;
    }

    return prevHeights;
}

export function calcCardMarginLeft(cards: TimeModeCard[], cardIndex: number, heightThresholdPx: number): number {
    const MARGIN_PX = 10;
    let marginLeft = 0;
    let prevCardIndex = cardIndex - 1;

    while (prevCardIndex >= 0 && cards[prevCardIndex].height < heightThresholdPx) {
        marginLeft = marginLeft + MARGIN_PX;
        prevCardIndex = prevCardIndex - 1;
    }

    return marginLeft;
}
