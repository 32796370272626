import { MS_IN_SECOND, SECONDS_IN_HOUR, SECONDS_IN_MIN } from '@data-terminal/utils';

export const DURATION_STR_PATTERN = /^(?:([0-9]+)h\s?)?(?:([0-5]?[0-9])m)?$/;

export function durationStrToMs(durationStr: string | undefined): number {
    const match = durationStr?.match(DURATION_STR_PATTERN);

    if (!match) {
        return 0;
    }

    const hours = match[1] ? parseInt(match[1], 10) : 0;
    const minutes = match[2] ? parseInt(match[2], 10) : 0;

    return (hours * SECONDS_IN_HOUR + minutes * SECONDS_IN_MIN) * MS_IN_SECOND;
}
