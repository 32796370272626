import { Injectable } from '@angular/core';
import { DataTerminalSessionService } from '@data-terminal/data-access';
import { Observable, Subject, of } from 'rxjs';
import { ActivitiesApiService } from '../activities-api/activities-api.service';
import { RETRY_TIME_DELAY_MS, RequestMetadata, mapToRequestMetadataWithRetry } from '@data-terminal/utils';
import { Activity } from '@data-terminal/shared-models';

@Injectable({
    providedIn: 'root',
})
export class GeneralActivitiesService {
    private readonly _updateOtherActivitiesTriggerSubject = new Subject<void>();
    private readonly updateOtherActivitiesTrigger$ = this._updateOtherActivitiesTriggerSubject.asObservable();

    constructor(
        private readonly activitiesApiService: ActivitiesApiService,
        private readonly dataTerminalSessionService: DataTerminalSessionService
    ) {}

    public getAllActivities(): Observable<RequestMetadata<Activity[]>> {
        const dataTerminalUser = this.dataTerminalSessionService.getCurrentDataTerminalUser();
        if (dataTerminalUser !== undefined && dataTerminalUser !== null) {
            return this.activitiesApiService
                .getUserActivities(this.dataTerminalSessionService.getCurrentDataTerminalUser().userId)
                .pipe(mapToRequestMetadataWithRetry({ baseDelayMs: RETRY_TIME_DELAY_MS }));
        } else {
            return of([]).pipe(mapToRequestMetadataWithRetry({ baseDelayMs: RETRY_TIME_DELAY_MS }));
        }
    }

    public triggerUpdateOtherActivities(): void {
        this._updateOtherActivitiesTriggerSubject.next();
    }

    public getUpdateOtherActivitiesTrigger$(): Observable<void> {
        return this.updateOtherActivitiesTrigger$;
    }
}
