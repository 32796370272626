import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveData } from '@angular/router';
import { Observable } from 'rxjs';
import { OperationDetails, ROUTE_PARAMS } from '@data-terminal/shared-models';
import { OperationService } from '../../operation/operation.service';
import { getAllParams } from '../get-params-from-path.function';
import { FeatureOperationServiceModule } from '../../../operation-service.module';

@Injectable({
    providedIn: FeatureOperationServiceModule,
})
export class OperationDetailsResolver {
    constructor(private readonly operationService: OperationService) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<OperationDetails> {
        const primaryKey = getAllParams(route)[ROUTE_PARAMS.primaryKey];
        return this.operationService.getOperationDetails(primaryKey);
    }
}

export function operationDetailsResolver(): ResolveData {
    return { operationDetails: OperationDetailsResolver };
}
