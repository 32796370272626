import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

import { Activity, MachineClass } from '@data-terminal/shared-models';

import { LogTimeFormComponent } from '../log-time-form/log-time-form.component';

export interface LogTimeDialogData {
    activities: Activity[];
    machineClass: MachineClass;
    lastLoggedActivityTimestamp: number;
}

@Component({
    templateUrl: './log-time-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [HdmuiComponentsModule, TranslateModule, LogTimeFormComponent],
})
export class LogTimeDialogComponent {
    readonly #dialogRef = inject(MatDialogRef<LogTimeDialogComponent>);

    @ViewChild('logTimeFormComponent') logTimeFormComponent!: LogTimeFormComponent;

    public readonly data: LogTimeDialogData = inject(MAT_DIALOG_DATA);
    public readonly option = HdmuiBaseDialogComponent.OPTION_CONFIRM_CANCEL;

    public onCancelDialog(): void {
        this.#dialogRef.close();
    }

    public onConfirmDialog(): void {
        this.#dialogRef.close(this.logTimeFormComponent.form.value);
    }
}
