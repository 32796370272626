import { Pipe, PipeTransform } from '@angular/core';

import { Activity, ActivityState } from '@data-terminal/shared-models';

@Pipe({
    name: 'activityRunningToState',
    standalone: true,
})
export class ActivityRunningToStatePipe implements PipeTransform {
    public transform(activity?: Activity): ActivityState {
        return activity?.running ? ActivityState.IN_PROGRESS : ActivityState.SUSPENDED;
    }
}
