<div class="operation_details_preview">
    <div class="operation_details_preview__row">
        @if (operationDetails.frontPreView !== 'not yet available' && operationDetails.frontPreView !== undefined) {
            <div
                class="operation_details_preview__image"
                [ngStyle]="{
                    'background-image': 'url(data:image/png;base64,' + operationDetails.frontPreView + ') ',
                }"
            ></div>
        }
        @if (operationDetails.frontPreView === 'not yet available') {
            <div class="operation_details_preview__image">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        }
        @if (!operationDetails.frontPreView) {
            <div class="operation_details_preview__image">
                <mat-icon svgIcon="hdmui:imageUnavailable" style="width: 50px; height: 50px"></mat-icon>
            </div>
        }
    </div>

    <div class="operation_details_preview__row">
        @if (operationDetails.backPreView !== 'not yet available' && operationDetails.backPreView !== undefined) {
            <div
                class="operation_details_preview__image"
                [ngStyle]="{
                    'background-image': 'url(data:image/png;base64,' + operationDetails.backPreView + ') ',
                }"
            ></div>
        }
        @if (operationDetails.frontPreView === 'not yet available') {
            <div class="operation_details_preview__image">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        }
        @if (!operationDetails.backPreView) {
            <div class="operation_details_preview__image">
                <mat-icon svgIcon="hdmui:imageUnavailable" style="width: 50px; height: 50px"></mat-icon>
            </div>
        }
    </div>
</div>
