import { Pipe, PipeTransform } from '@angular/core';

import { TimeModeEntry } from '@data-terminal/shared-models';

import { TimeModeCard } from '../models/time-mode-card.interface';
import { calcCardHeightPx } from '../functions/time-mode-card-dimension.functions';

@Pipe({
    name: 'timeModeEntriesToCards',
    standalone: true,
})
export class TimeModeEntriesToCardsPipe implements PipeTransform {
    public transform(timeModeEntries: TimeModeEntry[]): TimeModeCard[] {
        return timeModeEntries.map((entry) => ({
            entry,
            height: calcCardHeightPx(entry.duration),
        }));
    }
}
