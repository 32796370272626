import { CommonModule } from '@angular/common';
import { Component, EventEmitter, NgModule, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRouteDataService } from '@data-terminal/data-access';
import { CommentsFilter } from '@data-terminal/shared-models';
import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
const DEBOUNCE_TIME_500 = 500;

@Component({
    selector: 'data-terminal-comment-workflow-filters',
    templateUrl: './comment-workflow-filters.component.html',
    styleUrls: ['./comment-workflow-filters.component.scss'],
})
export class CommentWorkflowFiltersComponent implements OnInit {
    constructor(private readonly activatedRouteDataService: ActivatedRouteDataService) {}
    @Output() closeFilterPanel = new EventEmitter<void>();
    @Output() filterChange = new EventEmitter<CommentsFilter>();

    public filterGroup!: UntypedFormGroup;
    private readonly SESSION_STORAGE_KEY = 'filterComments';
    private MACHINE_STORAGE_KEY = '';

    ngOnInit(): void {
        this.activatedRouteDataService.activatedRouteData$.subscribe((route) => {
            if (route !== undefined) {
                this.MACHINE_STORAGE_KEY = route?.operationDetails?.machine;
            }
        });
        this.createFormGroup();
        this.readDataFromSessionStorage();
        this.filterGroup.valueChanges
            .pipe(startWith(this.filterGroup.value), debounceTime(DEBOUNCE_TIME_500), distinctUntilChanged())
            .subscribe((data) => {
                if (this.filterGroup.valid) {
                    sessionStorage.setItem(
                        `${this.SESSION_STORAGE_KEY}_${this.MACHINE_STORAGE_KEY}`,
                        JSON.stringify(data)
                    );
                    this.filterChange.emit(data);
                }
            });
    }

    readDataFromSessionStorage(): void {
        const data = sessionStorage.getItem(`${this.SESSION_STORAGE_KEY}_${this.MACHINE_STORAGE_KEY}`);
        if (data) {
            this.filterGroup.setValue(JSON.parse(data));
        }
    }

    onFilterClose(): void {
        this.closeFilterPanel.emit();
    }

    onTextSnippetReset(): void {
        this.filterGroup.get('textsnippet')?.setValue(null);
    }

    createFormGroup(): void {
        this.filterGroup = new UntypedFormGroup({
            textsnippet: new UntypedFormControl(),
        });
    }
}

@NgModule({
    declarations: [CommentWorkflowFiltersComponent],
    exports: [CommentWorkflowFiltersComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        HdmuiComponentsModule,
        TranslateModule,
        MatIconModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatDividerModule,
    ],
})
export class CommentWorkflowFiltersModule {}
