import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Activity, Machine } from '@data-terminal/shared-models';
import { MachineListComponent } from '@data-terminal/ui-presentational';

@Component({
    selector: 'data-terminal-sign-off-workstations',
    templateUrl: './sign-off-workstations.component.html',
    styleUrls: ['./sign-off-workstations.component.scss'],
})
export class SignOffWorkstationsComponent {
    @Input() public machines!: Machine[];
    @Input() public otherActivities!: Activity[];
    @Output() public selectMachines = new EventEmitter<Machine[]>();
    @Output() public isValid = new EventEmitter<boolean>();

    public onSelectedMachines($event: Machine[]): void {
        if ($event.length > 0) {
            this.selectMachines.emit($event);
            this.isValid.emit(true);
        } else {
            this.isValid.emit(false);
        }
    }
}
@NgModule({
    declarations: [SignOffWorkstationsComponent],
    exports: [SignOffWorkstationsComponent],
    imports: [MachineListComponent, TranslateModule],
})
export class SignOffWorkstationsModule {}
